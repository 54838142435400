import React, { useEffect, useState } from 'react';
import { X } from 'react-feather';
import Button from '../../complib/Button';
import SearchInput from '../../complib/SearchInput';
import appStyles from '../../support/appStyles';
import { highlitContent } from '../helpers';
interface Props {
  imageFn: (imageName: string, scale?: number) => React.ReactNode;
  options: string[];
  value?: string;
  onChange: (value: string) => void;
}

const ImageSelector = ({ value, onChange, options, imageFn }: Props) => {
  const [showing, setShowing] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [filteredIcons, setFilteredIcons] = useState<string[]>();
  useEffect(() => {
    const term = search.toLocaleLowerCase().trim();
    setFilteredIcons(
      options
        .filter(
          (icon: string) =>
            search.length < 1 || icon.toLocaleLowerCase().includes(term)
        )
        .sort((a: string, b: string) => (a > b ? 1 : -1))
    );
  }, [search, options]);
  const select = (icon: string): void => {
    onChange(icon);
    setShowing(false);
    setSearch('');
  };
  return (
    <div className='relative w-full'>
      <div
        className={`flex cursor-pointer items-center ${appStyles.input} bg-white py-1`}
        onClick={() => setShowing(!showing)}
      >
        {imageFn(value ?? '', 0.25)}
        <div className='ml-2'>{value}</div>
      </div>
      <div
        className={`absolute ${appStyles.input} bg-white top-0 ${
          showing ? 'absolute' : 'hidden'
        } transition-max-h z-10 w-full pl-0 pr-0`}
      >
        <div className='flex'>
          <SearchInput
            value={search}
            changeEvent={(term) => setSearch(term)}
            placeholder='Search icons&hellip;'
            className='m-2'
          />
          <Button
            theme='ghost'
            onClick={() => setShowing(false)}
            className='top-2 right-0 px-1 py-1 absolute mr-0'
          >
            <X size={16} />
          </Button>
        </div>
        <div className={`max-h-80 overflow-y-scroll grid grid-cols-4`}>
          {filteredIcons?.map((icon: string) => (
            <div
              key={icon}
              onClick={() => select(icon)}
              className={`flex text-sm p-1 text-sec-dark-gray items-center ${
                icon === value
                  ? 'border-pri-sky'
                  : 'opacity-80 bg-gray-200 border-transparent'
              } flex-0 border cursor-pointer`}
            >
              {imageFn(icon)}
              <div className='font-semibold hover:underline flex-1 ml-1 overflow-hidden'>
                {highlitContent(icon, search)}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ImageSelector;
