import React from 'react';
import { X } from 'react-feather';
import Modal from 'react-modal';
import { Item } from '../types/item';
import Button from '../complib/Button';
import ItemSelectBox from './ItemSelectBox';
import useStore from '../support/store';

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  data: Item[];
  entity: string;
}

const ItemsModal = ({ isOpen, closeModal, data, entity }: Props) => {
  const selectAll = useStore((state) => state.addAllItems);
  const removeAll = useStore((state) => state.removeAllItems);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel='Selection panel'
      overlayClassName='bg-[rgba(0,0,0,0.2)] fixed top-0 left-0 right-0 bottom-0 flex h-[100vh] w-[100vw] items-center justify-center'
      className='max-h-[83vh] relative bg-gray-200 rounded-sm shadow-lg p-6 w-5/6 max-w-6xl'
    >
      <Button
        theme='ghost'
        onClick={closeModal}
        className='top-4 right-4 px-1 py-1 absolute mr-0'
      >
        <X size={16} />
      </Button>
      <div className=''>
        <div className=''>
          <Button onClick={() => selectAll(entity)} className='mr-2 my-2'>
            Add all
          </Button>
          <Button onClick={() => removeAll(entity)} className='mr-2 my-2'>
            Remove all
          </Button>
        </div>
        <div className='overflow-y-auto h-[64vh]'>
          <div className='grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-2'>
            {data &&
              data.map((item: Item) => (
                <ItemSelectBox
                  key={`modal-${item.name}`}
                  item={item}
                  type={entity}
                />
              ))}
          </div>
        </div>
        <div className=''>
          <Button onClick={closeModal} className='mr-2 my-2'>
            Done
          </Button>
        </div>
      </div>
    </Modal>
  );
};
export default ItemsModal;
