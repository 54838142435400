import React, { useEffect, useState } from 'react';
import Card from '../../complib/Card';
import Loader from '../../complib/Loader';
import appStyles from '../../support/appStyles';
import {
  CapabilityProduct,
  ProductType,
  useCapabilitySelections,
} from '../../support/stores';
import { SafeMode, scoreOpacity, SortConfig } from '../safe-utils';
import { heatColor } from '../SafeFlowChart/chart-constants';
import TableSortHeading from '../TableSortHeading';
import {
  changeSorting,
  DisplayType,
  itemDisplay,
  itemStatusCircleStyle,
  itemStyle,
  reportPrintOmitRows,
} from './report-utils';

type ProductScore = { type: ProductType; score: number; name: string };
type ProductScores = {
  [key: string]: ProductScore;
};

interface Props {
  filteredProducts: string[] | undefined;
  highlight: string[];
  select: (p: ProductScore | null) => void;
  safeMode: SafeMode;
  currentSelection: { id: string; type: string } | null;
  capabilityKey?: 'products' | 'proposed';
}

const ReportProductPanel = ({
  filteredProducts,
  highlight,
  select,
  safeMode = 'risk',
  currentSelection,
  capabilityKey = 'products',
}: Props) => {
  const { selections: capabilitySelections } = useCapabilitySelections();
  const [products, setProducts] = useState<ProductScores>();
  const [maxScore, setMaxScore] = useState<number>(1);
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    key: safeMode === 'risk' ? 'score' : 'name',
    dir: safeMode === 'risk' ? -1 : 1,
  });

  useEffect(() => {
    const productScores: ProductScores = {};
    let max = 1;
    Object.values(capabilitySelections)
      .filter((s) => s.active)
      .forEach((s) => {
        // capability scores just need to be truthy in architect mode to add products
        const score = safeMode === 'risk' ? s.score ?? 0 : 1;
        let products = (s[capabilityKey] ?? []).filter(
          (p: CapabilityProduct) => p.active
        );
        if (capabilityKey === 'proposed' && products.length === 0) {
          products = (s['products'] ?? []).filter(
            (p: CapabilityProduct) => p.active
          );
        }
        if (capabilityKey === 'proposed' && products.length === 0) {
          products = [{ name: 'Third Party Product', active: true, type: '3' }];
        }
        if (score && products.length) {
          products.forEach((p: CapabilityProduct) => {
            const pName = p.name;
            productScores[pName] = productScores[pName] ?? {
              name: pName,
              type: p.type,
              score: 0,
            };
            productScores[pName].score += score;
            max = Math.max(productScores[pName].score, max);
          });
        }
      });
    setMaxScore(max);
    setProducts(productScores);
  }, [capabilitySelections, safeMode, capabilityKey]);

  const compareProducts = (a: ProductScore, b: ProductScore): number => {
    const aComp = a[sortConfig.key as 'type' | 'score'] as
      | ProductType
      | number
      | string;
    const bComp = b[sortConfig.key as 'type' | 'score'] as
      | ProductType
      | number
      | string;
    if (aComp === bComp) {
      return a.name > b.name ? 1 : -1;
    }
    return aComp > bComp ? sortConfig.dir : -sortConfig.dir;
  };

  const updateSort = (key: string) =>
    setSortConfig(changeSorting(sortConfig, key));

  const showProduct = (pScores: ProductScore): boolean => {
    if (!filteredProducts) {
      return true;
    }
    return filteredProducts.includes(pScores.name);
  };

  const productDisplay = (p: ProductScore): DisplayType =>
    itemDisplay(currentSelection, p, 'product', highlight);

  return (
    <section>
      {!products && <Loader className='w-16 h-16' />}
      {products && (
        <>
          <header className='text-center'>
            <h2 className={`${appStyles.h2} image-vertical-offset`}>
              {capabilityKey === 'proposed' ? 'Proposed' : 'Current'} Products
            </h2>
            <div
              className='flex items-stretch text-sm mb-2 -mr-6'
              data-html2canvas-ignore
            >
              {safeMode === 'risk' && (
                <TableSortHeading
                  updateSort={updateSort}
                  sort={sortConfig}
                  sortKey='score'
                  className='pl-4 w-[100px]'
                >
                  Priority
                </TableSortHeading>
              )}
              <TableSortHeading
                updateSort={updateSort}
                sort={sortConfig}
                sortKey='name'
                className='flex-1 flex justify-items-center'
              >
                Product
              </TableSortHeading>
              <TableSortHeading
                updateSort={updateSort}
                sort={sortConfig}
                className='w-20 text-right'
                sortKey='type'
              >
                Type
              </TableSortHeading>
            </div>
          </header>
          <Card className={`p-0`}>
            {products &&
              Object.values(products)
                .filter(showProduct)
                .sort(compareProducts)
                .map((p, n) => (
                  <div
                    key={p.name}
                    className={itemStyle(productDisplay(p))}
                    onClick={() => select(p)}
                    {...reportPrintOmitRows(n)}
                  >
                    {safeMode === 'risk' && (
                      <div
                        className={appStyles.numberColorBox}
                        style={{
                          backgroundColor: heatColor(
                            (p.score ?? 0) / maxScore,
                            scoreOpacity
                          ),
                        }}
                      >
                        <div className='image-vertical-offset'>{p.score}</div>
                      </div>
                    )}
                    <h3 className='font-cisco flex-1 m-2 image-vertical-offset'>
                      {p.name}
                    </h3>
                    <div
                      className={`${itemStatusCircleStyle} ${
                        products[p.name].type === 'cisco'
                          ? 'bg-sky-darker'
                          : 'bg-success-darker'
                      }`}
                    >
                      <div className='image-circle-offset'>
                        {p.type[0].toLocaleUpperCase()}
                      </div>
                    </div>
                  </div>
                ))}
          </Card>
        </>
      )}
    </section>
  );
};

export default ReportProductPanel;
export { ProductScore };
