import React from 'react';
import appStyles from '../support/appStyles';

interface Props {
  max?: number;
  min?: number;
  step?: number;
  value?: number;
  onChange: React.ChangeEventHandler;
}

const NumberInputRange = ({ max, min, step, value, onChange }: Props) => {
  return (
    <div>
      <input
        type='number'
        min={min ?? 0}
        max={max ?? 100}
        step={step ?? 1}
        value={value ?? 0}
        onChange={onChange}
        className={`${appStyles.input} pr-0 w-18`}
      />
      <br />
      <input
        type='range'
        min={min ?? 0}
        max={max ?? 100}
        step={step ?? 1}
        value={value ?? 0}
        onChange={onChange}
        className='w-full accent-sky-darker'
      />
    </div>
  );
};

export default NumberInputRange;
