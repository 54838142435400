import React from 'react';
import { Info } from 'react-feather';

interface Props {
  show: boolean;
}

const NoneShowing = ({ show }: Props) => {
  return (
    <>
      {show && (
        <div className={`flex items-center border-gray-300 border-t px-4`}>
          <h3 className='flex-1 my-4 flex'>
            <Info className='text-pri-sky mr-2' />
            <span className='text-l'>None to show for selected filters</span>
          </h3>
        </div>
      )}
    </>
  );
};

export default NoneShowing;
