import React from 'react';

const styles = [
  'animate-spin',
  'w-8',
  'h-8',
  'rounded-full',
  'border-2',
  'border-sky-lighter',
  'border-t-sky',
  'border-r-sky-darker',
].join(' ');

interface Props {
  className?: string;
}

const Loader = ({ className }: Props) => (
  <div className={`${styles} ${className}`}></div>
);

export default Loader;
