import React, { ReactNode } from 'react';
import { ChevronDown, ChevronUp } from 'react-feather';
import { SortConfig } from './safe-utils';

interface Props {
  children?: ReactNode;
  sortKey: string;
  sort: SortConfig;
  updateSort: (key: string) => void;
  className?: string;
}

const TableSortHeading = ({
  children,
  sortKey,
  sort,
  updateSort,
  className,
}: Props) => {
  const active = sort.key === sortKey;

  return (
    <div
      className={`${className} ${
        active ? 'font-semibold' : ''
      } flex items-center cursor-pointer hover:underline underline-offset-2`}
      onClick={() => updateSort(sortKey)}
    >
      {children}
      {active &&
        (sort.dir > 0 ? <ChevronDown size={16} /> : <ChevronUp size={16} />)}
    </div>
  );
};

export default TableSortHeading;
