import * as d3 from 'd3';

const d3arc = (start: number, radius: number, width: number) =>
  d3
    .arc()
    .innerRadius(radius - width / 2)
    .outerRadius(radius + width / 2)
    .startAngle(start)
    .endAngle(start + Math.PI);

const svgRect = (
  svgSelect: d3.Selection<SVGSVGElement | null, unknown, null, undefined>,
  { x, y, width, height, radius, fill }: { [key: string]: number | string }
) =>
  svgSelect
    .append('rect')
    .attr('x', x)
    .attr('y', y)
    .attr('width', width)
    .attr('height', height)
    .attr('rx', radius)
    .attr('fill', fill);

const splitLines = (title: string, lineLength = 13): string[] => {
  const lines: string[] = [];
  title.split(' ').forEach((c) => {
    const ll = lines.length;
    if (ll > 0 && lines[ll - 1].length + c.length < lineLength) {
      lines[ll - 1] += ` ${c}`;
    } else {
      lines.push(c);
    }
  });
  return lines;
};

export { d3arc, svgRect, splitLines };
