import appStyles from '../../support/appStyles';
import { verticals } from '../constants';
import React from 'react';

interface Props {
  value: SelectedCategories;
  onChange: (value: { [key: string]: boolean }) => void;
}

const BustechCategorySelector = ({ value, onChange }: Props) => {
  const toggleCategory = (cat: string) => {
    const newValue = { ...value };
    const previous = value[cat] ?? false;
    newValue[cat] = !previous;
    onChange(newValue);
  };

  return (
    <div className='flex flex-wrap'>
      {verticals.map((cat: string) => (
        <div
          key={cat}
          className={`${appStyles.filterButton} ${
            value && value[cat]
              ? 'bg-sky-darker hover:bg-ocean-lighter'
              : 'bg-gray-400 hover:bg-gray-500'
          } capitalize pt-[1px] pb-[1px] pl-3 pr-3 text-sm`}
          onClick={() => toggleCategory(cat)}
        >
          {cat}
        </div>
      ))}
    </div>
  );
};

type SelectedCategories = { [key: string]: boolean };

export default BustechCategorySelector;
export { SelectedCategories };
