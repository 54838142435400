import React, { useEffect, useState } from 'react';
import { Loader } from 'react-feather';
import { useQuery } from 'react-query';
import {
  fetchFramework,
  getQueryCacheFrom,
  queryConfig,
} from '../support/queries';
import { Framework } from '../types/framework';
import { Product } from '../types/product';
import ItemsTable, { TableData } from './ItemsTable';

interface Props {
  name: string;
}

const FrameworksTable = ({ name }: Props) => {
  const { data } = useQuery(`framework-${name}`, () => fetchFramework(name), {
    ...queryConfig,
    initialData: getQueryCacheFrom<Framework>('frameworks', name),
  });

  const [tableData, setTableData] = useState<TableData>();
  useEffect(() => {
    if (!data) {
      setTableData(undefined);
    } else {
      const tData: TableData = {
        columns: ['Product Name', 'Product Coverage', 'Coverage Description'],
        rows: data.product?.map((product: Partial<Product>) => [
          product.name ?? '',
          product.coverage ?? '',
          product.des ?? '',
        ]),
      };
      setTableData(tData);
    }
  }, [data]);

  return (
    <>
      {!tableData && <Loader />}
      {!!tableData?.rows.length && <ItemsTable tableData={tableData} />}
    </>
  );
};

export default FrameworksTable;
