import React from 'react';
import { SafeProps } from '../safe/safe-utils';
import appStyles from '../support/appStyles';
import HelpContentSecurityFunction from './HelpContentSecurityFunction';

const FlowHelp = ({ safeMode }: SafeProps) => {
  return (
    <>
      {safeMode === 'architect' && (
        <h2 className={appStyles.h2}>SAFE Architecture – Flow Diagram (3)</h2>
      )}
      {safeMode === 'risk' && (
        <h2 className={appStyles.h2}>
          SAFE Risk Assessment – Flow Diagram (4)
        </h2>
      )}
      <p className='mb-4'>
        On this page we can create custom flow diagrams using the information
        collected previously. On the left are the control and the flow diagram
        preview is on the right.
      </p>
      <p className='mb-4'>Diagram Controls Found on the Left:</p>
      <ul className='mb-4 pl-4 list-disc'>
        {safeMode === 'risk' && (
          <li>
            <strong>Capability Selection</strong> – default view filters
            displayed capabilities based on capability score showing the highest
            scoring controls first. Use this drop down to instead filter
            capabilities based on SAFE best practice designs or individual
            solutions.
          </li>
        )}
        {safeMode === 'architect' && (
          <li>
            <strong>Capability Selection</strong>– Default view applies no
            capability filters. Use this drop down to instead filter
            capabilities based on SAFE best practice designs or individual
            solutions.
          </li>
        )}
        <li>
          <strong>Flow Type</strong> – colors and visually groups the displayed
          capabilities
        </li>
        <ul className='pl-4 list-[circle]'>
          {safeMode === 'risk' && (
            <li>
              <strong>Risk</strong> – hotter colored capabilities reduce more
              risk, cooler colored capabilities reduce less risk
            </li>
          )}
          <li>
            <strong>Gap</strong> – red is a gap, orange is a partial gap, green
            is covered
          </li>
          <li>
            <strong>Cisco</strong> – future coverage if Cisco recommendations
            are followed
          </li>
          <li>
            <strong>Show Products</strong> – display or hide capability
            alignment to solutions
          </li>
          <li>
            <strong>Show Places</strong> – display or hide capability alignment
            to Place in the Network (PIN)
          </li>
        </ul>
        <li>
          <strong>Place in Network</strong> – applicable part of the network the
          control applies to
        </li>
        <li>
          <strong>Customer Maturity Level</strong> – level of acceptable risk
          based on assets and funding
        </li>
        <HelpContentSecurityFunction />
        <li>
          <strong>Maximum Capabilities per PIN</strong> – control maximum number
          of controls displayed per Place in the Network (PIN)
        </li>
        <li>
          <strong>Path Color</strong> – custom flow color to differentiate
          separate diagrams on the final report
        </li>
        <li>
          <strong>Start Point</strong> – custom flow start point icon and
          display name
        </li>
        <li>
          <strong>End Point</strong> – custom flow end point icon and display
          name
        </li>
        <li>
          <strong>Download Image</strong> - export this report in an image
          format applicable for import into the PowerPoint report template
        </li>
      </ul>
    </>
  );
};

export default FlowHelp;
