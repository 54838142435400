import React, { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

interface Props {
  className?: string;
  children: ReactNode;
  style?: React.CSSProperties;
}

const Card = ({ className = '', children, style }: Props) => {
  const classes = twMerge(
    `p-6 bg-white shadow-md rounded-sm text-gray-800 ${className}`
  );
  return (
    <section className={classes} style={style}>
      {children}
    </section>
  );
};
export default Card;
