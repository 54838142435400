import React, { useEffect, useState } from 'react';
import { Check } from 'react-feather';
import SearchInput from '../../complib/SearchInput';
import appStyles from '../../support/appStyles';
import { highlitContent } from '../helpers';

type MTOption = { id: number; name: string };
type MTOptions = MTOption[];

interface Props {
  options: MTOptions;
  value?: MTOptions;
  onChange: (value: MTOptions) => void;
}

const MultiSearchControl = ({ options, value, onChange }: Props) => {
  const [search, setSearch] = useState<string>('');
  const [filteredOptions, setFilteredOptions] = useState<MTOptions>();
  const optionPosition = (option: MTOption): number => {
    return value?.findIndex ? value?.findIndex((o) => o.id === option.id) : -1;
  };
  const isSelected = (option: MTOption): boolean => optionPosition(option) > -1;

  const toggleSelected = (option: MTOption) => {
    const newValue = [...(value ?? [])];
    const selectedOption = optionPosition(option);
    if (selectedOption > -1) {
      newValue.splice(selectedOption, 1);
    } else {
      newValue.push(option);
    }
    onChange(newValue);
  };

  useEffect(() => {
    const term = search.toLocaleLowerCase().trim();
    setFilteredOptions(
      options
        .filter(
          (option: MTOption) =>
            search.length < 1 || option.name.toLocaleLowerCase().includes(term)
        )
        .sort((a: MTOption, b: MTOption) => (a.name > b.name ? 1 : -1))
    );
  }, [search, options]);

  return (
    <>
      <SearchInput
        value={search}
        changeEvent={(term) => setSearch(term)}
        placeholder='Search options&hellip;'
        className=''
      />
      <div className={`${appStyles.input} bg-white max-h-64 overflow-y-scroll`}>
        {filteredOptions?.map((option: MTOption) => (
          <div
            key={option.id}
            className={`flex text-sm py-1 text-sec-dark-gray ${
              isSelected(option) ? '' : 'opacity-60 bg-sec-light-gray'
            }`}
          >
            <Check
              className={`flex-0 rounded-full p-[2px] mx-1 transition-colors cursor-pointer ${
                isSelected(option)
                  ? 'bg-sec-green text-white hover:bg-success-darker'
                  : 'bg-gray-400 text-gray-400 hover:bg-gray-500'
              } mr-2 cursor-pointer w-5 h-5 flex-0`}
              onClick={() => toggleSelected(option)}
            ></Check>
            <div
              className='font-semibold cursor-pointer hover:underline flex-1'
              onClick={() => toggleSelected(option)}
            >
              {highlitContent(option.name, search)}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default MultiSearchControl;
export { MTOptions, MTOption };
