/**
 * temporary requirement
 *
 * do not show MITRE framework
 * this will be fixed in the DB eventually
 * hopefully
 */
const removeMitre = (name: string) => {
  return name !== 'mitre';
};

/**
 * priorities for capability in 'architect' SAFE mode
 * these are used in place of the threat scoring for priority
 * these haven't been added to the db in time, so hard-coding here
 * :(
 */
const capabilityArbitraryPriority: {
  [key: string]: { name: string; domain: string; priority: number };
} = {
  'Operating System Security': {
    name: 'Operating System Security',
    domain: 'Management: Assets',
    priority: 51,
  },
  'Secure Cloud Compute Configuration': {
    name: 'Secure Cloud Compute Configuration',
    domain: 'Management: Assets',
    priority: 51,
  },
  'Device Lifecycle Management': {
    name: 'Device Lifecycle Management',
    domain: 'Management: Assets',
    priority: 41,
  },
  'Device Image Management': {
    name: 'Device Image Management',
    domain: 'Management: Assets',
    priority: 34,
  },
  'Enterprise Mobility Management/MDM': {
    name: 'Enterprise Mobility Management/MDM',
    domain: 'Management: Assets',
    priority: 32,
  },
  'Endpoint Privacy Screens': {
    name: 'Endpoint Privacy Screens',
    domain: 'Management: Assets',
    priority: 31,
  },
  'Thin Client / Non-Persistent Disk': {
    name: 'Thin Client / Non-Persistent Disk',
    domain: 'Management: Assets',
    priority: 31,
  },
  'Restrict Local Admin Rights': {
    name: 'Restrict Local Admin Rights',
    domain: 'Management: Assets',
    priority: 26,
  },
  'USB / Media Port Security': {
    name: 'USB / Media Port Security',
    domain: 'Management: Assets',
    priority: 13,
  },
  'Boot Code Integrity': {
    name: 'Boot Code Integrity',
    domain: 'Management: Assets',
    priority: 12,
  },
  'Emergency Systems / HVAC': {
    name: 'Emergency Systems / HVAC',
    domain: 'Management: Building Services',
    priority: 22,
  },
  'Vulnerability / Patch Management': {
    name: 'Vulnerability / Patch Management',
    domain: 'Management: Governance',
    priority: 58,
  },
  'Identity Management / 2FA': {
    name: 'Identity Management / 2FA',
    domain: 'Management: Governance',
    priority: 51,
  },
  'Operational / Audit Logging': {
    name: 'Operational / Audit Logging',
    domain: 'Management: Governance',
    priority: 46,
  },
  'Data Backup (Offline)': {
    name: 'Data Backup (Offline)',
    domain: 'Management: Governance',
    priority: 42,
  },
  'Data Backup (Online or Hot Replication)': {
    name: 'Data Backup (Online or Hot Replication)',
    domain: 'Management: Governance',
    priority: 42,
  },
  'Redundancy / DR Planning': {
    name: 'Redundancy / DR Planning',
    domain: 'Management: Governance',
    priority: 42,
  },
  'Configuration / Change Management': {
    name: 'Configuration / Change Management',
    domain: 'Management: Governance',
    priority: 41,
  },
  'Secure Development Lifecycle / Trusted Vendor': {
    name: 'Secure Development Lifecycle / Trusted Vendor',
    domain: 'Management: Governance',
    priority: 41,
  },
  'Training / Security Awareness Campaigns': {
    name: 'Training / Security Awareness Campaigns',
    domain: 'Management: Governance',
    priority: 41,
  },
  'Endpoint Posture Assessment': {
    name: 'Endpoint Posture Assessment',
    domain: 'Management: Governance',
    priority: 41,
  },
  'Threat Intelligence': {
    name: 'Threat Intelligence',
    domain: 'Management: Governance',
    priority: 35,
  },
  'Network Switch / Router Security': {
    name: 'Network Switch / Router Security',
    domain: 'Management: Network Services',
    priority: 51,
  },
  'Network DNS Security': {
    name: 'Network DNS Security',
    domain: 'Management: Network Services',
    priority: 51,
  },
  'Secure Active Directory Configuration': {
    name: 'Secure Active Directory Configuration',
    domain: 'Management: Network Services',
    priority: 51,
  },
  'Time Synchronization': {
    name: 'Time Synchronization',
    domain: 'Management: Network Services',
    priority: 21,
  },
  'Cipher Management / PKI': {
    name: 'Cipher Management / PKI',
    domain: 'Management: Network Services',
    priority: 12,
  },
  'Email Security': {
    name: 'Email Security',
    domain: 'Secure Services',
    priority: 54,
  },
  'Wireless IPS (WIPS) / RRM': {
    name: 'Wireless IPS (WIPS) / RRM',
    domain: 'Secure Services',
    priority: 54,
  },
  'Email Anti-Spam': {
    name: 'Email Anti-Spam',
    domain: 'Secure Services',
    priority: 46,
  },
  'Web Security Proxy': {
    name: 'Web Security Proxy',
    domain: 'Secure Services',
    priority: 35,
  },
  'Cloud Access Security Broker (CASB)': {
    name: 'Cloud Access Security Broker (CASB)',
    domain: 'Secure Services',
    priority: 34,
  },
  'Social Media Security': {
    name: 'Social Media Security',
    domain: 'Secure Services',
    priority: 31,
  },
  'Web Content Filtering': {
    name: 'Web Content Filtering',
    domain: 'Secure Services',
    priority: 29,
  },
  'Web Application Firewall (WAF)': {
    name: 'Web Application Firewall (WAF)',
    domain: 'Secure Services',
    priority: 7,
  },
  'Traffic Encryption / HTTPS / VPN': {
    name: 'Traffic Encryption / HTTPS / VPN',
    domain: 'Segmentation: Encryption',
    priority: 51,
  },
  'Email Encryption': {
    name: 'Email Encryption',
    domain: 'Segmentation: Encryption',
    priority: 32,
  },
  'Endpoint Disk Encryption': {
    name: 'Endpoint Disk Encryption',
    domain: 'Segmentation: Encryption',
    priority: 31,
  },
  'Network Application/Stateful Firewall': {
    name: 'Network Application/Stateful Firewall',
    domain: 'Segmentation: Firewalling',
    priority: 55,
  },
  'Network Access Control': {
    name: 'Network Access Control',
    domain: 'Segmentation: Firewalling',
    priority: 55,
  },
  'Endpoint Stateful Firewall': {
    name: 'Endpoint Stateful Firewall',
    domain: 'Segmentation: Firewalling',
    priority: 49,
  },
  'Cloud Workload Protection / ADM': {
    name: 'Cloud Workload Protection / ADM',
    domain: 'Segmentation: Firewalling',
    priority: 39,
  },
  'File / Directory Permissions': {
    name: 'File / Directory Permissions',
    domain: 'Segmentation: Host',
    priority: 51,
  },
  'Account Use Policies': {
    name: 'Account Use Policies',
    domain: 'Segmentation: Host',
    priority: 45,
  },
  'Endpoint Browser Security / Isolation': {
    name: 'Endpoint Browser Security / Isolation',
    domain: 'Segmentation: Host',
    priority: 31,
  },
  'Door Access Controls': {
    name: 'Door Access Controls',
    domain: 'Segmentation: Physical',
    priority: 30,
  },
  'Bollards / Walls / Fence': {
    name: 'Bollards / Walls / Fence',
    domain: 'Segmentation: Physical',
    priority: 26,
  },
  'Conduit / Buried Cabling / Fiber': {
    name: 'Conduit / Buried Cabling / Fiber',
    domain: 'Segmentation: Physical',
    priority: 26,
  },
  'Mantraps / Cages / Locks': {
    name: 'Mantraps / Cages / Locks',
    domain: 'Segmentation: Physical',
    priority: 26,
  },
  'Personal Protective Equipment (PPE)': {
    name: 'Personal Protective Equipment (PPE)',
    domain: 'Segmentation: Physical',
    priority: 1,
  },
  'Blacklist DNS / IP / URL Filtering': {
    name: 'Blacklist DNS / IP / URL Filtering',
    domain: 'Threat Defense: Cyber',
    priority: 56,
  },
  'Endpoint Anti-Malware': {
    name: 'Endpoint Anti-Malware',
    domain: 'Threat Defense: Cyber',
    priority: 50,
  },
  'Endpoint Detection & Response': {
    name: 'Endpoint Detection & Response',
    domain: 'Threat Defense: Cyber',
    priority: 48,
  },
  'Endpoint Exploit Prevention / HIPS': {
    name: 'Endpoint Exploit Prevention / HIPS',
    domain: 'Threat Defense: Cyber',
    priority: 41,
  },
  'Network Intrusion Prevention System (NIPS)': {
    name: 'Network Intrusion Prevention System (NIPS)',
    domain: 'Threat Defense: Cyber',
    priority: 38,
  },
  'Network SSL Decrypt': {
    name: 'Network SSL Decrypt',
    domain: 'Threat Defense: Cyber',
    priority: 36,
  },
  'Malware / URL Sandboxing': {
    name: 'Malware / URL Sandboxing',
    domain: 'Threat Defense: Cyber',
    priority: 36,
  },
  'Encrypted Traffic Analytics (ETA)': {
    name: 'Encrypted Traffic Analytics (ETA)',
    domain: 'Threat Defense: Cyber',
    priority: 35,
  },
  'Network Malware Filtering': {
    name: 'Network Malware Filtering',
    domain: 'Threat Defense: Cyber',
    priority: 35,
  },
  'DDoS Security / Scrubbing': {
    name: 'DDoS Security / Scrubbing',
    domain: 'Threat Defense: Cyber',
    priority: 16,
  },
  'Deception / Honeynets ': {
    name: 'Deception / Honeynets ',
    domain: 'Threat Defense: Cyber',
    priority: 10,
  },
  'Rate-Limiting / RTBH Filtering': {
    name: 'Rate-Limiting / RTBH Filtering',
    domain: 'Threat Defense: Cyber',
    priority: 10,
  },
  'Cameras / Motion Sensors / Visual Analytics': {
    name: 'Cameras / Motion Sensors / Visual Analytics',
    domain: 'Threat Defense: Physical',
    priority: 30,
  },
  Guards: { name: 'Guards', domain: 'Threat Defense: Physical', priority: 23 },
  'Endpoint Device Profiling (Asset Discovery)': {
    name: 'Endpoint Device Profiling (Asset Discovery)',
    domain: 'Visibility',
    priority: 50,
  },
  'Inventory Management': {
    name: 'Inventory Management',
    domain: 'Visibility',
    priority: 50,
  },
  'SIEM / Security Alert Logging': {
    name: 'SIEM / Security Alert Logging',
    domain: 'Visibility',
    priority: 44,
  },
  'Network Detection & Remediation (NDR)': {
    name: 'Network Detection & Remediation (NDR)',
    domain: 'Visibility',
    priority: 35,
  },
  'Incident Response Tool / SOAR': {
    name: 'Incident Response Tool / SOAR',
    domain: 'Visibility',
    priority: 29,
  },
  'Data Loss Prevention (DLP)': {
    name: 'Data Loss Prevention (DLP)',
    domain: 'Visibility',
    priority: 25,
  },
  'Endpoint RFID / Location Tracking': {
    name: 'Endpoint RFID / Location Tracking',
    domain: 'Visibility',
    priority: 22,
  },
  'Application User Behavior Analytics (UBA)': {
    name: 'Application User Behavior Analytics (UBA)',
    domain: 'Visibility',
    priority: 15,
  },
  'Network Packet Capture': {
    name: 'Network Packet Capture',
    domain: 'Visibility',
    priority: 14,
  },
};
const getCapabilityArbitraryPriority = (
  capName: string,
  domain?: string
): number => {
  let filtered = capabilityArbitraryPriority;
  if (domain) {
    filtered = {};
    Object.keys(capabilityArbitraryPriority)
      .filter((key) => capabilityArbitraryPriority[key].domain === domain)
      .forEach((key) => (filtered[key] = capabilityArbitraryPriority[key]));
  }
  return filtered[capName]?.priority ?? 100;
};

export { removeMitre, getCapabilityArbitraryPriority };
