import React, { useEffect, useState } from 'react';
import AsyncContainer from '../complib/AsyncContainer';
import Card from '../complib/Card';
import { getCapabilityArbitraryPriority } from '../framework-product-tool/hardcodedFutureProblems';
import NoneShowing from '../manage-data/page-elements/NoneShowing';
import appStyles from '../support/appStyles';
import { useQueryItems } from '../support/queries';
import {
  CapabilityProduct,
  CapabilitySettings,
  useCapabilitySelections,
  useCustomCapabilitySelections,
  useMaturitySelections,
  usePinFilterSelections,
  useSafeSettingsSelections,
  useSecurityFunctionSelections,
} from '../support/stores';
import { Capability } from '../types/capability';
import CapabilityStandardFilters from './CapabilityStandardFilters';
import {
  capabilityInStandardFilters,
  SafeProps,
  scoreOpacity,
} from './safe-utils';
import { heatColor } from './SafeFlowChart/chart-constants';

/**
 * newest reqs: 2023-11
 * current product: partial coverage only
 * proposed: anything but hide or full
 * any number
 * add min priority to page
 * current state: show status in a new column
 */

const SafeRecommendations = ({ safeMode = 'risk' }: SafeProps) => {
  const [recommendations, setRecommendations] =
    useState<CapabilitySettings[]>();
  const { selections } = useCapabilitySelections();
  const { settings: safeSettings } = useSafeSettingsSelections();
  const { getSetting } = useSafeSettingsSelections();
  const { selections: pinSelections } = usePinFilterSelections();
  const { selections: secFunSelections } = useSecurityFunctionSelections();
  const { selections: maturitySelections } = useMaturitySelections();
  const { selections: customCapabilitySelections } =
    useCustomCapabilitySelections();
  const capabilityData = useQueryItems('capability');

  function productApplicable(p: CapabilityProduct, status = 'Hide') {
    if (!p.active) {
      return false;
    }
    return status === 'Partial';
  }

  function proposedApplicable(p: CapabilityProduct, status = 'Hide') {
    if (!p.active) {
      return false;
    }
    return ['None', 'Planned', 'Partial'].includes(status);
  }

  const hasProposed = (cs: CapabilitySettings): boolean =>
    !!cs.proposed?.filter((p) => proposedApplicable(p, cs.status)).length;
  const needsWorkCurrent = (cs: CapabilitySettings): boolean =>
    !!cs.products?.filter((p) => productApplicable(p, cs.status)).length;

  const productList = (cs: CapabilitySettings): string => {
    let list = '';
    if (hasProposed(cs)) {
      cs.proposed
        ?.filter((p) => proposedApplicable(p, cs.status))
        .forEach((p, i) => (list += `${i > 0 ? ' OR ' : ''}${p.name}`));
    } else if (needsWorkCurrent(cs)) {
      cs.products
        ?.filter((p) => productApplicable(p, cs.status))
        .forEach(
          (p, i) =>
            (list += `${i > 0 ? ' OR ' : 'Complete deployment of '}${p.name}`)
        );
    }
    return list;
  };

  useEffect(() => {
    if (!Object.keys(selections)?.length || !capabilityData) {
      return;
    }
    const showCapability = (cs: CapabilitySettings): boolean =>
      hasProposed(cs) || needsWorkCurrent(cs);
    setRecommendations(
      [...Object.values(selections)]
        .filter((cs: CapabilitySettings) =>
          capabilityInStandardFilters(
            [
              ...capabilityData,
              ...Object.values(customCapabilitySelections),
            ].find((c: Capability) => c.id === cs.id)
          )
        )
        .filter(showCapability)
        .sort((a, b) => {
          const aScore =
            safeMode === 'architect'
              ? getCapabilityArbitraryPriority(a.name)
              : a.score;
          const bScore =
            safeMode === 'architect'
              ? getCapabilityArbitraryPriority(b.name)
              : b.score;
          return bScore > aScore ? 1 : -1;
        })
    );
  }, [
    selections,
    setRecommendations,
    safeMode,
    capabilityData,
    pinSelections,
    safeSettings,
    maturitySelections,
    secFunSelections,
    customCapabilitySelections,
  ]);

  /**
   * Download no longer required 23-08-08
   *
   * Delete if really not needed
   */

  // const exportImage = async () => {
  //   const sourceElement = document.querySelector(
  //     '#reportImageElement'
  //   ) as HTMLElement;
  //   if (!sourceElement) {
  //     console.error('Unable to find page elements for image.');
  //     return;
  //   }
  //   const canvas = await html2canvas(sourceElement, {
  //     windowWidth: 1920,
  //     scale: 3,
  //     // onclone: (documentClone) => {
  //     // we'll need this: finalize requirements first
  //     // then produce custom print css
  //     // },
  //   });
  //   const image = canvas.toDataURL('image/png', 1.0);
  //   saveAs(image, 'Short_Term_Recommendations');
  // };

  const heatBg = (score: number): string =>
    heatColor(
      score / (getSetting('capabilityMaxScore', 58) as number),
      scoreOpacity
    );

  return (
    <>
      <section className='grid grid-cols-[minmax(200px,1fr)_6fr] gap-8 min-h-0 min-w-0 relative'>
        <section>
          <CapabilityStandardFilters />
        </section>
        <section id='reportImageElement'>
          <h2 className={appStyles.h2}>Recommendations</h2>
          <AsyncContainer ready={capabilityData}>
            <Card>
              <div
                className={`grid ${
                  safeMode === 'risk'
                    ? 'grid-cols-[100px_300px_1fr]'
                    : 'grid-cols-[300px_1fr]'
                }  pb-2`}
              >
                {safeMode === 'risk' && (
                  <div className={`px-1 ${appStyles.h2}`}>Score</div>
                )}
                <div className={appStyles.h2}>Capability</div>
                <div className={appStyles.h2}>Product</div>
              </div>
              <NoneShowing show={!recommendations?.length} />
              {recommendations?.map((r: CapabilitySettings) => (
                <div
                  key={r.id}
                  className={`grid ${
                    safeMode === 'risk'
                      ? 'grid-cols-[100px_300px_1fr]'
                      : 'grid-cols-[300px_1fr]'
                  } py-1 border-t border-sec-light-gray`}
                >
                  {safeMode === 'risk' && (
                    <div
                      className={`${appStyles.numberColorBox}`}
                      style={{
                        backgroundColor: heatBg(r.score as number),
                      }}
                    >
                      {r.score}
                    </div>
                  )}
                  <div className='font-bold flex items-center mr-2'>
                    {r.name}
                  </div>
                  <div className='flex items-center'>{productList(r)}</div>
                </div>
              ))}
            </Card>
          </AsyncContainer>
        </section>
        {/* <Button onClick={exportImage} className='flex mt-4'>
        <Download className='mr-2' />
        Download recommendations image
      </Button> */}
      </section>
    </>
  );
};

export default SafeRecommendations;
