const capabilitySprites: {
  frames: { [key: string]: { x: number; y: number; w: number; h: number } };
  meta: {
    format: string;
    size: {
      w: number;
      h: number;
    };
  };
} = {
  frames: {
    'API Interface': {
      x: 1,
      y: 1,
      w: 130,
      h: 130,
    },
    Analysis_Correlation: {
      x: 133,
      y: 1,
      w: 130,
      h: 130,
    },
    'Anomaly Detection': {
      x: 265,
      y: 1,
      w: 130,
      h: 130,
    },
    AntiMalware: {
      x: 397,
      y: 1,
      w: 130,
      h: 130,
    },
    AntiSpam: {
      x: 529,
      y: 1,
      w: 130,
      h: 130,
    },
    AntiVirus: {
      x: 661,
      y: 1,
      w: 130,
      h: 130,
    },
    'App Visibility Control': {
      x: 793,
      y: 1,
      w: 130,
      h: 130,
    },
    Block: {
      x: 925,
      y: 1,
      w: 130,
      h: 130,
    },
    Cage: {
      x: 1057,
      y: 1,
      w: 130,
      h: 130,
    },
    'Central Management': {
      x: 1,
      y: 133,
      w: 130,
      h: 130,
    },
    'Certificate Authority': {
      x: 133,
      y: 133,
      w: 130,
      h: 130,
    },
    'Certificate Services': {
      x: 265,
      y: 133,
      w: 130,
      h: 130,
    },
    'ClientBased Security': {
      x: 397,
      y: 133,
      w: 130,
      h: 130,
    },
    'Cloud Access Security Broker_CASB': {
      x: 529,
      y: 133,
      w: 130,
      h: 130,
    },
    'Cloud Security': {
      x: 661,
      y: 133,
      w: 130,
      h: 130,
    },
    Conduit: {
      x: 793,
      y: 133,
      w: 130,
      h: 130,
    },
    'Conference Bridge': {
      x: 925,
      y: 133,
      w: 130,
      h: 130,
    },
    'DNS Security': {
      x: 1057,
      y: 133,
      w: 130,
      h: 130,
    },
    'Data Integrity': {
      x: 1,
      y: 265,
      w: 130,
      h: 130,
    },
    'Data Loss Prevention': {
      x: 133,
      y: 265,
      w: 130,
      h: 130,
    },
    Database: {
      x: 265,
      y: 265,
      w: 130,
      h: 130,
    },
    'Device Encryption': {
      x: 397,
      y: 265,
      w: 130,
      h: 130,
    },
    'Device Profiling': {
      x: 529,
      y: 265,
      w: 130,
      h: 130,
    },
    'Device Trajectory': {
      x: 661,
      y: 265,
      w: 130,
      h: 130,
    },
    'Disk Encryption': {
      x: 793,
      y: 265,
      w: 130,
      h: 130,
    },
    'Distributed Denial of Service Protection': {
      x: 925,
      y: 265,
      w: 130,
      h: 130,
    },
    'Email Encryption': {
      x: 1057,
      y: 265,
      w: 130,
      h: 130,
    },
    'Email Security': {
      x: 1,
      y: 397,
      w: 130,
      h: 130,
    },
    FSO: {
      x: 133,
      y: 397,
      w: 130,
      h: 130,
    },
    'Fabric Switching': {
      x: 265,
      y: 397,
      w: 130,
      h: 130,
    },
    Federation: {
      x: 397,
      y: 397,
      w: 130,
      h: 130,
    },
    Fence: {
      x: 529,
      y: 397,
      w: 130,
      h: 130,
    },
    'File Trajectory': {
      x: 661,
      y: 397,
      w: 130,
      h: 130,
    },
    Firewall: {
      x: 793,
      y: 397,
      w: 130,
      h: 130,
    },
    'Firewall Virtual': {
      x: 925,
      y: 397,
      w: 130,
      h: 130,
    },
    'Flow Analytics': {
      x: 1057,
      y: 397,
      w: 130,
      h: 130,
    },
    Guard: {
      x: 1,
      y: 529,
      w: 130,
      h: 130,
    },
    HVAC: {
      x: 133,
      y: 529,
      w: 130,
      h: 130,
    },
    'Host Context': {
      x: 265,
      y: 529,
      w: 130,
      h: 130,
    },
    Identity: {
      x: 397,
      y: 529,
      w: 130,
      h: 130,
    },
    'Intrusion Detection': {
      x: 529,
      y: 529,
      w: 130,
      h: 130,
    },
    'Intrusion Prevention': {
      x: 661,
      y: 529,
      w: 130,
      h: 130,
    },
    'L2-L3 Network': {
      x: 793,
      y: 529,
      w: 130,
      h: 130,
    },
    'L2-L3 Network_Virtual': {
      x: 925,
      y: 529,
      w: 130,
      h: 130,
    },
    'L2 Switching': {
      x: 1057,
      y: 529,
      w: 130,
      h: 130,
    },
    'L2 Switching_Virtual': {
      x: 1,
      y: 661,
      w: 130,
      h: 130,
    },
    'L3 Switching': {
      x: 133,
      y: 661,
      w: 130,
      h: 130,
    },
    'Load Balancer': {
      x: 265,
      y: 661,
      w: 130,
      h: 130,
    },
    Logging_Reporting: {
      x: 397,
      y: 661,
      w: 130,
      h: 130,
    },
    'Malware Sandbox': {
      x: 529,
      y: 661,
      w: 130,
      h: 130,
    },
    Microsegmentation: {
      x: 661,
      y: 661,
      w: 130,
      h: 130,
    },
    'Mobile Device Management': {
      x: 793,
      y: 661,
      w: 130,
      h: 130,
    },
    Monitoring: {
      x: 925,
      y: 661,
      w: 130,
      h: 130,
    },
    'Multi-Factor Authentication': {
      x: 1057,
      y: 661,
      w: 130,
      h: 130,
    },
    'Name Resolution': {
      x: 1,
      y: 793,
      w: 130,
      h: 130,
    },
    'Network Anti Malware': {
      x: 133,
      y: 793,
      w: 130,
      h: 130,
    },
    PPE: {
      x: 265,
      y: 793,
      w: 130,
      h: 130,
    },
    'Policy Configuration': {
      x: 397,
      y: 793,
      w: 130,
      h: 130,
    },
    'Posture Assessment': {
      x: 529,
      y: 793,
      w: 130,
      h: 130,
    },
    Quarantine: {
      x: 661,
      y: 793,
      w: 130,
      h: 130,
    },
    Remediate: {
      x: 793,
      y: 793,
      w: 130,
      h: 130,
    },
    'Remote Access': {
      x: 925,
      y: 793,
      w: 130,
      h: 130,
    },
    RoomAccess: {
      x: 1057,
      y: 793,
      w: 130,
      h: 130,
    },
    Routing: {
      x: 1,
      y: 925,
      w: 130,
      h: 130,
    },
    SOAR_XDR: {
      x: 133,
      y: 925,
      w: 130,
      h: 130,
    },
    'Secure API Gateway': {
      x: 265,
      y: 925,
      w: 130,
      h: 130,
    },
    'Secure File Share': {
      x: 397,
      y: 925,
      w: 130,
      h: 130,
    },
    'Secure X': {
      x: 529,
      y: 925,
      w: 130,
      h: 130,
    },
    'ServerBased Security': {
      x: 661,
      y: 925,
      w: 130,
      h: 130,
    },
    Storage: {
      x: 793,
      y: 925,
      w: 130,
      h: 130,
    },
    Switch: {
      x: 925,
      y: 925,
      w: 130,
      h: 130,
    },
    'TLS Offload': {
      x: 1057,
      y: 925,
      w: 130,
      h: 130,
    },
    Tagging: {
      x: 1,
      y: 1057,
      w: 130,
      h: 130,
    },
    'Threat Intelligence': {
      x: 133,
      y: 1057,
      w: 130,
      h: 130,
    },
    'Time Synchronization': {
      x: 265,
      y: 1057,
      w: 130,
      h: 130,
    },
    'USB-Security': {
      x: 397,
      y: 1057,
      w: 130,
      h: 130,
    },
    User: {
      x: 529,
      y: 1057,
      w: 130,
      h: 130,
    },
    VDC_VPC_OTV_Microsegmentation: {
      x: 661,
      y: 1057,
      w: 130,
      h: 130,
    },
    'VPN Concentrator': {
      x: 793,
      y: 1057,
      w: 130,
      h: 130,
    },
    Video: {
      x: 925,
      y: 1057,
      w: 130,
      h: 130,
    },
    VideoCamera: {
      x: 1057,
      y: 1057,
      w: 130,
      h: 130,
    },
    'Virtual Private Network': {
      x: 1189,
      y: 1,
      w: 130,
      h: 130,
    },
    'Virtualized Capabilities': {
      x: 1189,
      y: 133,
      w: 130,
      h: 130,
    },
    Voice: {
      x: 1189,
      y: 265,
      w: 130,
      h: 130,
    },
    'Vulnerability Management': {
      x: 1189,
      y: 397,
      w: 130,
      h: 130,
    },
    'Web Application Firewall': {
      x: 1189,
      y: 529,
      w: 130,
      h: 130,
    },
    'Web Reputation Filtering_DCS': {
      x: 1189,
      y: 661,
      w: 130,
      h: 130,
    },
    'Web Security': {
      x: 1189,
      y: 793,
      w: 130,
      h: 130,
    },
    'Wireless Connection': {
      x: 1189,
      y: 925,
      w: 130,
      h: 130,
    },
    'Wireless Intrusion Detection System': {
      x: 1189,
      y: 1057,
      w: 130,
      h: 130,
    },
    'Wireless Intrusion Prevention System': {
      x: 1,
      y: 1189,
      w: 130,
      h: 130,
    },
    'Wireless Rogue Detection': {
      x: 133,
      y: 1189,
      w: 130,
      h: 130,
    },
  },
  meta: {
    format: 'RGBA8888',
    size: {
      w: 1320,
      h: 1320,
    },
  },
};

export default capabilitySprites;
