import React, { useEffect, useState } from 'react';
import Dropdown from '../complib/Dropdown';
import Loader from '../complib/Loader';
import Tab from '../complib/Tab';
import TabList from '../complib/TabList';
import { Categories, parseData } from '../support/parseData';
import { useQueryItems } from '../support/queries';
import { removeMitre } from './hardcodedFutureProblems';
import SelectForMapping from './SelectForMapping';

const FrameworkPage = () => {
  const data = useQueryItems('frameworks');
  const [framework, setFramework] = useState<string>();
  const [control, setControl] = useState<string>();
  const [layout, setLayout] = useState<Categories>();

  const changeFramework = (f: string): void => {
    setControl(undefined);
    setFramework(f);
  };

  useEffect(() => {
    if (!data?.length) {
      return;
    }
    setLayout(parseData(data));
  }, [data]);

  return (
    <>
      <section className='flex-0 p-8 pb-0'>
        {!layout && <Loader className='w-16 h-16' />}
        {layout && (
          <>
            <TabList className='uppercase'>
              {Object.keys(layout)
                .filter(removeMitre)
                .map((f) => (
                  <Tab
                    key={f}
                    selected={f === framework}
                    action={() => changeFramework(f)}
                    title={f}
                  />
                ))}
            </TabList>
            {framework && (
              <Dropdown
                value={control}
                placeholder='Select control'
                options={framework ? Object.keys(layout[framework]) : []}
                onChange={(item: string) => setControl(item)}
              />
            )}
          </>
        )}
      </section>
      <section className='flex-1'>
        {framework && control && (
          <SelectForMapping
            entity='frameworks'
            title='Frameworks'
            filter={{
              framework,
              control,
            }}
          />
        )}
      </section>
    </>
  );
};

export default FrameworkPage;
