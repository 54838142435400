import React from 'react';
import { RefreshCcw, Trash2, X } from 'react-feather';
import Button from '../complib/Button';
import ModalFrame from '../complib/ModalFrame';

interface Props {
  close: () => void;
  action: () => void;
  isOpen: boolean;
  title: string;
  body: string[];
  actionButton: string;
  actionIcon: 'refresh' | 'delete';
}

const ConfirmActionModal = ({
  isOpen,
  close,
  action,
  title,
  body,
  actionButton,
  actionIcon,
}: Props) => {
  return (
    <ModalFrame close={close} isOpen={isOpen} title={title}>
      {body.map((text, i) => (
        <p key={i} className='font-sans font-light text-sec-dark-gray mb-2'>
          {text}
        </p>
      ))}
      <div className='flex justify-end mt-6'>
        <Button
          onClick={close}
          className='flex mt-2 mr-2 items-center'
          theme='ghost'
        >
          <X size={20} className='mr-2' />
          Cancel
        </Button>
        <Button
          onClick={action}
          className='flex mt-2 items-center'
          theme='danger'
        >
          {actionIcon === 'refresh' && (
            <RefreshCcw size={20} className='mr-2' />
          )}
          {actionIcon === 'delete' && (
            <Trash2 size={20} className='mr-2 items-center' />
          )}
          {actionButton}
        </Button>
      </div>
    </ModalFrame>
  );
};

export default ConfirmActionModal;
