import { FrameworkElement } from '../types/product';

type Categories = {
  [key: string]: { [key: string]: FrameworkElement[] };
};

const parseData = (data: FrameworkElement[]): Categories => {
  const categories: Categories = {};
  data.forEach((f: FrameworkElement) => {
    categories[f.framework] = categories[f.framework] ?? {};
    categories[f.framework][f.control] =
      categories[f.framework][f.control] ?? [];
    categories[f.framework][f.control].push(f);
  });
  return categories;
};

export { parseData, Categories };
