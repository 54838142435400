import React from 'react';
import appStyles from '../support/appStyles';

interface Props {
  status: { name: string; selected: boolean }[];
  toggleStatus: (statusName: string) => void;
}

const StatusFilter = ({ status, toggleStatus }: Props) => {
  return (
    <div className='text-sm mb-1 flex flex-wrap'>
      {status.map((s) => (
        <div
          key={s.name}
          className={`${appStyles.filterButton} ${
            s.selected
              ? 'bg-sky-darker hover:bg-ocean-lighter'
              : 'bg-gray-400 hover:bg-gray-500'
          }`}
          onClick={() => toggleStatus(s.name)}
        >
          {s.name}
        </div>
      ))}
    </div>
  );
};

export default StatusFilter;
