import React, { useEffect, useState } from 'react';
import AsyncContainer from '../complib/AsyncContainer';
import { SortConfig } from '../safe/safe-utils';
import TableSortHeading from '../safe/TableSortHeading';
import { useQueryItems } from '../support/queries';
import AdminControlForm from './AdminControlForm';
import Control from './api-types/control';
import {
  compareItems,
  defaultSortConfig,
  highlitContent,
  updateSort,
} from './helpers';
import AdminListPage from './page-elements/AdminListPage';
import NoneShowing from './page-elements/NoneShowing';

const styles = {
  colIdentifier: 'flex-0 w-24 mr-2',
  colName: 'flex-0 mr-2 w-56 my-2',
  colDesc: 'flex-1 mr-2 min-w-56',
  colUpdate: 'flex-0 w-56 flex items-center',
};

interface Props {
  sub?: boolean;
}

const AdminControlPage = ({ sub }: Props) => {
  const itemLabel = `${sub ? 'sub' : ''}control`;
  const [search, setSearch] = useState('');
  const [filtered, setFiltered] = useState([]);
  const itemData = useQueryItems(itemLabel, true);
  const [sortConfig, setSortConfig] = useState<SortConfig>(defaultSortConfig);
  const [openForm, setOpenForm] = useState<boolean>(false);
  const [selected, setSelected] = useState<Control>();

  const closeForm = () => {
    setSelected(undefined);
    setOpenForm(false);
  };

  useEffect(() => {
    setFiltered(
      (itemData ?? []).filter((a: Control) =>
        a.name.toLocaleLowerCase().includes(search?.trim().toLocaleLowerCase())
      )
    );
  }, [itemData, setFiltered, search]);

  const changeSort = (key: string) =>
    setSortConfig(updateSort(sortConfig, key));

  const compare = (a: Control, b: Control): number =>
    compareItems(a, b, sortConfig);

  const select = (f?: Control): void => {
    setSelected(f);
    setOpenForm(true);
  };

  const cellContent = (content = '') => highlitContent(content, search);
  return (
    <>
      <AdminListPage
        itemLabel={itemLabel}
        addAction={select}
        header={
          <>
            {!!filtered?.length && (
              <div className='flex text-left text-sm mb-2'>
                <>
                  <TableSortHeading
                    updateSort={changeSort}
                    sort={sortConfig}
                    sortKey='identifier'
                    className={styles.colIdentifier}
                  >
                    Control
                  </TableSortHeading>
                  <TableSortHeading
                    updateSort={changeSort}
                    sort={sortConfig}
                    sortKey='name'
                    className={styles.colName}
                  >
                    Control
                  </TableSortHeading>
                  <TableSortHeading
                    updateSort={changeSort}
                    sort={sortConfig}
                    sortKey='text'
                    className={styles.colDesc}
                  >
                    Description
                  </TableSortHeading>
                  <TableSortHeading
                    updateSort={changeSort}
                    sort={sortConfig}
                    sortKey='discussion'
                    className={styles.colDesc}
                  >
                    Discussion
                  </TableSortHeading>
                  <div className={styles.colUpdate}>Updated</div>
                </>
              </div>
            )}
          </>
        }
        list={
          <>
            <AsyncContainer ready={itemData} className='m-4'>
              {filtered?.sort(compare).map((item: Control) => (
                <div
                  key={item.id}
                  className={`flex items-center border-gray-300 border-t px-4 text-sm`}
                >
                  <div className={styles.colIdentifier}>
                    {cellContent(item.identifier)}
                  </div>
                  <h3
                    className={`${styles.colName} font-semibold hover:underline cursor-pointer`}
                    onClick={() => select(item)}
                  >
                    {cellContent(item.name)}
                  </h3>
                  <div className={styles.colDesc}>{cellContent(item.text)}</div>
                  <div className={styles.colDesc}>
                    {cellContent(item.discussion)}
                  </div>
                  <div className={styles.colUpdate}>{item.updated ?? '-'}</div>
                </div>
              ))}
              <NoneShowing show={!filtered?.length} />
            </AsyncContainer>
          </>
        }
        search={search}
        updateSearch={setSearch}
      />
      {openForm && (
        <AdminControlForm
          item={selected}
          isOpen={openForm}
          close={closeForm}
          sub={sub}
        />
      )}
    </>
  );
};

export default AdminControlPage;
