import * as d3 from 'd3';
import capabilitySprites from '../../manage-data/capabilitySprites';

const getViewBox = (icon: string): string => {
  const data = capabilitySprites.frames[icon];
  return `${data?.x ?? -130} ${data?.y ?? 0} 130 130`;
};

const drawCapabilityIcon = (
  svgSelect: d3.Selection<SVGSVGElement | null, unknown, null, undefined>,
  icon: string,
  x: number,
  y: number,
  size?: number
) => {
  const s = size ?? 132;
  svgSelect
    .append('svg')
    .attr('x', x)
    .attr('y', y)
    .attr('width', s)
    .attr('height', s)
    .attr('viewBox', getViewBox(icon))
    .append('image')
    .attr(
      'xlink:href',
      `${process.env.PUBLIC_URL}/assets/images/capabilityss.png`
    )
    .attr('width', 1320)
    .attr('height', 1320);
};

export default drawCapabilityIcon;
