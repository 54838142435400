import React, { useEffect } from 'react';
import { DefaultValues, useForm } from 'react-hook-form';
import appStyles from '../support/appStyles';
import AdminObjectForm from './AdminObjectForm';

interface PinValues {
  id?: number;
  name: string;
  order: number;
}

interface Props {
  pin?: PinValues;
  close: () => void;
  isOpen: boolean;
}

const displayCategory = 'PIN';
const endpoint = 'pin';

const AdminPinForm = ({ pin, close, isOpen }: Props) => {
  const formConfig = useForm<PinValues>();
  const { register, reset, control, getValues } = formConfig;
  const isNew = (): boolean => !pin?.id;

  const convertToApi = (data: PinValues): PinValues => {
    const apiData: PinValues = {
      ...pin,
      ...data,
    };
    if (typeof apiData.order === 'string') {
      apiData.order = parseInt(apiData.order);
    }
    return apiData;
  };

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    const createDefaultValues = (
      risk?: PinValues
    ): DefaultValues<PinValues> => ({
      id: risk?.id,
      name: risk?.name,
      order: pin?.order,
    });
    reset(createDefaultValues(pin));
  }, [reset, isOpen, pin]);

  return (
    <AdminObjectForm
      close={close}
      isOpen={isOpen}
      endpoint={endpoint}
      convertToApi={convertToApi}
      data={pin ? { ...pin } : undefined}
      displayCategory={displayCategory}
      formConfig={formConfig}
      isNew={() => isNew()}
    >
      <div className='flex-col flex mt-4'>
        <label className='text-sm'>Name</label>
        <input
          className={`${appStyles.input} text-lg py-1`}
          defaultValue={pin ? getValues('name') : ''}
          {...register('name', { required: true })}
        />
      </div>
      <div className='flex-col flex mt-4'>
        <label className='text-sm'>Order</label>
        <input
          type='number'
          step='1'
          min='1'
          className={`${appStyles.input} text-lg py-1`}
          defaultValue={pin ? getValues('order') : 1}
          {...register('order', { required: true })}
        />
      </div>
    </AdminObjectForm>
  );
};

export default AdminPinForm;
