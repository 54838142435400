import React, { useEffect } from 'react';
import appStyles from '../../support/appStyles';
import { useBctCategoriesSettings } from '../../support/stores';
import { verticals } from '../constants';

const BustechCategoryFilter = () => {
  const { getSetting, setSetting, settings } = useBctCategoriesSettings();

  const updateSelectedCategories = (cat: string): void => {
    setSetting(cat, !getSetting(cat));
  };

  useEffect(() => {
    if (Object.keys(settings)?.length) {
      return;
    }
    verticals.forEach((c) => setSetting(c, true));
  }, [settings, setSetting]);

  return (
    <div className='flex flex-wrap text-sm'>
      {verticals.map((cat: string) => (
        <div
          key={cat}
          className={`${appStyles.filterButton} ${
            getSetting(cat)
              ? 'bg-sky-darker hover:bg-ocean-lighter'
              : 'bg-gray-400 hover:bg-gray-500'
          } capitalize`}
          onClick={() => updateSelectedCategories(cat)}
        >
          {cat}
        </div>
      ))}
    </div>
  );
};

export default BustechCategoryFilter;
