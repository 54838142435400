import React, { useEffect, useState } from 'react';
import { X } from 'react-feather';
import Modal from 'react-modal';
import AsyncContainer from '../complib/AsyncContainer';
import Button from '../complib/Button';
import Card from '../complib/Card';
import { openSurveyOnce } from '../safe/safe-utils';
import SafeUsageSurveyModal from '../safe/SafeUsageSurveyModal';
import appStyles from '../support/appStyles';
import {
  generateBusneedsExcel,
  generateFrameworkExcel,
  generateProductExcel,
} from '../support/generate-excel';
import { useQueryItems } from '../support/queries';
import useStore, { ItemSelections } from '../support/store';
import { useSafeSettingsSelections } from '../support/stores';
import { Item } from '../types/item';
import { FrameworkElement } from '../types/product';
import BusneedsTable from './BusneedsTable';
import FrameworksTable from './FrameworksTable';
import ItemsModal from './ItemsModal';
import ProductPageFilters from './ProductPageFilters';
import ProductTable from './ProductTable';

Modal.setAppElement('#root');
interface Props {
  entity: 'product' | 'busneeds' | 'frameworks';
  title: string;
  filter?: {
    framework?: string;
    control?: string;
  };
}

const someSelected = (selected: ItemSelections) =>
  !!selected && Object.keys(selected).some((i) => selected[i].active);

const SelectForMapping = ({ entity, title, filter }: Props) => {
  const setUpStore = useStore((state) => state.populateItems);
  const remove = useStore((state) => state.removeItem);
  const selected = useStore((state) => state.selections[entity]);
  const data = useQueryItems(entity);
  const [items, setItems] = useState<Item[]>();
  const { getSetting } = useSafeSettingsSelections();

  useEffect(() => {
    if (!data?.length) {
      return;
    }
    let filtered = [...data];
    if (filter) {
      filtered = data.filter(
        (item: FrameworkElement) =>
          (!filter.framework || filter.framework === item.framework) &&
          (!filter.control || filter.control === item.control)
      );
    }
    setUpStore(
      entity,
      filtered.map((i: Item) => i.name)
    );
    setItems(filtered);
    if (!filter || filter.control) {
      openModal();
    }
  }, [data, setUpStore, setItems, filter, entity]);
  const [modalIsOpen, setModalOpen] = useState(false);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const exportSheet = () => {
    openSurveyOnce();
    const selectedItems = Object.keys(selected)
      .filter((s) => selected[s])
      .map((s) => items?.find((item) => item.name === s))
      .filter(Boolean);
    switch (entity) {
      case 'product':
        generateProductExcel(selectedItems);
        break;
      case 'frameworks':
        generateFrameworkExcel(selectedItems);
        break;
      case 'busneeds':
        generateBusneedsExcel(selectedItems);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <h2 className='text-3xl font-cisco mt-8 ml-8'>{title}</h2>
      <div className='container mx-8 my-2'>
        <Button onClick={openModal}>Select {title}</Button>
        {someSelected(selected) && (
          <Button onClick={exportSheet} className='mr-2 my-2'>
            Export selected
          </Button>
        )}
      </div>
      {items && (
        <ItemsModal
          isOpen={modalIsOpen}
          closeModal={closeModal}
          data={items}
          entity={entity}
        />
      )}
      <section
        className={`${appStyles.container} grid grid-cols-[minmax(262px,1fr)_6fr] gap-8`}
      >
        <section>{entity === 'product' && <ProductPageFilters />}</section>
        <section className='h-20 w-full border'>
          <AsyncContainer ready={!!items?.length}>
            <div className='col-span-4'>
              {someSelected(selected) && items?.length && (
                <>
                  {Object.keys(selected)
                    .filter((s) => selected[s].active)
                    .map((s) => (
                      <Card key={s} className='mb-4 relative'>
                        <h2 className='font-cisco flex-none text-2xl pb-2'>
                          {s}
                        </h2>
                        <Button
                          theme='ghost'
                          onClick={() => remove(entity, s)}
                          className='top-4 right-4 px-1 py-1 absolute mr-0'
                        >
                          <X size={16} />
                        </Button>
                        {entity === 'product' && <ProductTable name={s} />}
                        {entity === 'busneeds' && <BusneedsTable name={s} />}
                        {entity === 'frameworks' && (
                          <FrameworksTable name={s} />
                        )}
                      </Card>
                    ))}
                </>
              )}
            </div>
          </AsyncContainer>
        </section>
      </section>
      {(getSetting('showSurvey') as boolean) && (
        <SafeUsageSurveyModal context='framework' />
      )}
    </>
  );
};
export default SelectForMapping;
