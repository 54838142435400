import { DefaultOptions, QueryCache, QueryClient, useQuery } from 'react-query';
import { Busneed } from '../types/busneed';
import { Framework } from '../types/framework';
import { Item } from '../types/item';
import { Product } from '../types/product';
import { getToken } from './utils';

// this was used for the json-server api
// const api = http://localhost:3004;
// this one is the flask api
const api = process.env.REACT_APP_API;

type Method = 'DELETE' | 'GET' | 'POST' | 'PUT';
interface IDItem {
  id?: number;
}

const queryConfig = {
  refetchOnMount: false,
  refetchOnReconnect: false,
  refetchOnWindowFocus: false,
  staleTime: 1000 * 60 * 60,
  cacheTime: 1200 * 60 * 60,
  retry: true,
  retryDelay: 2000,
};

const adminConfig = {
  ...queryConfig,
  refetchOnMount: true,
  refetchOnReconnect: true,
  refetchOnWindowFocus: true,
  staleTime: 0,
  cacheTime: 5 * 60 * 60,
};

const queryClient: QueryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error, query) => {
      console.error('Query error: ', error, query);
    },
  }),
  defaultOptions: queryConfig as DefaultOptions<unknown>,
});

const fetchItems = async (endpoint: string) => {
  if (!endpoint) {
    return [];
  }
  const identityToken = getToken();
  const itemsResponse = await fetch(`${api}/${endpoint}`, {
    headers: {
      // 'Cognito-Auth-Token': token as string,
      Authorization: `Bearer ${identityToken}`,
    },
  });
  if (!itemsResponse.ok) {
    throw new Error(itemsResponse.statusText);
  }
  return itemsResponse.json();
};

const updateItem = async <T extends IDItem>(
  endpoint: string,
  method: Method,
  item: T
) => {
  const token = getToken();
  const response = await fetch(
    `${api}/${endpoint}${item.id ? `/${item.id}` : ''}`,
    {
      headers: {
        // 'Cognito-Auth-Token': token as string,
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      method,
      body: ['POST', 'PUT'].includes(method) ? JSON.stringify(item) : undefined,
    }
  );
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response.json();
};

const getQueryCacheFrom = <T extends Item>(
  key: string,
  id: string
): T | undefined => {
  const items = queryClient.getQueryData<T[]>(key);
  return (items ?? []).find((d: any) => d.name === id);
};

const fetchItem = async <T extends Item>(
  type: string,
  name: string
): Promise<T | undefined> => {
  const items: T[] = await fetchItems(type);
  const found = items.find((item) => item.name === name);
  return found;
};

const fetchProduct = async (name: string): Promise<Product | undefined> =>
  fetchItem('product', name);

const fetchBusneed = async (name: string): Promise<Busneed | undefined> =>
  fetchItem('busneeds', name);

const fetchFramework = async (name: string): Promise<Framework | undefined> =>
  fetchItem('frameworks', name);

const useQueryItems = (endpoint: string, isAdmin = false) => {
  const { data } = useQuery(
    endpoint,
    () => fetchItems(endpoint),
    isAdmin ? adminConfig : queryConfig
  );
  return data;
};

const postItem = <T extends IDItem>(endpoint: string, item: T) =>
  updateItem(endpoint, 'POST', item);
const putItem = <T extends IDItem>(endpoint: string, item: T) =>
  updateItem(endpoint, 'PUT', item);
const deleteItem = <T extends IDItem>(endpoint: string, item: T) =>
  updateItem(endpoint, 'DELETE', item);

export {
  queryConfig,
  fetchProduct,
  fetchBusneed,
  fetchFramework,
  queryClient,
  getQueryCacheFrom,
  useQueryItems,
  postItem,
  putItem,
  deleteItem,
  Method,
};
