import React, { useEffect, useState } from 'react';
import { Image, Plus, X } from 'react-feather';
import Button from '../complib/Button';
import Loader from '../complib/Loader';
import Switch from '../complib/Switch';
import { useQueryItems } from '../support/queries';
import {
  ScoreSettings,
  useBctRiskSelections,
  useSafeSettingsSelections,
} from '../support/stores';
import { sortedObjectArray } from '../support/utils';
import { Bustech } from '../types/bustech';
import BCTRiskPanel from './BCTRiskPanel';
import HelpSection from '../help/HelpSection';
import BCTPanel from './BCTPanel';
import Modal from 'react-modal';
import appStyles from '../support/appStyles';
import { SortConfig, SortDir } from './safe-utils';
import { Risk } from '../types/risk';
import BCTHelp from '../help/BCTHelp';
import BCTRiskPrintModal from './BCTRiskPrintModal';

const riskWorker: Worker = new Worker('/risk-worker.js');

const BCTRiskPage = () => {
  const {
    selections: selectedBcts,
    setItems,
    isInitialized,
    setItem,
  } = useBctRiskSelections();
  const bctData = useQueryItems('bustech');
  const riskData = useQueryItems('risk');
  const [bcts, setBcts] = useState<Bustech[]>();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [newBCT, setNewBCT] = useState<string>('');
  const [newDesc, setNewDesc] = useState<string>('');
  const [viewImage, setViewImage] = useState<boolean>(false);
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    key: 'name',
    dir: 1,
  });
  const { getSetting: getSafeSetting, setSetting: setSafeSetting } =
    useSafeSettingsSelections();

  useEffect(() => {
    if (!bctData?.length) {
      return;
    }
    setBcts(sortedObjectArray<Bustech>(bctData, 'name'));
  }, [bctData, setBcts, selectedBcts]);

  useEffect(() => {
    if (!bcts?.length || !riskData?.length || isInitialized()) {
      return;
    }
    riskWorker.postMessage([bcts, riskData]);
    riskWorker.onmessage = (event: MessageEvent) => {
      const { data } = event;
      setItems(data.payload);
    };
  }, [setItems, bcts, selectedBcts, riskData, isInitialized]);

  const updateShowAll = () => {
    setSafeSetting(
      'showAllBcts',
      !getSafeSetting('showAllBcts', true) as boolean
    );
  };

  const addBCT = () => {
    if (!newBCT.trim()) {
      return;
    }
    const group = (groupName: string): ScoreSettings => ({
      active: true,
      score: ['Service Disruption', 'Unauthorized Access'].includes(groupName)
        ? 50
        : 75,
      id: 0,
    });
    const groups: { [key: string]: ScoreSettings } = {};
    riskData.forEach((risk: Risk) => {
      groups[risk.impact] = { ...group(risk.impact) };
    });
    const id = Math.random();
    setItem(id, {
      id,
      name: newBCT,
      type: 'Specific',
      risks: {},
      groups,
      count: 0,
      score: 100,
      description: newDesc,
    });
    closeAddBCT();
  };

  const closeAddBCT = () => {
    setIsEditing(false);
    setNewBCT('');
    setNewDesc('');
  };

  const updateSort = (key: string) => {
    let dir = key === 'name' ? 1 : -1;
    if (key === sortConfig.key) {
      dir = -sortConfig.dir;
    }
    setSortConfig({ key, dir: dir as SortDir });
  };

  const showAll = (): boolean => getSafeSetting('showAllBcts', true) as boolean;

  const currentBct = (): number => getSafeSetting('currentBct', 0) as number;

  return (
    <>
      <section className='grid grid-cols-[minmax(560px,1fr)_2fr] gap-8'>
        <section>
          <div className='flex justify-end h-0 items-center relative top-6'>
            <p className='mx-2 text-sm'>Show unused</p>
            <Switch value={showAll()} onClick={updateShowAll} />
          </div>
          <h2 className={appStyles.h2}>Business Critical Technologies</h2>
          {!isInitialized() && <Loader className='w-16 h-16' />}
          {isInitialized() && (
            <>
              {(showAll() ||
                Object.values(selectedBcts).some(
                  (b) => b.type === 'Core' && b.active
                )) && <h3 className='font-semibold text-lg mb-4'>Common</h3>}
              <BCTPanel
                showAll={showAll()}
                bcts={
                  Object.values(selectedBcts).filter(
                    (bct) => bct.type === 'Core'
                  ) ?? []
                }
              />
              {(showAll ||
                Object.values(selectedBcts).some(
                  (b) => b.type !== 'Core' && b.active
                )) && <h3 className='font-semibold text-lg mb-4'>Specific</h3>}
              <BCTPanel
                showAll={showAll()}
                bcts={
                  Object.values(selectedBcts).filter(
                    (bct) => bct.type !== 'Core'
                  ) ?? []
                }
              />
            </>
          )}
          {isInitialized() && (
            <>
              <Button
                className='flex items-center'
                onClick={() => setIsEditing(true)}
              >
                <Plus size={20} className='mr-1' />
                Add BCT
              </Button>
              <br />
              <Button
                onClick={() => setViewImage(true)}
                className='flex items-center'
              >
                <Image size={20} className='mr-2' />
                Generate image download
              </Button>
              {isEditing && (
                <Modal
                  isOpen={isEditing}
                  onRequestClose={closeAddBCT}
                  overlayClassName='bg-[rgba(0,0,0,0.2)] fixed top-0 left-0 right-0 bottom-0 flex h-[100vh] w-[100vw] items-center justify-center'
                  className='max-h-[83vh] relative bg-gray-200 rounded-sm shadow-lg p-6 w-5/6 max-w-xl'
                >
                  <Button
                    theme='ghost'
                    onClick={closeAddBCT}
                    className='top-4 right-4 px-1 py-1 absolute mr-0'
                  >
                    <X size={16} />
                  </Button>
                  <h2 className='font-cisco text-xl mb-4'>
                    Add a Business Critical Technology
                  </h2>
                  <div>
                    <label>Name</label>
                    <input
                      className={`${appStyles.input} px-4 py-2 text-xl w-full mb-4`}
                      type='text'
                      value={newBCT}
                      onChange={(e) => setNewBCT(e.target.value)}
                    />
                    <label>Description</label>
                    <textarea
                      className={`${appStyles.input} px-4 py-2 w-full mb-4`}
                      value={newDesc}
                      onChange={(e) => setNewDesc(e.target.value)}
                    />
                    <Button onClick={addBCT} className='flex mt-2 items-center'>
                      <Plus size={20} className='mr-2' />
                      Add custom BCT
                    </Button>
                  </div>
                </Modal>
              )}
            </>
          )}
        </section>
        <section className='max-h-full overflow-y-auto'>
          {!!currentBct() && (
            <BCTRiskPanel
              bctKey={currentBct()}
              sortConfig={sortConfig}
              updateSort={updateSort}
            />
          )}
        </section>
      </section>
      <BCTRiskPrintModal close={() => setViewImage(false)} isOpen={viewImage} />
      <HelpSection>
        <BCTHelp />
      </HelpSection>
    </>
  );
};

export default BCTRiskPage;
