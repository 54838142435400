import React, { useEffect, useState } from 'react';
import AsyncContainer from '../complib/AsyncContainer';
import { SortConfig } from '../safe/safe-utils';
import TableSortHeading from '../safe/TableSortHeading';
import { useQueryItems } from '../support/queries';
import { Product } from '../types/product';
import AdminProductForm from './AdminProductForm';
import {
  compareItems,
  defaultSortConfig,
  highlitContent,
  updateSort,
} from './helpers';
import AdminListPage from './page-elements/AdminListPage';
import NoneShowing from './page-elements/NoneShowing';

const styles = {
  colDescription: 'flex-1 mr-2',
  colName: 'flex-0 mr-2 w-60 my-2',
  colUpdate: 'flex-0 w-56 flex items-center',
  colFilter: 'flex-0 w-40 mr-2',
  colIcon: 'w-8 mr-2 flex items-center',
};

const AdminProductPage = () => {
  const productData = useQueryItems('product', true);

  const [filteredProducts, setFilteredProducts] = useState<Product[]>();
  const [sortConfig, setSortConfig] = useState<SortConfig>(defaultSortConfig);
  const [openForm, setOpenForm] = useState<boolean>(false);
  const [selectedProduct, setSelectedProduct] = useState<Product>();
  const [search, setSearch] = useState('');

  const closeForm = () => {
    setSelectedProduct(undefined);
    setOpenForm(false);
  };

  useEffect(() => {
    setFilteredProducts(
      (productData ?? []).filter((p: Product) =>
        [p.name, p.description, p.filter].some((content) =>
          (content ?? '')
            .toLocaleLowerCase()
            .includes(search?.trim().toLocaleLowerCase())
        )
      )
    );
  }, [productData, setFilteredProducts, search]);

  const changeSort = (key: string) =>
    setSortConfig(updateSort(sortConfig, key));

  const compareProducts = (a: Product, b: Product): number =>
    compareItems(a, b, sortConfig);

  const selectProduct = (cap?: Product): void => {
    setSelectedProduct(cap);
    setOpenForm(true);
  };
  return (
    <>
      <AdminListPage
        itemLabel='product'
        search={search}
        updateSearch={setSearch}
        addAction={selectProduct}
        header={
          <>
            {!!filteredProducts?.length && (
              <div className='flex text-left text-sm mb-2'>
                <>
                  <TableSortHeading
                    updateSort={changeSort}
                    sort={sortConfig}
                    sortKey='name'
                    className={styles.colName}
                  >
                    Product
                  </TableSortHeading>
                  <div className={styles.colIcon}></div>
                  <TableSortHeading
                    updateSort={changeSort}
                    sort={sortConfig}
                    sortKey='filter'
                    className={styles.colFilter}
                  >
                    Filter group
                  </TableSortHeading>
                  <TableSortHeading
                    updateSort={changeSort}
                    sort={sortConfig}
                    sortKey='description'
                    className={styles.colDescription}
                  >
                    Description
                  </TableSortHeading>
                  <div className={styles.colUpdate}>Updated</div>
                </>
              </div>
            )}
          </>
        }
        list={
          <AsyncContainer ready={productData} className='m-4'>
            {filteredProducts?.sort(compareProducts).map((product: Product) => (
              <div
                key={product.id}
                className={`flex items-center border-gray-300 border-t px-4 text-sm`}
              >
                <h3
                  className={`${styles.colName} font-semibold hover:underline cursor-pointer`}
                  onClick={() => selectProduct(product)}
                >
                  {highlitContent(product.name, search)}
                </h3>
                <div className={styles.colIcon}>
                  <div className='rounded-full w-8 h-8 flex-0 bg-sec-light-gray flex'>
                    {product.image && (
                      <img
                        src={`/${product.image}`}
                        alt={product.name}
                        className='min-h-[32px] min-w-[32px] rounded-full object-cover'
                      />
                    )}
                  </div>
                </div>
                <div className={styles.colFilter}>
                  {highlitContent(product.filter, search)}
                </div>
                <div className={`${styles.colDescription} py-1`}>
                  {highlitContent(product.description, search)}
                </div>
                <div className={styles.colUpdate}>{product.update ?? '-'}</div>
              </div>
            ))}
            <NoneShowing show={!filteredProducts?.length} />
          </AsyncContainer>
        }
      />
      {openForm && (
        <AdminProductForm
          product={selectedProduct}
          isOpen={openForm}
          close={closeForm}
        />
      )}
    </>
  );
};

export default AdminProductPage;
