import React from 'react';
import appStyles from '../support/appStyles';

const BCTHelp = () => {
  return (
    <>
      <h2 className={appStyles.h2}>
        SAFE Risk Assessment – BCTs &amp; Threats (1)
      </h2>
      <p className='mb-4'>
        On this page you can input data gathered from your customer relating to
        their Business Critical Technology (BCT) stacks. Applicable BCTs are
        selected or created on the left and the BCT criticality scores are input
        based on the likely worse-case scenario attack.
      </p>
      <p>
        Each BCT has an edit button that can be leveraged to granularly modify
        threat alignment and scoring based on individual threats or threat
        categories.
      </p>
    </>
  );
};

export default BCTHelp;
