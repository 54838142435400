import React, { useEffect, useState } from 'react';
import { StoreApi, UseBoundStore } from 'zustand';
import AsyncContainer from '../complib/AsyncContainer';
import appStyles from '../support/appStyles';
import { useQueryItems } from '../support/queries';
import {
  ItemSelections,
  ItemSelectionsState,
  ItemSettings,
} from '../support/selections.store';
import { UniObject } from '../types/item';

interface Props {
  endpoint?: string;
  options?: UniObject[];
  selectionsHook: UseBoundStore<
    ItemSelectionsState<ItemSettings>,
    StoreApi<ItemSelectionsState<ItemSettings>>
  >;
  groupings?: { [group: string]: string[] };
}

const MultiToggleFilter = ({
  endpoint,
  selectionsHook,
  options,
  groupings,
}: Props) => {
  const data = useQueryItems(endpoint ?? '');
  const toggles = options ?? data;
  const { selections, setItems, selectItem, deselectItem } = selectionsHook();
  const [displayGroupings, setDisplayGroupings] = useState<{
    [key: string]: UniObject[];
  }>({});

  useEffect(() => {
    if (!toggles) {
      return;
    }
    const displayGroups: { [key: string]: UniObject[] } = {};
    if (groupings) {
      Object.keys(groupings).forEach((groupName: string) => {
        displayGroups[groupName] = groupings[groupName].map((pName: string) =>
          toggles.find((t: UniObject) => t.name === pName)
        );
      });
    }
    displayGroups.Other = toggles
      .filter((o: UniObject) => {
        if (!groupings) {
          return true;
        } else {
          return !Object.values(groupings).flat().includes(o.name);
        }
      })
      .sort((a: UniObject, b: UniObject) => (a.name < b.name ? -1 : 1));
    setDisplayGroupings(displayGroups);
  }, [setDisplayGroupings, groupings, toggles]);

  const toggleAll = (select = true): void => {
    const newSelections = { ...selections };
    Object.values(newSelections).forEach((v) => (v.active = select));
    setItems(newSelections);
  };

  const isAll = (selected = true): boolean => {
    return Object.values(selections).every((s) => s.active === selected);
  };

  const toggleItem = (id: number) => {
    selections[id] && selections[id].active ? deselectItem(id) : selectItem(id);
  };

  useEffect(() => {
    if (!!Object.values(selections).length || !toggles?.length) {
      return;
    }
    const newSelections: ItemSelections<ItemSettings> = {};
    toggles.forEach((item: UniObject) => {
      newSelections[item.id] = {
        ...item,
        id: item.id,
        active: true,
      };
    });
    setItems(newSelections);
  }, [selections, toggles, setItems]);

  return (
    <>
      <AsyncContainer ready={toggles}>
        {toggles && selections && (
          <>
            <div className='text-xs flex flex-wrap'>
              <div
                onClick={() => toggleAll(true)}
                className={`${appStyles.filterButton} ${
                  isAll() ? 'bg-sec-dark-gray' : 'bg-gray-400 hover:bg-gray-500'
                }`}
              >
                All
              </div>
              <div
                onClick={() => toggleAll(false)}
                className={`${appStyles.filterButton} ${
                  isAll(false)
                    ? 'bg-sec-dark-gray'
                    : 'bg-gray-400 hover:bg-gray-500'
                }`}
              >
                None
              </div>
            </div>
            {Object.keys(displayGroupings).map((group: string) => (
              <section
                key={group}
                className={
                  groupings
                    ? 'bg-gray-100 mb-2 border border-gray-400 px-1 rounded-sm'
                    : ''
                }
              >
                {groupings && (
                  <h3 className='bg-sec-light-gray -mx-1 mb-1 px-2 py-[1px] text-center text-sm'>
                    {group}
                  </h3>
                )}
                <div className='text-sm flex flex-wrap'>
                  {displayGroupings[group]
                    .filter((item: UniObject) => !!item?.id)
                    .map((item: UniObject) => (
                      <div
                        key={item.id}
                        className={`${appStyles.filterButton} ${
                          selections[item.id] && selections[item.id].active
                            ? 'bg-sky-darker hover:bg-ocean-lighter'
                            : 'bg-gray-400 hover:bg-gray-500'
                        }`}
                        onClick={() => toggleItem(item.id)}
                      >
                        {item.name}
                      </div>
                    ))}
                </div>
              </section>
            ))}
          </>
        )}
      </AsyncContainer>
    </>
  );
};

export default MultiToggleFilter;
