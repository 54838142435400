import React from 'react';
import { Check } from 'react-feather';
import { Item } from '../types/item';
import useStore from '../support/store';

interface Props {
  item: Item;
  type: string;
}

const ItemSelectBox = ({ item, type }: Props) => {
  const selections = useStore((state) => state.selections[type]);
  const add = useStore((state) => state.addItem);
  const remove = useStore((state) => state.removeItem);

  return (
    <>
      <div
        className={`transition-colors p-2 cursor-pointer rounded-sm ${
          selections[item.name]?.active ? 'bg-white' : 'bg-gray-200'
        } hover:bg-gray-300 flex`}
        onClick={() =>
          selections[item.name].active
            ? remove(type, item.name)
            : add(type, item.name)
        }
      >
        {item.image && (
          <img
            src={item.image}
            alt={item.name}
            className='w-12 h-12 rounded-full mr-2 flex-0'
          />
        )}
        <div className='flex-1'>
          <h3 className='text-lg font-cisco flex flex-row'>
            <span className='flex-1'>{item.name}</span>
            <Check
              className={`flex-0 rounded-full p-1 transition-colors ${
                selections[item.name].active
                  ? 'bg-success text-white'
                  : 'bg-gray-400 text-gray-400'
              }`}
            ></Check>
          </h3>
          <p className='text-xs'>{item.description}</p>
        </div>
      </div>
    </>
  );
};

export default ItemSelectBox;
