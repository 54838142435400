import React, { useEffect, useState } from 'react';
import Card from '../../complib/Card';
import Loader from '../../complib/Loader';
import appStyles from '../../support/appStyles';
import { useQueryItems } from '../../support/queries';
import { CapabilityRisk } from '../../types/capability';
import { SortConfig } from '../safe-utils';
import TableSortHeading from '../TableSortHeading';
import {
  changeSorting,
  DisplayType,
  itemDisplay,
  itemStyle,
  reportPrintOmitRows,
} from './report-utils';

interface ReportRisk {
  name: string;
  impact: string;
}

interface Props {
  filteredRisks: string[] | undefined;
  highlight: CapabilityRisk[];
  select: (r: { name: string; impact: string } | null) => void;
  currentSelection: { id: string; type: string } | null;
}

const ReportRiskPanelArchitect = ({
  filteredRisks,
  highlight,
  select,
  currentSelection,
}: Props) => {
  const riskData = useQueryItems('risk');
  const [risks, setRisks] = useState<ReportRisk[]>();
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    key: 'name',
    dir: 1,
  });

  useEffect(() => {
    setRisks(riskData);
  }, [riskData]);

  const compareRisks = (a: CapabilityRisk, b: CapabilityRisk): number => {
    const aComp = a.name as number | string;
    const bComp = b.name as number | string;
    if (aComp === bComp) {
      return (a.name ?? a.impact) > (b.name ?? b.impact) ? 1 : -1;
    }
    return aComp > bComp ? sortConfig.dir : -sortConfig.dir;
  };

  const updateSort = (key: string) =>
    setSortConfig(changeSorting(sortConfig, key));

  const showRisk = (risk: ReportRisk): boolean => {
    if (!filteredRisks) {
      return true;
    }
    return filteredRisks.includes(risk.name as string);
  };

  const riskDisplayType = (r: ReportRisk): DisplayType =>
    itemDisplay(
      currentSelection,
      r,
      'risk',
      highlight.filter((h) => h.impact === r.impact).map((h) => h.name)
    );

  return (
    <section>
      {!risks && <Loader className='w-16 h-16' />}
      {risks && (
        <>
          <header className='text-center'>
            <h2 className={`${appStyles.h2} image-vertical-offset`}>Risks</h2>
            <div
              className='flex items-stretch text-sm mb-2 -mr-6'
              data-html2canvas-ignore
            >
              <TableSortHeading
                updateSort={updateSort}
                sort={sortConfig}
                sortKey='name'
                className='flex-1 flex justify-items-center'
              >
                Risk
              </TableSortHeading>
            </div>
          </header>
          <Card className={`p-0`}>
            {Object.values(risks)
              .filter(showRisk)
              .sort(compareRisks)
              .map((r, n) => (
                <div
                  key={`${r.impact}-${r.name}`}
                  className={itemStyle(riskDisplayType(r))}
                  onClick={() => select(r)}
                  {...reportPrintOmitRows(n)}
                >
                  <h3 className='font-cisco flex-1 mx-2 my-1 image-vertical-offset'>
                    {r.name}
                  </h3>
                </div>
              ))}
          </Card>
        </>
      )}
    </section>
  );
};

export default ReportRiskPanelArchitect;
