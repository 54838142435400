import { ItemSelections, ItemSettings } from './selections.store';

type LocalStorageValue = ItemSelections<ItemSettings> | any;

const getLocalStorageValue = (
  key: string,
  defaultValue: LocalStorageValue = {}
): LocalStorageValue => {
  const savedString = localStorage.getItem(key);
  const savedValue = JSON.parse(savedString ?? '{}');
  return savedValue ?? defaultValue;
};

const saveLocalStorageValue = (key: string, value: LocalStorageValue): void => {
  const stringValue = JSON.stringify(value);
  localStorage.setItem(key, stringValue);
};

const getKeyRegex = (regex: RegExp): string | undefined => {
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (key && regex.test(key)) {
      return localStorage.getItem(key) as string;
    }
  }
  return;
};

const getSavedIDToken = (): string | undefined =>
  getKeyRegex(/^CognitoIdentityServiceProvider.+idToken$/);

export {
  LocalStorageValue,
  getLocalStorageValue,
  saveLocalStorageValue,
  getSavedIDToken,
};
