import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import Loader from '../complib/Loader';
import {
  fetchBusneed,
  getQueryCacheFrom,
  queryConfig,
} from '../support/queries';
import { Busneed } from '../types/busneed';
import { Product } from '../types/product';
import ItemsTable, { TableData } from './ItemsTable';

interface Props {
  name: string;
}

const BusneedsTable = ({ name }: Props) => {
  const { data } = useQuery(`busneed-${name}`, () => fetchBusneed(name), {
    ...queryConfig,
    initialData: getQueryCacheFrom<Busneed>('busneeds', name),
  });
  const [tableData, setTableData] = useState<TableData>();
  useEffect(() => {
    if (!data) {
      setTableData(undefined);
    } else {
      const tData: TableData = {
        columns: ['Product Name', 'Product Coverage', 'Coverage Description'],
        rows: data.product?.map((product: Product) => [
          product.name,
          product.coverage,
          product.des,
        ]),
      };
      setTableData(tData);
    }
  }, [data]);

  return (
    <>
      {!tableData && <Loader />}
      {!!tableData?.rows.length && <ItemsTable tableData={tableData} />}
    </>
  );
};

export default BusneedsTable;
