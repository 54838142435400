import create from 'zustand';
interface ItemSettings {
  id: number;
  name?: string;
  active?: boolean;
}
interface ItemSelections<T extends ItemSettings> {
  [id: number]: T;
}
interface ItemSelectionsState<T extends ItemSettings> {
  selections: ItemSelections<T>;
  isInitialized: () => boolean;
  setItems: (items: ItemSelections<T>) => void;
  removeItem: (id: number, settings?: T) => void;
  setItem: (id: number, settings: T) => void;
  getItemSetting: (id: number, setting: string, fallback?: unknown) => unknown;
  batchSetSettings: (
    items: ItemSelections<T>,
    setRemaining?: Partial<T>
  ) => void;
  setItemSetting: (id: number, setting: string, value: unknown) => void;
  selectItem: (id: number) => void;
  deselectItem: (id: number) => void;
  selectAll: () => void;
  deselectAll: () => void;
}

const initItem = <T extends ItemSettings>(settings: T) => ({
  ...settings,
  name: settings.name ?? settings.id?.toString(),
  active: settings.active ?? true,
});

const getSelectionsStoreHook = <T extends ItemSettings>(
  selections: ItemSelections<T> = {},
  persist?: (selections: ItemSelections<T>) => void
) =>
  create<ItemSelectionsState<T>>((set, get) => ({
    selections,
    isInitialized: () => !!Object.keys(get().selections).length,
    setItems: (items: ItemSelections<T>) => {
      const fullItems: ItemSelections<T> = {};
      Object.entries(items).forEach(([id, item]) => {
        fullItems[Number(id)] = initItem(item);
      });
      if (persist) {
        persist(fullItems);
      }
      set(() => {
        return { selections: fullItems };
      });
    },
    removeItem: (id: number) =>
      set((state) => {
        delete state.selections[id];
        if (persist) {
          persist(state.selections);
        }
        return { selections: state.selections };
      }),
    setItem: (id: number, settings: T) =>
      set((state) => {
        state.selections[id] = initItem(settings);
        if (persist) {
          persist(state.selections);
        }
      }),
    selectItem: (id: number) => get().setItemSetting(id, 'active', true),
    deselectItem: (id: number) => get().setItemSetting(id, 'active', false),
    getItemSetting: (id: number, setting: string, fallback: unknown = null) => {
      const item = get().selections[id];
      if (!item) {
        return fallback;
      }
      return item[setting as keyof T] ?? fallback;
    },
    batchSetSettings: (items: ItemSelections<T>, setRemaining?: Partial<T>) =>
      set((state) => {
        const selections: ItemSelections<T> = state.selections;
        if (setRemaining) {
          Object.entries(selections).forEach(([k, v]) => {
            const key = parseInt(k);
            selections[key] = { ...v, ...setRemaining };
          });
        }
        Object.entries(items).forEach(([k, v]) => {
          selections[Number(k)] = Object.assign(
            {},
            selections[Number(k)] ?? initItem(v),
            v
          );
        });
        if (persist) {
          persist(selections);
        }
        return {
          selections: { ...selections },
        };
      }),
    setItemSetting: (id: number, setting: string, value: unknown) =>
      set((state) => {
        state.selections[id] = Object.assign({}, state.selections[id], {
          [setting]: value,
        });
        const newSelections = {
          ...state.selections,
          [id]: state.selections[id],
        };
        if (persist) {
          persist(newSelections);
        }
        return {
          selections: newSelections,
        };
      }),
    selectAll: () =>
      set((state) => {
        Object.values(state.selections).forEach(
          (item: ItemSettings) => (item.active = true)
        );
        if (persist) {
          persist(state.selections);
        }
        return { selections: state.selections };
      }),
    deselectAll: () =>
      set((state) => {
        Object.values(state.selections).forEach(
          (item: ItemSettings) => (item.active = false)
        );
        if (persist) {
          persist(state.selections);
        }
        return { selections: state.selections };
      }),
  }));

export {
  ItemSettings,
  ItemSelections,
  ItemSelectionsState,
  getSelectionsStoreHook,
};
