import appStyles from '../../support/appStyles';
import { NameObject } from '../../types/item';
import { SortConfig, SortDir } from '../safe-utils';

type DisplayType = 'hidden' | 'normal' | 'selected';
type ObjectType = 'risk' | 'capability' | 'product';

const itemDisplay = (
  currentSelection: { id: string | number; type: string } | null,
  item: NameObject,
  contextType: ObjectType,
  highlighted: (number | string)[]
): DisplayType => {
  if (
    currentSelection?.type === contextType &&
    currentSelection?.id === item.name
  ) {
    return 'selected';
  }
  if (
    !currentSelection ||
    (contextType !== currentSelection.type &&
      (highlighted.includes(item.id as number) ||
        highlighted.includes(item.name as string)))
  ) {
    return 'normal';
  }
  return 'hidden';
};

const itemStyle = (display: DisplayType) =>
  `${appStyles.objectRow} transition-all duration-300 cursor-pointer ${
    display === 'selected' ? 'bg-sky text-white hover:bg-sky-darker' : ''
  } ${
    display === 'normal'
      ? 'bg-white text-gray-800 hover:bg-gray-300 hover:text-black'
      : ''
  } ${
    display === 'hidden' ? 'bg-gray-300 opacity-30 hover:opacity-60' : ''
  } cursor-pointer`;

const itemStatusCircleStyle =
  'font-semibold text-center rounded-full h-[28px] w-[28px] pt-[2px] text-white';

const changeSorting = (sortConfig: SortConfig, key: string) => {
  let dir = key === 'name' ? 1 : -1;
  if (key === sortConfig.key) {
    dir = -sortConfig.dir;
  }
  return { key, dir: dir as SortDir };
};

// limit report image output to 14 rows
// extra rows are hidden with this attribute
const reportPrintOmitRows = (n: number): { [key: string]: boolean } => {
  const attributes: { [key: string]: boolean } = {};
  if (n > 13) {
    attributes['data-html2canvas-ignore'] = true;
  }
  return attributes;
};

export {
  itemDisplay,
  DisplayType,
  itemStyle,
  itemStatusCircleStyle,
  changeSorting,
  reportPrintOmitRows,
};
