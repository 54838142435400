import Excel from 'exceljs';
import { saveAs } from 'file-saver';
import { Busneed } from '../types/busneed';
import { Framework } from '../types/framework';
import { Product } from '../types/product';
import { ItemSelections } from './selections.store';
import { BctSettings, CapabilitySettings } from './stores';

/**
 * Copied from Brennan's angular app
 * A few changes to fix local type errors etc
 * Data handling / sheet rendering might need to be changed? This hasn't been discussed yet
 */

interface Meta {
  title: string;
  header: string[];
  name: string;
  cell: string;
  widths: number[];
}

const dateString = (): string => {
  return new Date().toLocaleString();
};

const color = {
  light: 'dbdbdb',
  medium: 'c2c4c3',
  dark: '949494',
};

const cellBorder: Partial<Excel.Borders> = {
  top: { style: 'thin' },
  left: { style: 'thin' },
  bottom: { style: 'thin' },
  right: { style: 'thin' },
};

const cellFill = (fgCol: string): Excel.Fill => ({
  type: 'pattern',
  pattern: 'solid',
  fgColor: { argb: fgCol },
});

const generateExcel = (data: string[][], meta: Meta) => {
  // Create workbook and worksheet
  const workbook = new Excel.Workbook();
  const worksheet = workbook.addWorksheet('Mapping Data');
  // Add Row and formatting
  const titleRow = worksheet.addRow([meta.title]);
  titleRow.font = {
    name: 'Arial Hebrew',
    family: 4,
    size: 16,
    underline: 'double',
    bold: true,
  };
  worksheet.getCell('A1').fill = cellFill(color.light);
  worksheet.getCell('A1').alignment = { vertical: 'top', horizontal: 'left' };
  worksheet.addRow([]);
  worksheet.mergeCells('A1:' + meta.cell);
  // Add Header Row
  const headerRow = worksheet.addRow(meta.header);

  //  Cell Style : Fill and Border
  headerRow.eachCell((cell) => {
    cell.fill = cellFill(color.dark);
    cell.font = { bold: true };
    cell.border = cellBorder;
  });
  worksheet.autoFilter = {
    from: 'A3',
    to: meta.cell,
  };
  //  Add Data and Conditional Formatting
  let increment = 0;
  data.forEach((d: (string | unknown[])[]) => {
    if (d[0].length > 1) {
      increment++;
    }
    const row = worksheet.addRow(d);
    row.alignment = { wrapText: true };
    row.eachCell((cell) => {
      cell.fill = cellFill(increment % 2 === 1 ? color.medium : color.light);
      cell.border = cellBorder;
    });
  });
  meta.widths.forEach((width, i) => {
    worksheet.getColumn(i + 1).width = width;
  });
  worksheet.addRow([]);
  // Footer Row
  const footerRow = worksheet.addRow(['Report Generated: ' + dateString()]);
  footerRow.getCell(1).fill = cellFill(color.dark);
  footerRow.getCell(1).border = cellBorder;
  // Merge Cells
  worksheet.mergeCells(`A${footerRow.number}:F${footerRow.number}`);
  // Generate Excel File with given name
  workbook.xlsx.writeBuffer().then((data) => {
    const blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    saveAs(blob, 'MappingReport.xlsx');
  });
};

const generateProductExcel = (products: (Product | unknown)[]) => {
  const updateData: string[][] = [];
  products.filter(Boolean).forEach((product) => {
    const p = product as Product;
    let loops = 0;
    if (
      p.busneed.length >= p.framework.length &&
      p.busneed.length >= p.integrations.length
    ) {
      loops = p.busneed.length;
    } else if (
      p.framework.length >= p.busneed.length &&
      p.framework.length >= p.integrations.length
    ) {
      loops = p.framework.length;
    } else if (
      p.integrations.length >= p.framework.length &&
      p.integrations.length >= p.busneed.length
    ) {
      loops = p.integrations.length;
    }
    for (let i = 0; i <= loops; i++) {
      const tempUpdate = [];
      if (i === 0) {
        tempUpdate.push(p.name);
      } else {
        tempUpdate.push(' ');
      }
      try {
        tempUpdate.push(p.busneed[i].name);
      } catch (e) {
        tempUpdate.push(' ');
      }
      try {
        tempUpdate.push(
          p.framework[i].name,
          p.framework[i].framework.toUpperCase(),
          p.framework[i].coverage,
          p.framework[i].des
        );
      } catch (e) {
        tempUpdate.push(' ', ' ', ' ', ' ');
      }
      try {
        tempUpdate.push(
          p.integrations[i].name,
          p.integrations[i].integration,
          p.integrations[i].des
        );
      } catch (e) {
        tempUpdate.push(' ', ' ', ' ');
      }
      updateData.push(tempUpdate);
    }
  });
  const header = [
    'Product Name',
    'Business Need',
    'Sub-Control Name',
    'Framework Name',
    'Framework Coverage',
    'Framework Description',
    'Integrated Product',
    'Type',
    'Integration Description',
  ];
  const title = 'Cisco Product Analysis Report';
  const meta: Meta = {
    title,
    header,
    name: 'product',
    cell: 'I2',
    widths: [25, 25, 35, 20, 23, 35, 25, 25, 35],
  };
  generateExcel(updateData, meta);
};

const generateFrameworkExcel = (frameworks: (Framework | unknown)[]) => {
  const updateData: string[][] = [];
  frameworks.forEach((framework) => {
    const f = framework as Framework;
    let loops = 0;
    if (f.busneed.length >= f.product.length) {
      loops = f.busneed.length;
    } else if (f.product.length >= f.busneed.length) {
      loops = f.framework.length;
    }
    for (let i = 0; i <= loops; i++) {
      const tempUpdate = [];
      if (i === 0) {
        tempUpdate.push(f.name);
        tempUpdate.push(f.framework.toUpperCase());
        tempUpdate.push(f.control.toUpperCase());
      } else {
        tempUpdate.push(' ', ' ', ' ');
      }
      try {
        tempUpdate.push(
          f.product[i].name,
          f.product[i].coverage,
          f.product[i].des
        );
      } catch (e) {
        tempUpdate.push(' ', ' ', ' ');
      }
      try {
        tempUpdate.push(f.busneed[i].name);
      } catch (e) {
        tempUpdate.push(' ');
      }
      updateData.push(tempUpdate);
    }
  });
  const header: Array<string> = [
    'Sub-Control Name',
    'Framework Name',
    'Framework Control',
    'Covered Product',
    'Coverage',
    'Coverage Description',
    'Business Need',
  ];
  const title = 'Cisco Product Analysis Report';
  const meta: Meta = {
    header,
    title,
    name: 'framework',
    cell: 'G2',
    widths: [35, 20, 30, 35, 23, 35, 25],
  };
  generateExcel(updateData, meta);
};

const generateBusneedsExcel = (busneeds: (Busneed | unknown)[]) => {
  const updateData: string[][] = [];
  busneeds.forEach((busneed) => {
    const b = busneed as Busneed;
    let loops = 0;
    if (b.framework.length >= b.product.length) {
      loops = b.framework.length;
    } else if (b.product.length >= b.framework.length) {
      loops = b.framework.length;
    }
    for (let i = 0; i <= loops; i++) {
      const tempUpdate = [];
      if (i === 0) {
        tempUpdate.push(b.name);
      } else {
        tempUpdate.push(' ');
      }
      try {
        tempUpdate.push(
          b.product[i].name,
          b.product[i].coverage,
          b.product[i].des
        );
      } catch (e) {
        tempUpdate.push(' ', ' ', ' ');
      }
      try {
        tempUpdate.push(b.framework[i].name);
        tempUpdate.push(b.framework[i].framework.toUpperCase());
        tempUpdate.push(b.framework[i].control.toUpperCase());
      } catch (e) {
        tempUpdate.push(' ', ' ', ' ');
      }
      updateData.push(tempUpdate);
    }
  });
  const header: Array<string> = [
    'Business Need',
    'Covered Product',
    'Coverage',
    'Coverage Description',
    'Associated Sub-Control',
    'Framework',
    'Control',
  ];
  const title = 'Cisco Product Analysis Report';
  const meta: Meta = {
    header,
    title,
    name: 'framework',
    cell: 'G2',
    widths: [25, 25, 23, 35, 35, 20, 20],
  };
  generateExcel(updateData, meta);
};

export {
  generateProductExcel,
  generateFrameworkExcel,
  generateBusneedsExcel,
  generatePagedExcel,
};

// safe report sheets
const generatePagedExcel = (
  bctSelections: ItemSelections<BctSettings>,
  capabilitySelections: ItemSelections<CapabilitySettings>
) => {
  // Create workbook and worksheet
  const workbookData: any[] = [];
  let updateData: any[] = [];
  const sheetProducts: { name: string; score: number; description: string }[] =
    [];
  Object.values(capabilitySelections).forEach((cs: CapabilitySettings) => {
    (cs.products ?? [])
      .filter((p) => p.active)
      .forEach((p) =>
        sheetProducts.push({
          score: cs.score as number,
          name: p.name,
          description: '',
        })
      );
  });
  const sheetRisks: {
    [key: string]: { name: string; score: number; description: '' };
  } = Object.values(bctSelections)
    .filter((bctSel: BctSettings) => bctSel.active)
    .map((bct: BctSettings) => Object.values(bct.risks))
    .flat(1)
    .filter((r) => r.active)
    .reduce((a: any, c) => {
      const rName = c.name as string;
      if (rName) {
        a[rName] = a[rName] ?? { name: c.name, score: 0, description: '' };
        a[rName].score += (c.score === null ? 100 : c.score) as number;
      }
      return a;
    }, {});
  const sheetCapabilities: {
    name: string;
    score: number;
    description: string;
    coverage: string;
  }[] = Object.values(capabilitySelections).map((c: CapabilitySettings) => ({
    score: c.score as number,
    name: c.name as string,
    coverage: (c.status ?? 'None') as 'None' | 'Partial' | 'Planned' | 'Full',
    description: '',
  }));
  const title = 'Cisco SAFE Capability Report';
  let header = ['Capability', 'Score', 'Coverage', 'Coverage Description'];
  let meta = ['Capability', 'D2', 45, 35, 35, 90];
  sheetCapabilities.forEach(function (c) {
    updateData.push([c.name, c.score, c.coverage, c.description]);
  });
  workbookData.push([header, updateData, meta]);
  updateData = [];
  header = ['Product', 'Priority', 'Description'];
  meta = ['Product', 'C2', 45, 35, 125];
  sheetProducts.forEach(function (p) {
    updateData.push([p.name, p.score, p.description]);
  });
  workbookData.push([header, updateData, meta]);
  updateData = [];
  header = ['Risk', 'Priority', 'Description'];
  meta = ['Risk', 'C2', 45, 35, 125];
  Object.values(sheetRisks).forEach(function (r) {
    updateData.push([r.name, r.score, r.description]);
  });
  workbookData.push([header, updateData, meta]);

  const workbook = new Excel.Workbook();
  workbookData.forEach(function (hdm: any) {
    const header = hdm[0];
    const data = hdm[1];
    const meta = hdm[2];
    const worksheet = workbook.addWorksheet(meta[0]);
    // Add Row and formatting
    const titleRow = worksheet.addRow([title]);
    titleRow.font = {
      name: 'Arial Hebrew',
      family: 4,
      size: 16,
      underline: 'double',
      bold: true,
    };
    worksheet.getCell('A1').fill = cellFill(color.light);
    worksheet.getCell('A1').alignment = { vertical: 'top', horizontal: 'left' };
    worksheet.addRow([]);
    worksheet.mergeCells('A1:' + meta[1]);
    // Add Header Row
    const headerRow = worksheet.addRow(header);
    //  Cell Style : Fill and Border
    headerRow.eachCell((cell: any) => {
      cell.fill = cellFill(color.dark);
      cell.font = { bold: true };
      cell.border = cellBorder;
    });
    worksheet.autoFilter = {
      from: 'A3',
      to: meta[1],
    };
    //  worksheet.addRows(data);
    //  Add Data and Conditional Formatting
    let increment = 0;
    data.forEach((d: any[]) => {
      if (d[0].length > 1) {
        increment++;
      }
      const row = worksheet.addRow(d);
      row.alignment = { wrapText: true };
      row.eachCell((cell: any) => {
        if (increment % 2 === 1) {
          cell.fill = cellFill(color.medium);
        } else {
          cell.fill = cellFill(color.light);
        }
        cell.border = cellBorder;
      });
    });
    worksheet.getColumn(1).width = meta[2];
    worksheet.getColumn(2).width = meta[3];
    worksheet.getColumn(3).width = meta[4];
    if (meta[0] === 'Capability') {
      worksheet.getColumn(4).width = meta[5];
      worksheet.getColumn(5).width = meta[6];
    }
    worksheet.addRow([]);
    // Footer Row
    const footerRow = worksheet.addRow([
      'SAFE Report Auto-Generated: ' + dateString(),
    ]);
    footerRow.getCell(1).fill = cellFill(color.dark);
    footerRow.getCell(1).border = cellBorder;
    // Merge Cells
    worksheet.mergeCells(`A${footerRow.number}:C${footerRow.number}`);
    // Generate Excel File with given name
  });
  workbook.xlsx.writeBuffer().then((data: any) => {
    const blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    saveAs(blob, 'SAFEReport.xlsx');
  });
};
