import React, { useEffect, useState } from 'react';
import AsyncContainer from '../complib/AsyncContainer';
import { SortConfig } from '../safe/safe-utils';
import TableSortHeading from '../safe/TableSortHeading';
import { useQueryItems } from '../support/queries';
import { Pin } from '../types/pin';
import AdminPinForm from './AdminPinForm';
import {
  compareItems,
  defaultSortConfig,
  highlitContent,
  updateSort,
} from './helpers';
import AdminListPage from './page-elements/AdminListPage';
import NoneShowing from './page-elements/NoneShowing';

const styles = {
  colOrder: 'flex-0 w-10 mr-2',
  colName: 'flex-1 mr-2 min-w-[16rem] my-2',
  colUpdate: 'flex-0 w-56 flex items-center',
};

const AdminPinPage = () => {
  const data = useQueryItems('pin', true);

  const [filtered, setFiltered] = useState<Pin[]>();
  const [sortConfig, setSortConfig] = useState<SortConfig>(defaultSortConfig);
  const [openForm, setOpenForm] = useState<boolean>(false);
  const [selected, setSelected] = useState<Pin>();
  const [search, setSearch] = useState('');

  const closeForm = () => {
    setSelected(undefined);
    setOpenForm(false);
  };

  useEffect(() => {
    setFiltered(
      (data ?? []).filter((p: Pin) =>
        p.name.toLocaleLowerCase().includes(search?.trim().toLocaleLowerCase())
      )
    );
  }, [data, setFiltered, search]);

  const changeSort = (key: string) =>
    setSortConfig(updateSort(sortConfig, key));

  const comparePins = (a: Pin, b: Pin): number =>
    compareItems(a, b, sortConfig);

  const select = (p?: Pin): void => {
    setSelected(p);
    setOpenForm(true);
  };

  return (
    <>
      <AdminListPage
        search={search}
        updateSearch={setSearch}
        addAction={select}
        header={
          <>
            {!!filtered?.length && (
              <div className='flex text-left text-sm mb-2'>
                <>
                  <TableSortHeading
                    updateSort={changeSort}
                    sort={sortConfig}
                    sortKey='name'
                    className={styles.colName}
                  >
                    PIN
                  </TableSortHeading>
                  <TableSortHeading
                    updateSort={changeSort}
                    sort={sortConfig}
                    sortKey='order'
                    className={styles.colOrder}
                  >
                    Order
                  </TableSortHeading>
                  <div className={styles.colUpdate}>Updated</div>
                </>
              </div>
            )}
          </>
        }
        itemLabel='PIN'
        list={
          <>
            <AsyncContainer ready={!!data} className='m-4'>
              {!!data &&
                filtered?.sort(comparePins).map((p: Pin) => (
                  <div
                    key={p.id}
                    className={`flex items-center border-gray-300 border-t px-4 text-sm`}
                  >
                    <h3
                      className={`${styles.colName} font-semibold hover:underline cursor-pointer`}
                      onClick={() => select(p)}
                    >
                      {highlitContent(p.name, search)}
                    </h3>
                    <div className={styles.colOrder}>{p.order}</div>
                    <div className={styles.colUpdate}>{p.update ?? '-'}</div>
                  </div>
                ))}
              <NoneShowing show={!filtered?.length} />
            </AsyncContainer>
          </>
        }
      />
      {openForm && (
        <AdminPinForm pin={selected} isOpen={openForm} close={closeForm} />
      )}
    </>
  );
};

export default AdminPinPage;
