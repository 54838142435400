import React from 'react';
import HuiSelect, { Option } from '../../complib/HuiSelect';
import Loader from '../../complib/Loader';
import { useQueryItems } from '../../support/queries';
import { useSafeSettingsSelections } from '../../support/stores';
import { Product } from '../../types/product';
import { SafeProps } from '../safe-utils';
// import { safeArchitectures } from './chart-constants';

type CapabilitySelectionType = 'score' | 'product' | 'architecture';
interface CapabilitySelection extends Option {
  type: CapabilitySelectionType;
  name: string;
}
const defaultMode: CapabilitySelection = {
  type: 'score',
  name: 'All',
};

const architectDefaultMode: CapabilitySelection = {
  type: 'score',
  name: 'All Capabilities',
};

const CapabilityModeSelect = ({ safeMode = 'risk' }: SafeProps) => {
  const products = useQueryItems('product');
  const architectures = useQueryItems('architecture');
  const correctDefault =
    safeMode === 'risk' ? defaultMode : architectDefaultMode;
  const { getSetting: getSafeSetting, setSetting: setSafeSetting } =
    useSafeSettingsSelections();
  const capabilitySelections = () => [
    defaultMode,
    { name: '----' },
    ...Object.keys(architectures ?? []).map(
      (key) =>
        ({
          name: architectures[key].name,
          type: 'architecture',
        } as CapabilitySelection)
    ),
    { name: '----' },
    ...(products ?? []).map((product: Product) => ({
      name: product.name,
      type: 'product',
    })),
  ];
  const setMode = (cs: CapabilitySelection) => {
    setSafeSetting('capabilityModeName', cs.name);
    setSafeSetting('capabilityModeType', cs.type);
  };
  const getMode = (): CapabilitySelection => ({
    name: getSafeSetting('capabilityModeName', correctDefault.name) as string,
    type: getSafeSetting(
      'capabilityModeType',
      defaultMode.type
    ) as CapabilitySelectionType,
  });

  return (
    <>
      {(!architectures || !products) && <Loader />}
      {!!architectures && !!products && (
        <HuiSelect
          value={getMode()}
          defaultValue={correctDefault}
          options={capabilitySelections()}
          displayKey='name'
          selection={setMode}
          maxHeightCss='max-h-96'
        />
      )}
    </>
  );
};

export default CapabilityModeSelect;
