import React from 'react';
import appStyles from '../support/appStyles';

interface Props {
  className?: string;
  value?: string;
  changeEvent: (val: string) => void;
  placeholder?: string;
}

function SearchInput({ className, value, changeEvent, placeholder }: Props) {
  return (
    <input
      className={`${className} ${appStyles.input}`}
      value={value}
      onChange={(e) => changeEvent(e.target.value)}
      placeholder={placeholder}
    />
  );
}
export default SearchInput;
