import React, { ReactNode } from 'react';

interface Props {
  title?: string;
  action?: () => void;
  selected?: boolean;
  children?: ReactNode;
  disabled?: boolean;
}

const Tab = ({
  title = '',
  action,
  children,
  selected,
  disabled = false,
}: Props) => (
  <li
    key={title}
    onClick={action}
    className={`font-medium border-b-4 border-sky ${
      selected ? 'border-sky text-black' : 'border-transparent text-gray-700'
    } cursor-pointer hover:border-sky-lighter ${children ? '' : 'p-2'} ${
      disabled ? 'cursor-default hover:border-transparent text-gray-400' : ''
    }`}
  >
    {children ?? title}
  </li>
);

export default Tab;
