import React, { useEffect, useState } from 'react';
import AsyncContainer from '../complib/AsyncContainer';
import { SortConfig } from '../safe/safe-utils';
import TableSortHeading from '../safe/TableSortHeading';
import { useQueryItems } from '../support/queries';
import AdminFrameworkForm from './AdminFrameworkForm';
import Framework from './api-types/framework';
import {
  compareItems,
  defaultSortConfig,
  highlitContent,
  updateSort,
} from './helpers';
import AdminListPage from './page-elements/AdminListPage';
import NoneShowing from './page-elements/NoneShowing';

const itemLabel = 'framework';

const styles = {
  colDesc: 'flex-1 w-52 mr-2',
  colName: 'flex-0 mr-2 min-w-[16rem] my-2',
  colUpdate: 'flex-0 w-56 flex items-center',
  colControls: 'flex-0 w-24 flex items-center',
};

const AdminFrameworkPage = () => {
  const [search, setSearch] = useState('');
  const [filtered, setFiltered] = useState([]);
  const itemData = useQueryItems(itemLabel, true);
  const [sortConfig, setSortConfig] = useState<SortConfig>(defaultSortConfig);
  const [openForm, setOpenForm] = useState<boolean>(false);
  const [selected, setSelected] = useState<Framework>();

  const closeForm = () => {
    setSelected(undefined);
    setOpenForm(false);
  };

  useEffect(() => {
    setFiltered(
      (itemData ?? []).filter((a: Framework) =>
        a.name.toLocaleLowerCase().includes(search?.trim().toLocaleLowerCase())
      )
    );
  }, [itemData, setFiltered, search]);

  const changeSort = (key: string) =>
    setSortConfig(updateSort(sortConfig, key));

  const compare = (a: Framework, b: Framework): number =>
    compareItems(a, b, sortConfig);

  const select = (f?: Framework): void => {
    setSelected(f);
    setOpenForm(true);
  };

  const cellContent = (content = '') => highlitContent(content, search);

  return (
    <>
      <AdminListPage
        itemLabel={itemLabel}
        updateSearch={setSearch}
        search={search}
        addAction={select}
        header={
          <>
            {!!filtered?.length && (
              <div className='flex text-left text-sm mb-2'>
                <>
                  <TableSortHeading
                    updateSort={changeSort}
                    sort={sortConfig}
                    sortKey='name'
                    className={styles.colName}
                  >
                    Framework
                  </TableSortHeading>
                  <TableSortHeading
                    updateSort={changeSort}
                    sort={sortConfig}
                    sortKey='description'
                    className={styles.colDesc}
                  >
                    Description
                  </TableSortHeading>
                  <div className={styles.colControls}>Controls</div>
                  <div className={styles.colUpdate}>Updated</div>
                </>
              </div>
            )}
          </>
        }
        list={
          <>
            <AsyncContainer ready={itemData} className='m-4'>
              {filtered?.sort(compare).map((item: Framework) => (
                <div
                  key={item.id}
                  className={`flex items-center border-gray-300 border-t px-4 text-sm`}
                >
                  <h3
                    className={`${styles.colName} font-semibold hover:underline cursor-pointer`}
                    onClick={() => select(item)}
                  >
                    {cellContent(item.name)}
                  </h3>
                  <div className={styles.colDesc}>
                    {cellContent(item.description)}
                  </div>
                  <div className={styles.colControls}>
                    <div className='bg-sec-dark-gray min-w-8 text-center text-white rounded-full font-bold px-2 py-[2px] text-lg my-1'>
                      {item.controls?.reduce(
                        (a, c) => a + c.subcontrols.length + 1,
                        0
                      )}
                    </div>
                  </div>
                  <div className={styles.colUpdate}>{item.updated ?? '-'}</div>
                </div>
              ))}
              <NoneShowing show={!filtered?.length} />
            </AsyncContainer>
          </>
        }
      />
      {openForm && (
        <AdminFrameworkForm
          item={selected}
          isOpen={openForm}
          close={closeForm}
        />
      )}
    </>
  );
};

export default AdminFrameworkPage;
