import React, { ReactNode } from 'react';

const styles = {
  button: [
    'rounded-full',
    'p-5',
    'py-1',
    'border',
    'font-light',
    'transition',
    'disabled:opacity-40',
    'mr-2',
  ].join(' '),
  primary: [
    'bg-sky',
    'active:bg-sky-darker',
    'hover:bg-sky-lighter',
    'border-sky',
    'focus:shadow-[0_0_0_3px_rgba(0,188,235,0.25)]',
  ].join(' '),
  secondary: [
    'text-white',
    'bg-ocean',
    'active:bg-ocean-darker',
    'hover:bg-ocean-lighter',
    'border-ocean',
    'focus:shadow-[0_0_0_3px_rgba(30,68,113,0.25)]',
  ].join(' '),
  success: [
    'bg-success',
    'active:bg-success-darker',
    'hover:bg-success-lighter',
    'border-success',
    'focus:shadow-[0_0_0_3px_rgba(106,191,75,0.25)]',
  ].join(' '),
  danger: [
    'text-white',
    'bg-danger',
    'active:bg-danger-darker',
    'hover:bg-danger-lighter',
    'border-danger',
    'focus:shadow-[0_0_0_3px_rgba(226,35,26,0.25)]',
  ].join(' '),
  dark: [
    'text-white',
    'bg-dark',
    'active:bg-dark-darker',
    'hover:bg-dark-lighter',
    'border-dark',
    'focus:shadow-[0_0_0_3px_rgba(73,80,87,0.25)]',
  ].join(' '),
  ghost: [
    'hover:text-white',
    'bg-transparent',
    'active:bg-dark-darker',
    'hover:bg-dark-lighter',
    'border-dark',
    'focus:shadow-[0_0_0_3px_rgba(73,80,87,0.25)]',
  ].join(' '),
};

interface Props {
  className?: string;
  children: ReactNode;
  type?: 'button' | 'submit';
  onClick?: React.MouseEventHandler;
  theme?: 'primary' | 'secondary' | 'success' | 'danger' | 'dark' | 'ghost';
  title?: string;
}

const Button = ({
  className = '',
  children,
  type = 'button',
  onClick,
  theme = 'primary',
  title,
}: Props) => {
  return (
    <button
      type={type}
      className={`${styles.button} ${styles[theme]} ${className ?? 'text-sm'}`}
      onClick={onClick}
      title={title ?? undefined}
    >
      {children}
    </button>
  );
};
export default Button;
