import React from 'react';
import { capabilityIconsS3 } from '../../safe/SafeFlowChart/capability-icons';

interface Props {
  icon: string;
  scale?: number;
}

const CapabilityIcon = ({ icon, scale = 0.33 }: Props) => {
  const isValid = (): boolean => capabilityIconsS3.includes(icon);

  const style = () => ({
    backgroundImage: isValid()
      ? `url(${process.env.PUBLIC_URL}/assets/images/capabilityss.png)`
      : 'none',
  });

  return (
    <div
      className='origin-top-left'
      style={{
        transform: `scale(${scale})`,
        width: `${132 * scale}px`,
        height: `${132 * scale}px`,
      }}
    >
      <div
        style={style()}
        className={`flex-none w-[132px] h-[132px] block bg-no-repeat cap-sheet--Capability_43_${icon.replace(
          / /g,
          '-'
        )} bg-sec-light-gray rounded-full`}
      ></div>
    </div>
  );
};

export default CapabilityIcon;
