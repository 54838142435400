import React, { ReactNode } from 'react';

interface Props {
  children: ReactNode;
  className?: string;
}

const Steps = ({ children, className }: Props) => {
  return (
    <section className={`flex justify-center ${className}`}>{children}</section>
  );
};

export default Steps;
