import React, { useEffect } from 'react';
import { Controller, DefaultValues, useForm } from 'react-hook-form';
import { BCTRiskSeverity } from '../safe/RiskScoreSelector';
import appStyles from '../support/appStyles';
import { useQueryItems } from '../support/queries';
import { Bustech } from '../types/bustech';
import { Risk } from '../types/risk';
import AdminObjectForm from './AdminObjectForm';
import { verticals } from './constants';
import BustechCategorySelector, {
  SelectedCategories,
} from './page-elements/BustechCategorySelector';
import BustechRiskSelector, {
  BCTRiskConfig,
  BCTRisks,
} from './page-elements/BustechRiskSelector';

interface BCTValues {
  bustechid: number;
  id: number;
  name: string;
  bustechdes: string;
  risk: BCTRisks;
  categories: SelectedCategories;
}

interface Props {
  bct?: Bustech;
  close: () => void;
  isOpen: boolean;
}

const displayCategory = 'Business Critical Technology';
const endpoint = 'bustech';

const AdminBCTForm = ({ isOpen, close, bct }: Props) => {
  const riskData = useQueryItems('risk');

  const formConfig = useForm<BCTValues>();

  const isNew = () => (bct?.bustechid ? false : true);
  const { register, reset, control, getValues } = formConfig;

  const convertToApi = (data: BCTValues): Bustech => {
    const apiData: Bustech = {
      ...bct,
      id: bct?.bustechid,
      bustechdes: data.bustechdes,
      name: data.name,
      risk: Object.values(data.risk)
        .filter((risk: BCTRiskConfig) => risk.selected)
        .map(({ selected, ...save }: BCTRiskConfig) => save),
      assets: data.categories.assets ? 1 : 0,
      commercial: data.categories.commercial ? 1 : 0,
      education: data.categories.education ? 1 : 0,
      enterprise: data.categories.enterprise ? 1 : 0,
      federalgov: data.categories.federalgov ? 1 : 0,
      financial: data.categories.financial ? 1 : 0,
      healthcare: data.categories.healthcare ? 1 : 0,
      individuals: data.categories.individuals ? 1 : 0,
      localgov: data.categories.localgov ? 1 : 0,
      manufacturing: data.categories.manufacturing ? 1 : 0,
      nation: data.categories.nation ? 1 : 0,
      operations: data.categories.operations ? 1 : 0,
      thirdparty: data.categories.thirdparty ? 1 : 0,
      update: data.categories.update ? 1 : 0,
      utilities: data.categories.utilities ? 1 : 0,
    };
    return apiData;
  };

  useEffect(() => {
    const createDefaultCategories = (): SelectedCategories => {
      const selectedCats: SelectedCategories = {};
      verticals.forEach(
        (cat) => (selectedCats[cat] = bct && bct[cat] === 1 ? true : false)
      );
      return selectedCats;
    };
    const createDefaultRisks = (): BCTRisks => {
      const bRisks: BCTRisks = {};
      riskData?.forEach((risk: Risk) => {
        const bctRisk = bct?.risk?.find((bctr) => bctr.name === risk.name);
        bRisks[risk.id] = {
          id: risk.id,
          name: risk.name,
          severity: (bctRisk?.severity ?? 50) as BCTRiskSeverity,
          selected: bctRisk ? true : false,
        };
      });
      return bRisks;
    };
    const createDefaultValues = (bct?: Bustech): DefaultValues<BCTValues> => ({
      bustechid: bct?.bustechid ?? undefined,
      id: bct?.bustechid ?? undefined,
      name: bct?.name ?? undefined,
      bustechdes: bct?.bustechdes ?? undefined,
      categories: createDefaultCategories(),
      risk: createDefaultRisks(),
    });
    reset(createDefaultValues(bct));
  }, [reset, isOpen, bct, riskData]);

  return (
    <AdminObjectForm
      close={close}
      isOpen={isOpen}
      endpoint={endpoint}
      convertToApi={convertToApi}
      data={bct}
      displayCategory={displayCategory}
      formConfig={formConfig}
      isNew={() => isNew()}
    >
      <div className='flex-col flex mt-4'>
        <label className='text-sm'>Name</label>
        <input
          className={`${appStyles.input} text-lg py-1`}
          defaultValue={bct ? getValues('name') : ''}
          {...register('name', { required: true })}
        />
      </div>
      <div className='flex-col flex mt-4'>
        <label className='text-sm'>Description</label>
        <textarea
          className={`${appStyles.input} text-lg py-1`}
          defaultValue={bct ? getValues('bustechdes') : ''}
          {...register('bustechdes')}
        />
      </div>
      <div className='flex-col flex mt-4'>
        <label className='text-sm'>Verticals</label>
        <Controller
          control={control}
          name='categories'
          defaultValue={bct ? getValues('categories') : {}}
          render={({ field }) => (
            <BustechCategorySelector
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
      </div>
      <div className='flex-col flex mt-4'>
        <label className='text-sm'>Threats</label>
        <Controller
          control={control}
          name='risk'
          defaultValue={getValues('risk') as BCTRisks}
          render={({ field }) => (
            <BustechRiskSelector
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
      </div>
    </AdminObjectForm>
  );
};

export default AdminBCTForm;
