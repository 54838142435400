import React, { useEffect, useState } from 'react';
import { Plus } from 'react-feather';
import Button from '../complib/Button';
import Card from '../complib/Card';
import Loader from '../complib/Loader';
import NumberInputRange from '../complib/NumberInputRange';
import { getCapabilityArbitraryPriority } from '../framework-product-tool/hardcodedFutureProblems';
import CapabilityHelp from '../help/CapabilityHelp';
import HelpSection from '../help/HelpSection';
import AdminCapabilityForm from '../manage-data/AdminCapabilityForm';
import appStyles from '../support/appStyles';
import { useQueryItems } from '../support/queries';
import { ItemSelections } from '../support/selections.store';
import {
  CapabilityProduct,
  CapabilitySettings,
  useBctRiskSelections,
  useCapabilityGroupsSettings,
  useCapabilitySelections,
  useCustomCapabilitySelections,
  useMaturitySelections,
  useSafeSettingsSelections,
  useSecurityFunctionSelections,
} from '../support/stores';
import { sanitisedNumber } from '../support/utils';
import { Capability } from '../types/capability';
import CapabilityProductSelector from './CapabilityProductSelector';
import CapabilityStandardFilters from './CapabilityStandardFilters';
import {
  addCustomCapability,
  capabilityInStandardFilters,
  groupedCapabilities,
  SafeProps,
  scoreOpacity,
  SortConfig,
  SortDir,
  statusSettings,
} from './safe-utils';
import { heatColor } from './SafeFlowChart/chart-constants';
import StatusFilter from './StatusFilter';
import TableSortHeading from './TableSortHeading';

const CapabilityPage = ({ safeMode = 'risk' }: SafeProps) => {
  const capabilityData = useQueryItems('capability');
  const [openForm, setOpenForm] = useState<boolean>(false);

  const {
    getItemSetting: getCapabilitySetting,
    setItemSetting: setCapabilitySetting,
    batchSetSettings: batchSetCapabilities,
  } = useCapabilitySelections();
  const { setSetting: setGroupScore, settings: groupSettings } =
    useCapabilityGroupsSettings();
  const {
    getSetting: getSafeSetting,
    setSetting: setSafeSetting,
    settings: safeSettings,
  } = useSafeSettingsSelections();
  const { selections: customCapabilitySelections } =
    useCustomCapabilitySelections();
  const { selections: maturitySelections } = useMaturitySelections();
  const { selections: secFunSelections } = useSecurityFunctionSelections();
  const { isInitialized } = useBctRiskSelections();

  const [allCapabilities, setAllCapabilities] = useState<{
    [key: string]: Capability[];
  }>();
  const [status, setStatus] = useState(statusSettings);
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    key: 'score',
    dir: -1,
  });

  // set grouped capability data
  useEffect(() => {
    if (!capabilityData?.length) {
      return;
    }
    const grouped = groupedCapabilities([
      ...capabilityData,
      ...Object.values(customCapabilitySelections),
    ]);
    setAllCapabilities(grouped);
  }, [capabilityData, safeSettings, customCapabilitySelections]);

  // set capability selections
  useEffect(() => {
    if (!allCapabilities || (safeMode === 'risk' && !isInitialized())) {
      return;
    }
    if (Object.keys(groupSettings).length) {
      // add new, missing capabilities
      // might be required when capability data is edited by admins
      // between 2 sessions with a saved workshop
      Object.values(allCapabilities).forEach((group) => {
        Object.values(group).forEach((item: Capability) => {
          const id = item.id as number;
          const name: string = getCapabilitySetting(id, 'name', null) as string;
          if (!name) {
            setCapabilitySetting(id, 'name', item.name);
            setCapabilitySetting(id, 'id', item.id);
            setCapabilitySetting(id, 'status', 'None');
            setCapabilitySetting(id, 'active', true);
            setCapabilitySetting(id, 'score', 0);
            setCapabilitySetting(id, 'image', item.image);
            setCapabilitySetting(
              id,
              'products',
              (item.product ?? []).map((p: { name: string }) => ({
                name: p.name,
                active: false,
                type: 'cisco',
              }))
            );
          }
        });
      });
      return;
    }
    Object.keys(allCapabilities).forEach((id: string) => setGroupScore(id, 0));
    const items: ItemSelections<CapabilitySettings> = {};
    Object.values(allCapabilities).forEach((group) => {
      Object.values(group).forEach((item: Capability) => {
        items[item.id as number] = {
          id: item.id as number,
          name: item.name,
          active: true,
          status: 'None',
          products: (item.product ?? []).map((p: { name: string }) => ({
            name: p.name,
            active: false,
            type: 'cisco',
          })),
        };
      });
    });
    batchSetCapabilities(items);
  }, [
    allCapabilities,
    batchSetCapabilities,
    groupSettings,
    setGroupScore,
    isInitialized,
    safeMode,
    setCapabilitySetting,
    getCapabilitySetting,
    maturitySelections,
    secFunSelections,
  ]);

  const showCapability = (cap: Capability) => {
    return (
      capabilityInStandardFilters(cap) &&
      status.find(
        (s) =>
          s.name === getCapabilitySetting(cap.id as number, 'status', 'None')
      )?.selected &&
      (safeMode === 'architect' ||
        getContextScore(cap) >=
          (getSafeSetting('minCapabilityScore', 0) as number))
    );
  };

  const toggleStatus = (id: string) => {
    const temp = [...status];
    const state = temp.find((s) => s.name === id);
    if (state) {
      state.selected = !state?.selected;
      setStatus(temp);
    }
  };

  const compareCapabilities = (a: Capability, b: Capability): number => {
    let aComp = getCapabilitySetting(a.id as number, sortConfig.key) as
      | number
      | string
      | CapabilityProduct[];
    let bComp = getCapabilitySetting(b.id as number, sortConfig.key) as
      | number
      | string
      | CapabilityProduct[];
    if (safeMode === 'architect' && sortConfig.key === 'score') {
      aComp = getCapabilityArbitraryPriority(a.name, a.domain) ?? 100;
      bComp = getCapabilityArbitraryPriority(b.name, b.domain) ?? 100;
    }
    if (sortConfig.key === 'status') {
      aComp = status.find((s) => s.name === aComp)?.sort ?? 0;
      bComp = status.find((s) => s.name === bComp)?.sort ?? 0;
    }
    if (sortConfig.key === 'products' || sortConfig.key === 'proposed') {
      const aProducts = (aComp ?? []) as CapabilityProduct[];
      const bProducts = (bComp ?? []) as CapabilityProduct[];
      aComp = aProducts.reduce((acc, c) => (c.active ? acc + 1e3 : acc + 1), 0);
      bComp = bProducts.reduce((acc, c) => (c.active ? acc + 1e3 : acc + 1), 0);
    }
    if (aComp === bComp) {
      return a.name > b.name ? 1 : -1;
    }
    return aComp > bComp ? sortConfig.dir : -sortConfig.dir;
  };

  const updateSort = (key: string) => {
    let dir = key === 'name' ? 1 : -1;
    if (key === sortConfig.key) {
      dir = -sortConfig.dir;
    }
    setSortConfig({ key, dir: dir as SortDir });
  };

  const heatBg = (cap: Capability): string =>
    heatColor(getContextScore(cap) / getScoreMax(), scoreOpacity);

  const getMinStep = (): number => {
    const max = getScoreMax();
    return max > 1e3 ? 1e2 : max > 1e2 ? 10 : 1;
  };

  const getContextScore = (cap: Capability): number => {
    if (safeMode === 'risk') {
      return getCapabilitySetting(cap.id as number, 'score') as number;
    }
    return getCapabilityArbitraryPriority(cap.name, cap.domain);
  };
  const getScoreMax = () => {
    if (safeMode === 'risk') {
      return getSafeSetting('capabilityMaxScore') as number;
    }
    return 58;
  };
  const getScoreMin = () => {
    if (safeMode === 'architect') {
      return 0;
    }
    return getSafeSetting('minCapabilityScore', 0) as number;
  };

  const needsProduct = (cap: Capability) => {
    const status = getCapabilitySetting(cap.id as number, 'status', 'None');
    let products: CapabilityProduct[];
    switch (status) {
      case 'Partial':
      case 'Full':
        products = getCapabilitySetting(
          cap.id as number,
          'products',
          []
        ) as CapabilityProduct[];
        return products.every((p) => p.active === false);
      case 'Planned':
        products = getCapabilitySetting(
          cap.id as number,
          'proposed',
          []
        ) as CapabilityProduct[];
        return products.every((p) => p.active === false);
      case 'Hide':
      case 'None':
      default:
        return false;
    }
  };

  return (
    <>
      <section className='grid grid-cols-[minmax(200px,1fr)_6fr] gap-8'>
        <section>
          <CapabilityStandardFilters />
          <h2 className={appStyles.h2}>Coverage</h2>
          <StatusFilter toggleStatus={toggleStatus} status={status} />
          {safeMode === 'risk' && (
            <>
              <h2 className={appStyles.h2}>Minimum Priority</h2>
              <NumberInputRange
                max={getScoreMax()}
                step={getMinStep()}
                value={getScoreMin()}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setSafeSetting(
                    'minCapabilityScore',
                    sanitisedNumber(e.target.value, 0, getScoreMax())
                  )
                }
              />
            </>
          )}
          <Button
            className='mt-4 w-max flex'
            theme='primary'
            onClick={() => setOpenForm(true)}
          >
            <Plus />
            <span className='mx-2'>Custom capability</span>
          </Button>
          <Card
            className={`transition-opacity ${
              Object.values(allCapabilities ?? {}).some((caps) =>
                caps.some((c) => needsProduct(c))
              )
                ? 'opacity-100'
                : 'opacity-0'
            } mt-6 text-sec-dark-gray`}
          >
            <span className='text-sec-red font-semibold text-2xl'>*</span> :
            products required for capability selected coverage.
          </Card>
        </section>
        <section className='mt-6'>
          {!allCapabilities && <Loader className='w-16 h-16' />}
          {allCapabilities &&
            groupSettings &&
            // productData &&
            Object.keys(allCapabilities)
              .sort((a, b) => (a < b ? -1 : 1))
              .filter((group: string) =>
                allCapabilities[group].some((cap) => showCapability(cap))
              )
              .map((group: string, i: number) => (
                <Card
                  key={group}
                  className={`mb-6 border-l-[10px] px-0 pb-3 pt-1`}
                  style={{
                    borderColor: `rgb(${140 - 15 * i},200,${120 + 20 * i})`,
                  }}
                >
                  <header className='pl-6 pr-4'>
                    <h2 className={`pb-2 ${appStyles.h2}`}>{group}</h2>
                    <div className='flex text-left text-sm mb-2'>
                      {safeMode === 'risk' && (
                        <>
                          <TableSortHeading
                            updateSort={updateSort}
                            sort={sortConfig}
                            sortKey='score'
                            className='w-16 mr-2'
                          >
                            Priority
                          </TableSortHeading>
                          <TableSortHeading
                            updateSort={updateSort}
                            sort={sortConfig}
                            sortKey='name'
                            className='flex-1 mr-2'
                          >
                            Capability
                          </TableSortHeading>
                          <TableSortHeading
                            updateSort={updateSort}
                            sort={sortConfig}
                            className='mr-2 w-72 flex-0'
                            sortKey='status'
                          >
                            Coverage
                          </TableSortHeading>
                          <TableSortHeading
                            updateSort={updateSort}
                            sort={sortConfig}
                            className='w-72 flex-0 mr-2'
                            sortKey='products'
                          >
                            Current Products
                          </TableSortHeading>
                          <TableSortHeading
                            updateSort={updateSort}
                            sort={sortConfig}
                            className='w-72 flex-0'
                            sortKey='proposed'
                          >
                            Proposed Products
                          </TableSortHeading>
                        </>
                      )}
                      {safeMode === 'architect' && (
                        <>
                          <div className='flex-1 font-semibold mr-2'>
                            Capability
                          </div>
                          <div className='w-72 font-semibold mr-2'>
                            Coverage
                          </div>
                          <div className='w-72 font-semibold flex-0 mr-2'>
                            Current Products
                          </div>
                          <div className='w-72 font-semibold flex-0'>
                            Proposed Products
                          </div>
                        </>
                      )}
                    </div>
                  </header>
                  {allCapabilities[group]
                    .filter((cap: Capability) => showCapability(cap))
                    .sort(compareCapabilities)
                    .map((cap: Capability) => (
                      <div
                        key={cap.id}
                        className={`flex items-center border-gray-300 border-t min min-h-full px-4`}
                      >
                        {safeMode === 'risk' && (
                          <div
                            className={appStyles.numberColorBox}
                            style={{
                              backgroundColor: heatBg(cap),
                            }}
                          >
                            {getContextScore(cap)}
                          </div>
                        )}
                        <h3 className='font-cisco text-s flex-1 m-2 relative'>
                          {cap.name}
                          {needsProduct(cap) && (
                            <span className='text-sec-red font-semibold text-3xl font-sans absolute -bottom-1'>
                              &nbsp;*
                            </span>
                          )}
                        </h3>
                        <div className='flex-0 flex mr-2 w-72'>
                          {status.map((s) => (
                            <div
                              className={`${appStyles.statusSwitch} ${
                                getCapabilitySetting(
                                  cap.id as number,
                                  'status',
                                  'None'
                                ) === s.name
                                  ? s.color
                                  : 'bg-gray-400'
                              }`}
                              key={s.name}
                              onClick={() =>
                                setCapabilitySetting(
                                  cap.id as number,
                                  'status',
                                  s.name
                                )
                              }
                            >
                              {s.name}
                            </div>
                          ))}
                        </div>
                        <CapabilityProductSelector
                          id={cap.id as number}
                          prop='products'
                          className='w-72 flex-0 mr-2'
                        />
                        <CapabilityProductSelector
                          id={cap.id as number}
                          prop='proposed'
                          className='w-72 flex-0'
                        />
                      </div>
                    ))}
                </Card>
              ))}
        </section>
      </section>
      <HelpSection>
        <CapabilityHelp safeMode={safeMode} />
      </HelpSection>
      <AdminCapabilityForm
        close={() => setOpenForm(false)}
        isOpen={openForm}
        customSubmit={(data) => addCustomCapability(data)}
      />
    </>
  );
};

export default CapabilityPage;
