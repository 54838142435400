import { CapabilityProduct, CapabilitySettings } from '../../support/stores';
// import { getCapabilityImage } from '../safe-utils';

interface CapabilityData {
  name: string;
  image: string;
  border: string;
}

const colors = {
  green: 'rgb(113,190,73)',
  brightGreen: 'rgb(190,214,66)',
  yellow: 'rgb(255,202,5)',
  brightOrange: 'rgb(238,172,66)',
  orange: 'rgb(243,117,33)',
  purple: 'rgb(158,84,160)',
  darkBlue: 'rgb(92,114,182)',
  lightBlue: 'rgb(143,194,233)',
  gray: 'rgb(187,189,190)',
  brown: 'rgb(157,130,92)',
  borderRed: 'rgb(227,39,38)',
  borderOrange: 'rgb(248,158,28)',
  borderGreen: 'rgb(0,128,62)',
};

const gapColors: { [key: string]: string } = {
  None: colors.borderRed,
  Planned: colors.lightBlue,
  Partial: colors.borderOrange,
  Full: colors.borderGreen,
};

const isSpecialPin = (name: string): boolean =>
  ['Server', 'Human', 'Endpoint', 'Application'].includes(name);

const endDir = '/assets/safe-endpoint-icons/';
const capDir = '/assets/safe-capability-icons/';

// uncomment to enable: all of these exist
const endIcons = [
  // 'automated-process',
  'building-controls',
  // 'ceo',
  // 'clerk',
  'customer',
  // 'guest',
  // 'manager',
  // 'remote-colleague',
  'remote-employee',
  // 'remote-technician',
  'salesperson',
  // 'secure-partner',
  'server',
  'subject-matter-expert',
  'technician',
  'video-camera',
  'video-surveillance',
];

const capImageSize = 50;
const pathColor = colors.lightBlue;
const gapx = 480;
const gapy = 260;
const middle = [640, 416];
const lines = {
  gap: gapy,
  top: middle[1] + gapy,
  middle: middle[1],
  bottom: middle[1] - gapy,
  left: middle[0] - gapx,
  right: middle[0] + gapx,
};

export interface CapabilityBadge {
  name: string;
  image: string;
  border: string;
  products?: CapabilityProduct[];
}

const capBadge = (
  cap: CapabilitySettings,
  border: string,
  image: string
): CapabilityBadge => ({
  name: cap.name ?? cap.id.toString(),
  image,
  border,
  products: cap.products?.filter((p) => p.active) ?? [],
});

const rangePoint = (
  start: number,
  end: number,
  fraction: number,
  middleRise = 0
) => {
  const mult = Math.max(0, Math.min(fraction, 1));
  return Math.round(
    start + (end - start) * mult + mult * (1 - mult) * middleRise
  );
};
const heatColor = (fraction = 0, opacity = 1): string => {
  const low = { r: 0, g: 31, b: 255 };
  const high = { r: 255, g: 31, b: 0 };
  return `rgba(${rangePoint(low.r, high.r, fraction, 200)},${rangePoint(
    low.g,
    high.g,
    fraction,
    200
  )},${rangePoint(low.b, high.b, fraction, 200)},${opacity})`;
};
const heatColorAlpha = (fraction = 0, alpha = 1): string => {
  const low = { r: 92, g: 114, b: 182 };
  const high = { r: 243, g: 117, b: 33 };
  return `rgba(${rangePoint(low.r, high.r, fraction, 200)},${rangePoint(
    low.g,
    high.g,
    fraction,
    400
  )},${rangePoint(low.b, high.b, fraction, 200)},${alpha})`;
};

export {
  CapabilityData,
  capImageSize,
  lines,
  pathColor,
  colors,
  endIcons,
  endDir,
  capDir,
  capBadge,
  heatColor,
  gapColors,
  isSpecialPin,
  heatColorAlpha,
};
