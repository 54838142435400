const appStyles = {
  container: ['gap-4', 'mx-8', 'my-4', 'max-w-[1920px]', 'flex-1'].join(' '),
  table: ['mb-4', 'font-sans', 'font-light', 'text-xs', 'text-gray-700'].join(
    ' '
  ),
  tr: ['odd:bg-gray-100'].join(' '),
  cell: ['text-left', 'pr-4', 'py-2', 'first:pl-4'].join(' '),
  ths: ['border-b-2', 'border-gray-400', 'whitespace-nowrap'].join(' '),
  tag: [
    'mb-1',
    'whitespace-nowrap',
    'bg-info-lighter',
    'rounded-sm',
    'px-2',
    'py-1',
    'mr-1',
    'uppercase',
    'font-semibold',
    'text-xs',
  ].join(' '),
  input: [
    'border',
    'rounded-sm',
    'border-gray-400',
    'px-2',
    'transition-all',
    'focus:border-sky',
    'hover:border-sky',
    'focus:shadow-[0_0_0_3px_rgba(0,188,235,0.25)]',
    'outline-0',
  ].join(' '),
  inputError: [
    'border-sec-red',
    'focus:border-sec-red',
    'focus:border-sec-red',
    'hover:border-sec-red',
    'focus:shadow-[0_0_0_3px_rgba(255,0,0,0.25)]',
  ].join(' '),
  filterButton: [
    'rounded-md',
    'mr-1',
    'mb-1',
    'px-3',
    'py-1',
    'font-semibold',
    'text-white',
    'cursor-pointer',
    'transition-colors',
  ].join(' '),
  objectRow: [
    'flex',
    'items-center',
    'border-gray-300',
    'border-t',
    'first:border-t-0',
    'min',
    'min-h-full',
    'px-3',
  ].join(' '),
  h2: ['text-xl', 'font-cisco', 'mt-4', 'mb-2'].join(' '),
  statusSwitch: [
    'first:rounded-l-full',
    'last:rounded-r-full',
    'text-white',
    'text-s',
    'font-semibold',
    'py-[2px]',
    'px-[5px]',
    'first:pl-4',
    'last:pr-4',
    'cursor-pointer',
  ].join(' '),
  numberColorBox: [
    'font-bold p-1',
    'rounded',
    'm-1',
    'w-16',
    'text-right',
    'text-black',
  ].join(' '),
};

export default appStyles;
