import appStyles from '../support/appStyles';
import { heatColor } from './SafeFlowChart/chart-constants';
import React from 'react';
import { scoreOpacity } from './safe-utils';

type BCTRiskSeverity = 25 | 50 | 75 | 100;

const statusDisplay = new Map<BCTRiskSeverity, { [key: string]: string }>([
  [25, { title: 'Low', colour: heatColor(0, scoreOpacity) }],
  [50, { title: 'Medium', colour: heatColor(1 / 3, scoreOpacity) }],
  [75, { title: 'High', colour: heatColor(2 / 3, scoreOpacity) }],
  [100, { title: 'Worst Case', colour: heatColor(1, scoreOpacity) }],
]);

interface Props {
  getCurrentRiskScore: () => BCTRiskSeverity;
  setScore: (s: BCTRiskSeverity) => void;
}

const RiskScoreSelector = ({ getCurrentRiskScore, setScore }: Props) => {
  return (
    <div className='flex ml-2'>
      {Array.from(statusDisplay.keys()).map((s) => (
        <div
          className={`${appStyles.statusSwitch} text-gray-700 text-xs opacity-100 border border-transparent hover:border-gray-500 whitespace-nowrap`}
          style={{
            backgroundColor:
              s === getCurrentRiskScore()
                ? statusDisplay.get(s)?.colour
                : '#f2f2f2',
            color: s === getCurrentRiskScore() ? '#fff' : '#405057',
          }}
          key={s}
          onClick={() => setScore(s)}
        >
          {statusDisplay.get(s)?.title}
        </div>
      ))}
    </div>
  );
};

export default RiskScoreSelector;
export { BCTRiskSeverity };
