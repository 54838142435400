import React from 'react';

const HelpContentSecurityFunction = () => (
  <li>
    <strong>Security Function</strong> – how a particular capability provides
    risk mitigation
    <ul className='pl-4 list-[circle]'>
      <li>
        <strong>ID</strong> – Identity
      </li>
      <li>
        <strong>DE</strong> - Detect
      </li>
      <li>
        <strong>PR</strong> - Protect
      </li>
      <li>
        <strong>RS</strong> - Respond
      </li>
      <li>
        <strong>RC</strong> - Recover
      </li>
    </ul>
  </li>
);

export default HelpContentSecurityFunction;
