import React, { useEffect, useState } from 'react';
import { DefaultValues, useForm } from 'react-hook-form';
import appStyles from '../support/appStyles';
import AdminObjectForm from './AdminObjectForm';
import Control from './api-types/control';

interface Props {
  sub?: boolean;
  item?: Control;
  close: () => void;
  isOpen: boolean;
}

const AdminControlForm = ({ sub, item, close, isOpen }: Props) => {
  const displayCategory = `${sub ? 'Subc' : 'C'}ontrol`;
  const endpoint = `${sub ? 'sub' : ''}control`;

  const formConfig = useForm<Control>();
  const { register, reset, control, getValues } = formConfig;
  const isNew = (): boolean => !item?.id;

  const convertToApi = (data: Control): Control => {
    const apiData: Control = {
      ...item,
      ...data,
    };
    return apiData;
  };

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    const createDefaultValues = (a?: Control): DefaultValues<Control> => ({
      id: a?.id,
      name: a?.name,
      identifier: a?.identifier,
      text: a?.text,
      discussion: a?.discussion,
    });
    reset(createDefaultValues(item));
  }, [reset, isOpen, item]);

  return (
    <AdminObjectForm
      close={close}
      isOpen={isOpen}
      endpoint={endpoint}
      convertToApi={convertToApi}
      data={item ? { ...item } : undefined}
      displayCategory={displayCategory}
      formConfig={formConfig}
      isNew={() => isNew()}
    >
      <div className='flex-col flex mt-4'>
        <label className='text-sm'>Identifier</label>
        <input
          className={`${appStyles.input} text-lg py-1`}
          defaultValue={item ? getValues('identifier') : ''}
          {...register('identifier', { required: true })}
        />
      </div>
      <div className='flex-col flex mt-4'>
        <label className='text-sm'>Name</label>
        <input
          className={`${appStyles.input} text-lg py-1`}
          defaultValue={item ? getValues('name') : ''}
          {...register('name', { required: true })}
        />
      </div>
      <div className='flex-col flex mt-4'>
        <label className='text-sm'>Description</label>
        <textarea
          className={`${appStyles.input} text-lg py-1`}
          defaultValue={item ? getValues('text') : ''}
          {...register('text')}
        />
      </div>
      <div className='flex-col flex mt-4'>
        <label className='text-sm'>Discussion</label>
        <textarea
          className={`${appStyles.input} text-lg py-1`}
          defaultValue={item ? getValues('discussion') : ''}
          {...register('discussion')}
        />
      </div>
    </AdminObjectForm>
  );
};

export default AdminControlForm;
