import React, { useEffect, useState } from 'react';
import AsyncContainer from '../complib/AsyncContainer';
import { SortConfig } from '../safe/safe-utils';
import TableSortHeading from '../safe/TableSortHeading';
import { useQueryItems } from '../support/queries';
import AdminArchitectureForm from './AdminArchitectureForm';
import Architecture from './api-types/architecture';
import {
  compareItems,
  defaultSortConfig,
  highlitContent,
  updateSort,
} from './helpers';
import AdminListPage from './page-elements/AdminListPage';
import NoneShowing from './page-elements/NoneShowing';

const styles = {
  colCapability: 'flex-1 min-w-40 mr-2',
  colName: 'flex-0 mr-2 w-52 my-2',
  colUpdate: 'flex-0 w-56 flex items-center',
};

const AdminArchitecturePage = () => {
  const data = useQueryItems('architecture', true);
  const [filtered, setFiltered] = useState([]);
  const [sortConfig, setSortConfig] = useState<SortConfig>(defaultSortConfig);
  const [openForm, setOpenForm] = useState<boolean>(false);
  const [selected, setSelected] = useState<Architecture>();
  const [search, setSearch] = useState('');

  const closeForm = () => {
    setSelected(undefined);
    setOpenForm(false);
  };

  useEffect(() => {
    setFiltered(
      (data ?? []).filter((a: Architecture) =>
        a.name.toLocaleLowerCase().includes(search?.trim().toLocaleLowerCase())
      )
    );
  }, [data, setFiltered, search]);

  const changeSort = (key: string) =>
    setSortConfig(updateSort(sortConfig, key));

  const compare = (a: Architecture, b: Architecture): number =>
    compareItems(a, b, sortConfig);

  const select = (cap?: Architecture): void => {
    setSelected(cap);
    setOpenForm(true);
  };

  const cellContent = (content = '') => highlitContent(content, search);

  return (
    <>
      <AdminListPage
        itemLabel='architecture'
        search={search}
        updateSearch={setSearch}
        addAction={select}
        header={
          <>
            {!!filtered?.length && (
              <div className='flex text-left text-sm mb-2 items-center'>
                <>
                  <TableSortHeading
                    updateSort={changeSort}
                    sort={sortConfig}
                    sortKey='name'
                    className={styles.colName}
                  >
                    Architecture
                  </TableSortHeading>
                  <div className={styles.colCapability}>Capabilities</div>
                  <div className={styles.colUpdate}>Updated</div>
                </>
              </div>
            )}
          </>
        }
        list={
          <>
            <AsyncContainer ready={!!data} className='mx-4'>
              {!!data &&
                filtered?.sort(compare).map((a: Architecture) => (
                  <div
                    key={a.id}
                    className={`flex items-center border-gray-300 border-t px-4 text-sm`}
                  >
                    <h3
                      className={`${styles.colName} font-semibold hover:underline cursor-pointer`}
                      onClick={() => select(a)}
                    >
                      {cellContent(a.name)}
                    </h3>
                    <div className={`${styles.colCapability} my-1`}>
                      {a.capability.map((p) => p.name).join(', ')}
                    </div>
                    <div className={styles.colUpdate}>{a.updated ?? '-'}</div>
                  </div>
                ))}
              <NoneShowing show={!filtered?.length} />
            </AsyncContainer>
          </>
        }
      />
      {openForm && (
        <AdminArchitectureForm
          architecture={selected}
          isOpen={openForm}
          close={closeForm}
        />
      )}
    </>
  );
};

export default AdminArchitecturePage;
