import React from 'react';
import { X } from 'react-feather';
import AsyncContainer from '../complib/AsyncContainer';
import Button from '../complib/Button';
import Card from '../complib/Card';
import appStyles from '../support/appStyles';
import { useQueryItems } from '../support/queries';
import { ItemSelections, ItemSettings } from '../support/selections.store';
import { useFWProductSelections } from '../support/stores';
import ProductPageFilters from './ProductPageFilters';
import ProductTable from './ProductTable';

const someSelected = (selected: ItemSelections<ItemSettings>) =>
  !!selected && Object.keys(selected).some((i) => selected[parseInt(i)].active);

const ProductPage = () => {
  const productData = useQueryItems('product');
  const { selections, deselectItem, getItemSetting } = useFWProductSelections();

  return (
    <section
      className={`${appStyles.container} grid grid-cols-[minmax(262px,1fr)_6fr] gap-8`}
    >
      <section>
        <ProductPageFilters />
      </section>
      <section className='h-20 w-full border'>
        <AsyncContainer ready={!!productData?.length}>
          <div className='col-span-4 mt-4'>
            {someSelected(selections) && (
              <>
                {(Object.keys(selections) as unknown[] as number[])
                  .filter((s) => selections[s].active)
                  .sort((a, b) =>
                    (selections[a].name ?? '') > (selections[b].name ?? '')
                      ? 1
                      : -1
                  )
                  .map((s) => (
                    <Card key={s} className='mb-4 relative'>
                      <h2 className='font-cisco flex-none text-2xl pb-2'>
                        {getItemSetting(s, 'name') as string}
                      </h2>
                      <Button
                        theme='ghost'
                        onClick={() => deselectItem(s)}
                        className='top-4 right-4 px-1 py-1 absolute mr-0'
                      >
                        <X size={16} />
                      </Button>
                      <ProductTable
                        name={getItemSetting(s, 'name') as string}
                      />
                    </Card>
                  ))}
              </>
            )}
          </div>
        </AsyncContainer>
      </section>
    </section>
  );
};
export default ProductPage;
