import React, { useEffect, useState } from 'react';
import Card from '../../complib/Card';
import Loader from '../../complib/Loader';
import appStyles from '../../support/appStyles';
import {
  BctSettings,
  RiskSettings,
  useBctRiskSelections,
} from '../../support/stores';
import { CapabilityRisk } from '../../types/capability';
import { NameObject } from '../../types/item';
import { scoreOpacity, SortConfig } from '../safe-utils';
import { heatColor } from '../SafeFlowChart/chart-constants';
import TableSortHeading from '../TableSortHeading';
import {
  changeSorting,
  DisplayType,
  itemDisplay,
  itemStyle,
  reportPrintOmitRows,
} from './report-utils';

interface Props {
  filteredRisks: string[] | undefined;
  highlight: CapabilityRisk[];
  select: (r: RiskSettings | CapabilityRisk | null) => void;
  currentSelection: { id: string; type: string } | null;
}

const ReportRiskPanel = ({
  filteredRisks,
  highlight,
  select,
  currentSelection,
}: Props) => {
  const { selections: bctSelections, isInitialized } = useBctRiskSelections();
  const [risks, setRisks] = useState<RiskSettings[]>();
  const [maxScore, setMaxScore] = useState<number>(1);
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    key: 'score',
    dir: -1,
  });

  useEffect(() => {
    const risks: { [key: string]: RiskSettings } = {};
    let max = 1;
    Object.values(bctSelections).forEach((bct: BctSettings) => {
      Object.values(bct.risks).forEach((r) => {
        const rKey = r.name ?? '';
        risks[rKey] = risks[rKey] ?? Object.assign({}, r, { score: 0 });
        if (bct.active && r.active) {
          let rScore = r.score;
          if (!rScore) {
            rScore = bct.groups[r.group as string].score;
          }
          const bctRiskScore = Math.round((bct.score * (rScore ?? 100)) / 100);
          risks[rKey].score = (risks[rKey].score ?? 0) + bctRiskScore;
          max = Math.max(risks[rKey].score ?? 0, max);
        }
      });
    });
    setMaxScore(max);
    setRisks(Object.values(risks));
  }, [bctSelections, isInitialized]);

  const compareRisks = (a: RiskSettings, b: RiskSettings): number => {
    const aComp = a[sortConfig.key as 'name' | 'score'] as number | string;
    const bComp = b[sortConfig.key as 'name' | 'score'] as number | string;
    if (aComp === bComp) {
      return (a.name ?? a.id) > (b.name ?? b.id) ? 1 : -1;
    }
    return aComp > bComp ? sortConfig.dir : -sortConfig.dir;
  };

  const updateSort = (key: string) =>
    setSortConfig(changeSorting(sortConfig, key));

  const showRisk = (risk: RiskSettings): boolean => {
    if (!filteredRisks) {
      return true;
    }
    return filteredRisks.includes(risk.name as string);
  };

  const riskDisplayType = (r: NameObject): DisplayType =>
    itemDisplay(
      currentSelection,
      r,
      'risk',
      highlight.map((h) => h.name)
    );

  return (
    <section>
      {!risks && <Loader className='w-16 h-16' />}
      {risks && (
        <>
          <header className='text-center'>
            <h2 className={`${appStyles.h2} image-vertical-offset`}>Risks</h2>
            <div
              className='flex items-stretch text-sm mb-2 -mr-6'
              data-html2canvas-ignore
            >
              <TableSortHeading
                updateSort={updateSort}
                sort={sortConfig}
                sortKey='score'
                className='pl-4 w-[100px]'
              >
                Priority
              </TableSortHeading>
              <TableSortHeading
                updateSort={updateSort}
                sort={sortConfig}
                sortKey='name'
                className='flex-1 flex justify-items-center'
              >
                Risk
              </TableSortHeading>
            </div>
          </header>
          <Card className={`mb-6 p-0`}>
            {Object.values(risks)
              .filter(showRisk)
              .sort(compareRisks)
              .map((r, n) => (
                <div
                  key={r.name}
                  className={itemStyle(
                    riskDisplayType({ name: r.name as string })
                  )}
                  onClick={() => select(r)}
                  {...reportPrintOmitRows(n)}
                >
                  <div
                    className={`${appStyles.numberColorBox}`}
                    style={{
                      backgroundColor: heatColor(
                        (r.score ?? 0) / maxScore,
                        scoreOpacity
                      ),
                    }}
                  >
                    <div className='image-vertical-offset'>{r.score}</div>
                  </div>
                  <h3 className='font-cisco flex-1 m-2 image-vertical-offset'>
                    {r.name}
                  </h3>
                </div>
              ))}
          </Card>
        </>
      )}
    </section>
  );
};

export default ReportRiskPanel;
