import React, { useState } from 'react';
import { useEffect } from 'react';
import AsyncContainer from '../complib/AsyncContainer';
import HuiSelect from '../complib/HuiSelect';
import MultiToggleFilter from '../safe/MultiToggleFilter';
import appStyles from '../support/appStyles';
import { useQueryItems } from '../support/queries';
import {
  useAppSettingsSelections,
  useFWProductSelections,
} from '../support/stores';
import { Product } from '../types/product';

const ProductPageFilters = () => {
  const productData = useQueryItems('product');
  const { getSetting, setSetting } = useAppSettingsSelections();
  const [frameworkOptions, setFrameworkOptions] = useState<{ name: string }[]>(
    []
  );
  const [groupings, setGroupings] = useState<{ [key: string]: string[] }>({});

  useEffect(() => {
    if (!productData?.length) {
      return;
    }
    const frameworkSet = new Set<string>();
    const groups: { [key: string]: string[] } = {};
    productData.forEach((p: Product) => {
      p.framework.forEach((f) => frameworkSet.add(f.framework));
      const group = p.filter;
      if (group) {
        groups[group] = groups[group] ?? [];
        groups[group].push(p.name);
      }
    });
    setGroupings(groups);
    const fwOptions = [...frameworkSet].map((f) => ({ name: f })) as {
      name: string;
    }[];
    setFrameworkOptions(fwOptions);
    setSetting(
      'filterFramework',
      getSetting('filterframework', fwOptions[0]?.name) as string
    );
  }, [productData, getSetting, setSetting]);

  return (
    <>
      <h2 className={appStyles.h2}>Framework</h2>
      <AsyncContainer ready={!!frameworkOptions.length}>
        <HuiSelect
          options={frameworkOptions}
          defaultValue={{ name: getSetting('filterFramework', '') }}
          displayKey='name'
          selection={(e) => setSetting('filterFramework', e.name as string)}
        />
      </AsyncContainer>
      <h2 className={appStyles.h2}>Products</h2>
      <MultiToggleFilter
        selectionsHook={useFWProductSelections}
        endpoint='product'
        groupings={groupings}
      />
    </>
  );
};

export default ProductPageFilters;
