import React, { useEffect, useState } from 'react';
import { Controller, DefaultValues, useForm } from 'react-hook-form';
import AsyncContainer from '../complib/AsyncContainer';
import HuiSelect from '../complib/HuiSelect';
import { capabilityIconsS3 } from '../safe/SafeFlowChart/capability-icons';
import appStyles from '../support/appStyles';
import { useQueryItems } from '../support/queries';
import { Capability } from '../types/capability';
import AdminObjectForm, { DeleteRelationships } from './AdminObjectForm';
import './capabilitySprites.css';
import CapabilityIcon from './page-elements/CapabilityIcon';
import ImageSelector from './page-elements/ImageSelector';
import MultiSearchControl from './page-elements/MultiSearchControl';
import MultiToggleControl, {
  MTOptions,
} from './page-elements/MultiToggleControl';

interface CapabilityValues {
  id?: number;
  name: string;
  pin: MTOptions;
  risk: MTOptions;
  product: MTOptions;
  domain: string;
  securityfunction: { name: string; id: number }[];
  maturitylevel: number;
  image: string;
}

interface Props {
  capability?: Capability;
  close: () => void;
  isOpen: boolean;
  customSubmit?: (data: CapabilityValues) => void;
}

/* hardcoding */
/* will later need an admin page and related endpoints */
const domainOptions = [
  { name: 'Management: Assets' },
  { name: 'Management: Building Services' },
  { name: 'Management: Governance' },
  { name: 'Management: Network Services' },
  { name: 'Secure Services' },
  { name: 'Segmentation: Encryption' },
  { name: 'Segmentation: Firewalling' },
  { name: 'Segmentation: Host' },
  { name: 'Segmentation: Physical' },
  { name: 'Threat Defense: Cyber' },
  { name: 'Threat Defense: Physical' },
  { name: 'Visibility' },
];

const styles = {
  control: ['flex-col', 'flex', 'mt-4'].join(' '),
};

const displayCategory = 'Capability';
const endpoint = 'capability';

const AdminCapabilityForm = ({
  isOpen,
  close,
  capability,
  customSubmit,
}: Props) => {
  const riskData = useQueryItems('risk');
  const pinData = useQueryItems('pin');
  const productData = useQueryItems('product');
  const architectureData = useQueryItems('architecture');
  const securityfunctionData = useQueryItems('securityfunction');
  const [productOptions, setProductOptions] = useState<MTOptions>([]);
  const [riskOptions, setRiskOptions] = useState<MTOptions>([]);
  const [defaults, setDefaults] = useState<boolean>(false);
  const [relationships, setRelationships] = useState<DeleteRelationships[]>([]);

  const formConfig = useForm<CapabilityValues>();
  const { register, reset, control, getValues } = formConfig;
  const isNew = (): boolean => !capability?.id;

  const convertToApi = (data: CapabilityValues): Capability => {
    const apiData: Capability = {
      ...capability,
      ...data,
      product: data.product ?? [],
      risk: (data.risk ?? []).map((r) => ({
        ...r,
        name: r.name.replace(/^\[.+\] /, ''),
      })),
    };
    return apiData;
  };

  useEffect(() => {
    const id = capability?.id;
    if (!id || !architectureData) {
      return;
    }
    const architectures: string[] = [];
    architectureData.forEach(
      (a: { name: string; capability: { id: number }[] }) => {
        if (a.capability.some((c) => c.id === id)) {
          architectures.push(a.name);
        }
      }
    );
    if (architectures.length) {
      setRelationships([
        {
          relatedClass: 'Architectures',
          objectTitles: architectures,
        },
      ]);
    }
  }, [capability, architectureData]);

  useEffect(() => {
    if (!isOpen || !riskData || !pinData || !productData || defaults) {
      return;
    }
    const createDefaultRisks = (): MTOptions =>
      (capability?.risk ?? []).map((r) => ({
        id: r.id,
        name: `[${r.impact}] ${r.name}`,
      })) as MTOptions;
    const createDefaultPins = (): MTOptions =>
      (capability?.pin ?? []).map((p) => ({
        id: p.id,
        name: p.name,
      })) as MTOptions;
    const createDefaultProducts = (): MTOptions =>
      (capability?.product ?? []).map((p) => ({
        id: p.id,
        name: p.name,
      })) as MTOptions;
    const createDefaultSecurityFunctions = (): MTOptions =>
      (capability?.securityfunction ?? []).map((sc) => ({
        id: sc.id,
        name: sc.name,
      })) as MTOptions;

    const createDefaultValues = (
      capability?: Capability
    ): DefaultValues<CapabilityValues> => ({
      name: capability?.name,
      id: capability?.id,
      pin: createDefaultPins(),
      risk: createDefaultRisks(),
      product: createDefaultProducts(),
      domain: capability?.domain,
      securityfunction: createDefaultSecurityFunctions(),
      maturitylevel: capability?.maturitylevel,
      image: capability?.image,
    });
    setDefaults(true);
    reset(createDefaultValues(capability));
  }, [reset, isOpen, riskData, pinData, productData, capability, defaults]);

  useEffect(() => {
    if (!productData?.length || !!productOptions.length) {
      return;
    }
    const options: MTOptions = [];
    productData.forEach((pd: { name: string; id: number }) =>
      options.push({ id: pd.id, name: pd.name })
    );
    setProductOptions(options);
  }, [productData, productOptions]);

  useEffect(() => {
    if (!riskData?.length || !!riskOptions.length) {
      return;
    }
    const options: MTOptions = [];
    riskData.forEach((d: { name: string; id: number; impact: string }) =>
      options.push({ id: d.id, name: `[${d.impact}] ${d.name}` })
    );
    setRiskOptions(options);
  }, [riskData, riskOptions]);

  const closeReset = () => {
    setDefaults(false);
    close();
  };

  return (
    <AdminObjectForm
      close={closeReset}
      isOpen={isOpen}
      endpoint={endpoint}
      convertToApi={convertToApi}
      data={capability}
      displayCategory={displayCategory}
      formConfig={formConfig}
      isNew={() => isNew()}
      deleteRelationships={relationships}
      customSubmit={customSubmit}
    >
      <div className={styles.control}>
        <label className='text-sm'>Image</label>
        <Controller
          control={control}
          name='image'
          defaultValue={getValues().image}
          render={({ field }) => (
            <ImageSelector
              value={field.value}
              onChange={field.onChange}
              imageFn={(imageName, scale) => (
                <CapabilityIcon icon={imageName} scale={scale ?? 0.3} />
              )}
              options={capabilityIconsS3}
            />
          )}
        />
      </div>
      <div className={styles.control}>
        <label className='text-sm'>Name</label>
        <input
          className={`${appStyles.input} text-lg py-1`}
          defaultValue={capability ? getValues('name') : ''}
          {...register('name', { required: true })}
        />
      </div>
      <div className={styles.control}>
        <label className='text-sm'>Domain</label>
        <Controller
          control={control}
          name='domain'
          defaultValue={getValues().domain}
          render={({ field }) => (
            <HuiSelect
              options={domainOptions}
              displayKey='name'
              defaultValue={{ name: field.value ?? '-' }}
              selection={(selection) => field.onChange(selection.name)}
              maxHeightCss='max-h-64'
            />
          )}
        />
      </div>
      <AsyncContainer ready={pinData}>
        <div className={styles.control}>
          <label className='text-sm'>Place in the Network</label>
          <Controller
            control={control}
            name='pin'
            defaultValue={getValues().pin}
            render={({ field }) => (
              <MultiToggleControl
                options={pinData}
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
        </div>
      </AsyncContainer>
      <AsyncContainer ready={securityfunctionData}>
        <div className={styles.control}>
          <label className='text-sm'>Security Function</label>
          <Controller
            control={control}
            name='securityfunction'
            defaultValue={getValues().securityfunction}
            render={({ field }) => (
              <MultiToggleControl
                options={securityfunctionData}
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
        </div>
      </AsyncContainer>
      <div className={styles.control}>
        <label className='text-sm'>Maturity Level</label>
        <Controller
          control={control}
          name='maturitylevel'
          defaultValue={getValues().maturitylevel}
          render={({ field }) => (
            <div className='flex w-min rounded-full cursor-pointer'>
              {[1, 2, 3].map((n) => (
                <div
                  key={n}
                  onClick={() => field.onChange(n)}
                  className={`${appStyles.statusSwitch} ${
                    field.value === n
                      ? 'bg-sky-darker'
                      : 'bg-gray-400 hover:bg-gray-500'
                  }`}
                >
                  {n}
                </div>
              ))}
            </div>
          )}
        />
      </div>
      <AsyncContainer ready={!!productOptions?.length}>
        <div className={styles.control}>
          <label className='text-sm'>Products</label>
          <Controller
            control={control}
            name='product'
            defaultValue={getValues().product}
            render={({ field }) => (
              <MultiToggleControl
                options={productOptions}
                onChange={field.onChange}
                value={field.value}
              />
            )}
          />
        </div>
      </AsyncContainer>
      <AsyncContainer ready={!!riskOptions?.length}>
        <div className={styles.control}>
          <label className='text-sm'>Threats</label>
          <Controller
            control={control}
            name='risk'
            defaultValue={getValues().risk}
            render={({ field }) => (
              <MultiSearchControl
                options={riskOptions}
                onChange={field.onChange}
                value={field.value}
              />
            )}
          />
        </div>
      </AsyncContainer>
    </AdminObjectForm>
  );
};

export default AdminCapabilityForm;
export { CapabilityValues };
