import React, { ReactNode } from 'react';
import { useState } from 'react';
import Button from '../complib/Button';
import Card from '../complib/Card';

interface Props {
  children: ReactNode;
}

const HelpSection = ({ children }: Props) => {
  const [isShowing, setIsShowing] = useState<boolean>(false);

  return (
    <>
      <Card
        className={`overflow-y-auto ${
          isShowing ? 'left-2/3' : 'left-full'
        } fixed bg-white h-full transition-left w-1/3 top-0 z-10`}
      >
        <h1 className='font-cisco text-2xl pb-6 border-b border-gray-300 mb-6'>
          SAFE Help
        </h1>
        {children}
      </Card>
      <Button
        onClick={() => setIsShowing(!isShowing)}
        className='fixed top-4 right-6 z-30'
      >
        {isShowing ? 'Hide' : 'Show'} help
      </Button>
    </>
  );
};

export default HelpSection;
