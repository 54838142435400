import React, { useEffect, useState } from 'react';
import AsyncContainer from '../complib/AsyncContainer';
import { SortConfig } from '../safe/safe-utils';
import TableSortHeading from '../safe/TableSortHeading';
import appStyles from '../support/appStyles';
import { useQueryItems } from '../support/queries';
import { useBctCategoriesSettings } from '../support/stores';
import { Bustech } from '../types/bustech';
import AdminBCTForm from './AdminBCTForm';
import { verticals } from './constants';
import { compareItems, defaultSortConfig, updateSort } from './helpers';
import AdminListPage from './page-elements/AdminListPage';
import BustechCategoryFilter from './page-elements/BustechCategoryFilter';
import NoneShowing from './page-elements/NoneShowing';

const styles = {
  colName: 'flex-0 mr-2 min-w-[16rem] max-w-[16rem] mr-2',
  colDesc: 'flex-0 mr-2 min-w-[20rem] max-w-[20rem]',
  colCats: 'flex-1 flex min-w-96 flex-wrap mr-2',
  colUpdate: 'flex-0 w-52 flex items-center',
  colThreat: 'flex-0 w-16 mr-2',
};

const AdminBCTPage = () => {
  const bctData = useQueryItems('bustech', true) as Bustech[];
  const [filteredBCTs, setFilteredBCTs] = useState<Bustech[]>();
  const [formOpened, setFormOpened] = useState<boolean>(false);
  const [selectedBCT, setSelectedBCT] = useState<Bustech>();

  const { getSetting, settings } = useBctCategoriesSettings();

  const compareBCTs = (a: Bustech, b: Bustech): number =>
    compareItems(a, b, sortConfig);

  const selectBCT = (bct?: Bustech): void => {
    setSelectedBCT(bct);
    setFormOpened(true);
    return;
  };
  const [sortConfig, setSortConfig] = useState<SortConfig>(defaultSortConfig);
  const changeSort = (key: string) =>
    setSortConfig(updateSort(sortConfig, key));

  useEffect(() => {
    setFilteredBCTs(
      (bctData ?? []).filter(
        (bct) =>
          verticals.some((cat) => getSetting(cat) && bct[cat] === 1) ||
          Object.values(settings).every(Boolean) // include bcts with no categories
      )
    );
  }, [bctData, setFilteredBCTs, getSetting, settings]);

  const closeForm = () => {
    setSelectedBCT(undefined);
    setFormOpened(false);
  };

  return (
    <>
      <AdminListPage
        itemLabel='BCT'
        addAction={selectBCT}
        filters={
          <>
            <h2 className={appStyles.h2}>Verticals</h2>
            <BustechCategoryFilter />
          </>
        }
        header={
          <>
            {!!filteredBCTs?.length && (
              <div className='flex text-left text-sm mb-2'>
                <>
                  <TableSortHeading
                    updateSort={changeSort}
                    sort={sortConfig}
                    sortKey='name'
                    className={styles.colName}
                  >
                    BCT
                  </TableSortHeading>
                  <div className={styles.colDesc}>Description</div>
                  <TableSortHeading
                    updateSort={changeSort}
                    sort={sortConfig}
                    sortKey='risk'
                    className={styles.colThreat}
                  >
                    Threats
                  </TableSortHeading>
                  <div className={styles.colCats}>Verticals</div>
                  <div className={styles.colUpdate}>Updated</div>
                </>
              </div>
            )}
          </>
        }
        list={
          <>
            <AsyncContainer ready={!!bctData} className='m-4'>
              {filteredBCTs
                ?.sort(compareBCTs)
                .map((bct: Bustech, i: number) => (
                  <div
                    key={bct.name + i}
                    className={`flex items-center border-gray-300 border-t px-4 text-sm`}
                  >
                    <h3
                      className={`${styles.colName} font-semibold hover:underline cursor-pointer`}
                      onClick={() => selectBCT(bct)}
                    >
                      {bct.name}
                    </h3>
                    <div className={styles.colDesc}>{bct.bustechdes}</div>
                    <div
                      className={styles.colThreat}
                      title={bct.risk.map((r) => r.name).join(', ')}
                    >
                      <div className='bg-warning min-w-8 text-center text-white rounded-full font-bold px-2 py-[2px] text-lg my-1'>
                        {bct.risk.length}
                      </div>
                    </div>
                    <div className={`${styles.colCats} my-2 capitalize`}>
                      <div>
                        {verticals.filter((cat: string) => bct[cat]).join(', ')}
                      </div>
                    </div>
                    <div className={styles.colUpdate}>{bct.update ?? '-'}</div>
                  </div>
                ))}
              <NoneShowing show={!!bctData && !filteredBCTs?.length} />
            </AsyncContainer>
          </>
        }
      />
      {formOpened && (
        <AdminBCTForm bct={selectedBCT} isOpen={formOpened} close={closeForm} />
      )}
    </>
  );
};

export default AdminBCTPage;
