import React, { useState } from 'react';
import { useEffect } from 'react';
import { Check, ChevronDown, ChevronUp } from 'react-feather';
import AsyncContainer from '../../complib/AsyncContainer';
import SearchInput from '../../complib/SearchInput';
import appStyles from '../../support/appStyles';
import { useQueryItems } from '../../support/queries';
import Control from '../api-types/control';
import { highlitContent } from '../helpers';

interface ControlValue {
  id: number;
  subcontrols?: { id: number }[];
}

interface Props {
  value: ControlValue[];
  onChange: (value: ControlValue[]) => void;
}

interface ControlProps extends Props {
  isParent: boolean;
  // value: ControlValue[];
  // onChange: (value: ControlValue[]) => void;
}

const ControlSelector = ({ isParent, value, onChange }: ControlProps) => {
  const [search, setSearch] = useState<string>('');
  const [filtered, setFiltered] = useState<Control[]>();
  const [open, setOpen] = useState<number | null>(null);
  const controlData = useQueryItems(isParent ? 'control' : 'subcontrol');

  useEffect(() => {
    if (!controlData) {
      return;
    }
    const term = search.trim()?.toLocaleLowerCase() ?? '';
    const hasTerm = (
      c: Control,
      prop: 'name' | 'description' | 'discussion' | 'identifier'
    ) => c[prop]?.toLocaleLowerCase().includes(term);
    const filteredControls = controlData.filter((c: Control) => {
      return (
        !term ||
        hasTerm(c, 'name') ||
        hasTerm(c, 'description') ||
        hasTerm(c, 'discussion') ||
        hasTerm(c, 'identifier')
      );
    });
    setFiltered(filteredControls);
  }, [setFiltered, search, controlData]);

  const getControlData = (id: number) =>
    controlData.find((c: Control) => c.id === id);
  const getControlValue = (id: number) =>
    (value ?? []).find((c: ControlValue) => c.id === id);

  const isSelected = (id: number) => !!getControlValue(id);

  const subCount = (id: number) =>
    getControlValue(id)?.subcontrols?.length ?? 0;

  const setSubcontrols = (id: number, controlValue: ControlValue[]) => {
    const controls = [...value];
    const updatedControl = controls.find((c) => c.id === id);
    if (updatedControl) {
      updatedControl.subcontrols = controlValue;
    }
    // console.log('sub', controls);
    onChange(controls);
  };

  const toggleControl = (id: number): void => {
    const newValue = [...(value ?? [])];
    if (isSelected(id)) {
      const loc: number = newValue.findIndex((c) => c.id === id);
      newValue.splice(loc, 1);
    } else {
      newValue.push({ id });
    }
    // console.log(newValue);
    onChange(newValue);
  };

  return (
    <AsyncContainer ready={controlData?.length}>
      <SearchInput
        value={search}
        changeEvent={(term) => setSearch(term)}
        placeholder='Search controls&hellip;'
        className='mb-2'
      />
      {isParent && (
        <div className='grid grid-cols-5 px-2 text-xs font-bold'>
          <div>Identifier</div>
          <div>Name</div>
          <div>Discussion</div>
          <div>Description</div>
          <div>Subcontrols</div>
        </div>
      )}
      <div
        className={`${appStyles.input} bg-white ${
          isParent ? 'max-h-80' : 'max-h-48'
        } overflow-y-scroll`}
      >
        {filtered?.map((c: Control) => (
          <div key={c.id}>
            <div
              className={`grid grid-cols-5 text-sm border-b -mx-2 px-2  ${
                isSelected(c.id) ? '' : 'bg-gray-200 text-gray-500'
              } border-sec-light-gray`}
            >
              <div className='flex items-center'>
                <Check
                  onClick={() => toggleControl(c.id)}
                  className={`rounded-full p-1 mr-1 my-1 ${
                    isSelected(c.id)
                      ? 'bg-sec-green'
                      : 'bg-sec-light-gray text-sec-light-gray'
                  }`}
                />
                {highlitContent(c.identifier, search)}
              </div>
              <div className='flex items-center'>
                {highlitContent(c.name, search)}
              </div>
              <div className='flex items-center leading-4 py-1'>
                {highlitContent(c.discussion, search)}
              </div>
              <div className='flex items-center leading-4 py-1'>
                {highlitContent(c.description, search)}
              </div>
              <div className='flex items-center'>
                {isParent && isSelected(c.id) && (
                  <div
                    onClick={() => setOpen(open === c.id ? -1 : c.id)}
                    className='flex items-center cursor-pointer'
                  >
                    <div
                      className={`rounded-full text-white ${
                        isSelected(c.id) ? 'bg-sec-dark-gray' : 'bg-gray-400'
                      } font-bold w-6 h-6 flex justify-center items-center my-1`}
                    >
                      <div>{subCount(c.id)}</div>
                    </div>
                    {c.id === open && <ChevronDown />}
                    {c.id !== open && <ChevronUp />}
                  </div>
                )}
              </div>
            </div>
            {isParent && (
              <div
                className={`-mx-2 bg-sec-light-gray px-2 box-border overflow-hidden ${
                  open === c.id ? 'max-h-56 py-2' : 'max-h-0'
                } transition-max-h`}
              >
                <ControlSelector
                  isParent={false}
                  onChange={(value: ControlValue[]) =>
                    setSubcontrols(c.id, value)
                  }
                  value={getControlValue(c.id)?.subcontrols ?? []}
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </AsyncContainer>
  );
};

const FrameworkControlSelector = ({ value, onChange }: Props) => {
  return (
    <>
      <ControlSelector isParent={true} onChange={onChange} value={value} />
    </>
  );
};

export default FrameworkControlSelector;
