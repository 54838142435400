import React from 'react';
import appStyles from '../support/appStyles';
import {
  useMaturitySelections,
  usePinFilterSelections,
  useSecurityFunctionSelections,
} from '../support/stores';
import MultiToggleFilter from './MultiToggleFilter';
import CapabilityModeSelect from './SafeFlowChart/CapabilityModeSelect';

const CapabilityStandardFilters = () => {
  return (
    <>
      <h2 className={appStyles.h2}>Capability Selection</h2>
      <CapabilityModeSelect />
      <h2 className={appStyles.h2}>Place in the Network</h2>
      <MultiToggleFilter
        selectionsHook={usePinFilterSelections}
        endpoint='pin'
      />
      <h2 className={appStyles.h2}>Security Function</h2>
      <MultiToggleFilter
        selectionsHook={useSecurityFunctionSelections}
        endpoint='securityfunction'
      />
      <h2 className={appStyles.h2}>Maturity Level</h2>
      <MultiToggleFilter
        selectionsHook={useMaturitySelections}
        options={[
          { id: 1, name: 'P1' },
          { id: 2, name: 'P2' },
          { id: 3, name: 'P3' },
        ]}
      />
    </>
  );
};

export default CapabilityStandardFilters;
