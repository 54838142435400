import React from 'react';
import appStyles from '../support/appStyles';
import HelpContentSecurityFunction from './HelpContentSecurityFunction';
import { SafeProps } from '../safe/safe-utils';

const CapabilityHelp = ({ safeMode }: SafeProps) => {
  return (
    <>
      {safeMode === 'risk' && (
        <>
          <h2 className={appStyles.h2}>
            SAFE Risk Assessment – Capabilities (2)
          </h2>
          <p className='mb-4'>
            On this page you can input data gathered from your customer relating
            to their security capability coverage. Security capability scoring
            is previewed here and the controls are stack ranked by score.
          </p>
          <p className='mb-4'>
            You can leverage the filters on the left to show specific
            capabilities in relation to:
          </p>
        </>
      )}
      {safeMode === 'architect' && (
        <>
          <h2 className={appStyles.h2}>SAFE Architecture – Capabilities (1)</h2>
          <p className='mb-4'>
            On this page you can input data gathered from your customer relating
            to their security capability coverage. You can leverage the filters
            on the left to show specific capabilities in relation to:
          </p>
        </>
      )}
      <ul className='mb-4 pl-4 list-disc'>
        <li>
          <strong>Place in the Network</strong> – applicable part of the network
          the control applies to
        </li>
        {safeMode === 'risk' && (
          <li>
            <strong>Minimum Capability Score</strong> – filtering capability
            scores less than a certain value
          </li>
        )}
        <li>
          <strong>Customer Maturity Level</strong> – level of acceptable risk
          based on assets and funding
        </li>
        <HelpContentSecurityFunction />
        <li>
          <strong>Coverage</strong> – explained below, current success level of
          a particular capability
        </li>
      </ul>
      <p className='mb-4'>Coverage Details:</p>
      <ul className='mb-4 pl-4 list-disc'>
        <li>
          If a security control is not in place, you can simple mark the control
          as&nbsp;<strong>&ldquo;None&rdquo;</strong>
        </li>
        <li>
          If a security control is planned to be deployed in the near future,
          you can mark the control as&nbsp;
          <strong>&ldquo;Planned&rdquo;</strong> and add the applicable solution
          or vendor name(s)
        </li>
        <li>
          If a security control is partially deployed or partially successful
          mark the control as&nbsp;<strong>&ldquo;Partial&rdquo;</strong> and
          add the applicable solution or vendor name(s)
        </li>
        <li>
          If a security control is fully deployed and fully successful mark the
          control as&nbsp;<strong>&ldquo;Full&rdquo;</strong> and add the
          applicable solution or vendor name(s)
        </li>
      </ul>
    </>
  );
};

export default CapabilityHelp;
