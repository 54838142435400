import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
  title: string;
  position: number;
  enabled: boolean;
  active: boolean;
  link: string;
}

const Step = ({ title, position, enabled, active, link }: Props) => {
  const styles = {
    step: ['relative flex flex-col items-center w-36'].join(' '),
    stepNotFirst: [
      'before:block',
      'before:absolute',
      'before:content',
      `before:content-['']`,
      'before:w-full',
      'before:-left-1/2',
      enabled ? 'before:bg-success' : 'before:bg-gray-300',
      'before:top-6',
      'before:h-[2px]',
      'before:z-0',
    ].join(' '),
    icon: [
      enabled
        ? 'border-success hover:border-success-darker'
        : 'border-gray-300',
      'rounded-full',
      'w-12',
      'h-12',
      'flex',
      'justify-center',
      'items-center',
      'border-2',
      'z-10',
      'font-cisco',
      'text-xl',
      enabled ? 'text-gray-800 hover:text-black' : 'text-gray-600',
      active ? 'bg-success' : enabled ? 'bg-white' : 'bg-gray-300',
    ].join(' '),
    iconEnabled: ['border-success'].join(' '),
    label: [
      'font-cisco',
      enabled ? 'text-gray-800 hover:text-black' : 'text-gray-600',
    ].join(' '),
  };

  if (enabled) {
    return (
      <Link
        to={link}
        className={`${styles.step} ${position > 1 && styles.stepNotFirst}`}
      >
        <div className={`${styles.icon} ${styles.iconEnabled}`}>{position}</div>
        <div className={styles.label}>{title}</div>
      </Link>
    );
  }
  return (
    <div
      className={`${styles.step} ${
        position > 1 && styles.stepNotFirst
      } cursor-not-allowed`}
    >
      <div className={styles.icon}>
        <div>{position}</div>
      </div>
      <div className={styles.label}>{title}</div>
    </div>
  );
};

export default Step;
