import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import Step from '../complib/Step';
import Steps from '../complib/Steps';
import appStyles from '../support/appStyles';
import { useQueryItems } from '../support/queries';
import {
  usePinFilterSelections,
  useSafeSettingsSelections,
} from '../support/stores';
import CapabilityPage from './CapabilityPage';
import ReportPage from './Report/ReportPage';
import { isCurrent } from './safe-utils';
import SafeFileActionsMenu from './SafeFileActionsMenu';
import SafeFlowChart from './SafeFlowChart/SafeFlowChart';
import SafeRecommendations from './SafeRecommendations';
import SafeUsageSurveyModal from './SafeUsageSurveyModal';

const SafeArchitect = () => {
  const { selections: pinFilterSelections } = usePinFilterSelections();
  const { getSetting: getSafeSetting } = useSafeSettingsSelections();
  const pinsReady = !!Object.keys(pinFilterSelections ?? {}).length;
  const navigate = useNavigate();
  const capabilityData = useQueryItems('capability');

  useEffect(() => {
    if (location.pathname.endsWith('capability')) {
      return;
    }
    if (location.pathname.endsWith('report') && pinsReady) {
      return;
    }
    if (
      location.pathname.endsWith('safe-flow') &&
      capabilityData &&
      pinsReady
    ) {
      return;
    }
    if (
      location.pathname.endsWith('recommendations') &&
      capabilityData &&
      pinsReady
    ) {
      return;
    }
    navigate('capability');
  }, [navigate, pinsReady, capabilityData]);

  return (
    <section className={`${appStyles.container} mt-3 block`}>
      <div>
        <Steps>
          <Step
            title='Capabilities'
            position={1}
            enabled={true}
            active={isCurrent('capability')}
            link='capability'
          ></Step>

          <Step
            title='Gap Report'
            position={2}
            enabled={pinsReady}
            active={isCurrent('report')}
            link='report'
          ></Step>
          <Step
            title='Flow Diagram'
            position={3}
            enabled={pinsReady}
            active={isCurrent('safe-flow')}
            link='safe-flow'
          ></Step>
          <Step
            title='Recommendations'
            position={4}
            enabled={pinsReady}
            active={isCurrent('recommendations')}
            link='recommendations'
          ></Step>
        </Steps>
        <hr className='bg-none border-b border-b-gray-400 my-2' />
        <Routes>
          <Route path='/' element={<Navigate replace to='capability' />} />
          <Route
            path='capability'
            element={<CapabilityPage safeMode='architect' />}
          />
          {pinsReady && (
            <>
              <Route
                path='report'
                element={<ReportPage safeMode='architect' />}
              />
              <Route
                path='safe-flow'
                element={<SafeFlowChart safeMode='architect' />}
              />
              <Route
                path='recommendations'
                element={<SafeRecommendations safeMode='architect' />}
              />
            </>
          )}
        </Routes>
      </div>
      {(getSafeSetting('showSurvey') as boolean) && (
        <SafeUsageSurveyModal context='safe-architect' />
      )}
      <SafeFileActionsMenu redirect='capability' />
    </section>
  );
};

export default SafeArchitect;
