import React from 'react';

interface Props {
  className?: string;
  value?: boolean;
  onClick?: () => void;
}

const Switch = ({ className, value = false, onClick }: Props) => {
  const styles = {
    span: [
      `${value ? 'bg-sky' : 'bg-gray-300'}`,
      'transitiion-all',
      'relative',
      'flex',
      'items-center',
      'justify-items-center',
      'h-[12px]',
      'w-[34px]',
      'rounded-full',
      'before:absolute',
      'before:top-0',
      'before:right-0',
      'before:bottom-0',
      'before:left-0',
      'before:content-[""]',
      'before:pointer-events-none',
      'before:opacity-0',
      'before:rounded-full',
      `${value ? 'after:bg-white' : 'after:bg-gray-500'}`,
      'after:border',
      `${value ? 'after:border-sky' : 'after:border-gray-500'}`,
      `${value ? 'after:translate-x-[16px]' : 'after:translate-x-[0px]'}`,
      'after:transition-all',
      'after:block',
      'after:absolute',
      'after:left-0',
      'after:content-[""]',
      'after:cursor-pointer',
      'after:h-[18px]',
      'after:w-[18px]',
      'after:rounded-full',
      'hover:after:shadow-[0_0_0_3px_rgba(0,188,235,0.25)]',
      'focus:after:shadow-[0_0_0_3px_rgba(0,188,235,0.25)]',
    ].join(' '),
  };
  return (
    <label
      className={
        className + ' cursor-pointer inline-flex relative items-center'
      }
    >
      <span className={styles.span} onClick={onClick}></span>
    </label>
  );
};

export default Switch;
