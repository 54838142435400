import React, { useState } from 'react';
import { useEffect } from 'react';
import { Controller, DefaultValues, useForm } from 'react-hook-form';
import appStyles from '../support/appStyles';
import { Product } from '../types/product';
import AdminObjectForm from './AdminObjectForm';
import ImageSelector from './page-elements/ImageSelector';

interface ProductValues {
  id?: number;
  name: string;
  description: string;
  link: string;
  productmanager: string;
  mailer: string;
  sales: string;
  image: string;
  filter: string;
}

interface Props {
  product?: Product;
  close: () => void;
  isOpen: boolean;
}

const displayCategory = 'Product';
const endpoint = 'product';

const iconOptions = [
  'Duo.png',
  'amp4ep.png',
  'anyconnect.png',
  'cloudlock.png',
  'cybervision.png',
  'esa.png',
  'fp.png',
  'investigate.png',
  'ise.jpg',
  'meraki-mx.png',
  'meraki-sm.png',
  'securex.png',
  'swatch-cloud1.png',
  'swe.png',
  'talos.png',
  'tetration.png',
  'tg.png',
  'umbrella.png',
  'wsa.png',
];

const styles = {
  control: ['flex-col', 'flex', 'mt-4'].join(' '),
};

const productIcon = (icon?: string, scale?: number) => {
  const url = `${
    icon?.indexOf('assets/safe-icons/') === 0 ? '/' : '/assets/safe-icons/'
  }${icon}`;
  const className = scale === 0.25 ? 'w-8 h-8' : 'w-12 h-12';
  return icon ? (
    <img src={url} alt='Product logo' className={`${className} rounded-full`} />
  ) : (
    <div className={`${className} bg-sec-light-gray rounded-full`}></div>
  );
};

const AdminProductForm = ({ product, close, isOpen }: Props) => {
  const [defaults, setDefaults] = useState<boolean>(false);
  const formConfig = useForm<ProductValues>();
  const { register, reset, control, getValues } = formConfig;
  const isNew = (): boolean => !product?.id;

  useEffect(() => {
    if (!isOpen || defaults) {
      return;
    }
    const createDefaultValues = (
      product?: Product
    ): DefaultValues<ProductValues> => ({
      name: product?.name,
      id: product?.id,
      description: product?.description,
      link: product?.link,
      productmanager: product?.productmanager ?? '',
      mailer: product?.mailer ?? '',
      sales: product?.sales ?? '',
      image: product?.image,
      filter: product?.filter,
    });
    setDefaults(true);
    reset(createDefaultValues(product));
  }, [reset, isOpen, product, defaults]);

  const convertToApi = (data: ProductValues): ProductValues => {
    const apiData: ProductValues = {
      ...product,
      ...data,
    };
    return apiData;
  };

  const closeReset = () => {
    setDefaults(false);
    close();
  };

  return (
    <AdminObjectForm
      close={closeReset}
      isOpen={isOpen}
      endpoint={endpoint}
      convertToApi={convertToApi}
      data={product ? { ...product } : undefined}
      displayCategory={displayCategory}
      formConfig={formConfig}
      isNew={() => isNew()}
    >
      <div className={styles.control}>
        <label className='text-sm'>Image</label>
        <Controller
          control={control}
          name='image'
          defaultValue={getValues().image}
          render={({ field }) => (
            <ImageSelector
              value={field.value}
              onChange={field.onChange}
              imageFn={(imageName, scale) => productIcon(imageName, scale)}
              options={iconOptions}
            />
          )}
        />
      </div>
      <div className='flex-col flex mt-4'>
        <label className='text-sm'>Name</label>
        <input
          className={`${appStyles.input} text-lg py-1`}
          defaultValue={product ? getValues('name') : ''}
          {...register('name', { required: true })}
        />
      </div>
      <div className='flex-col flex mt-4'>
        <label className='text-sm'>Description</label>
        <textarea
          className={`${appStyles.input} py-1`}
          defaultValue={product ? getValues('description') : ''}
          {...register('description')}
        ></textarea>
      </div>
      <div className='flex-col flex mt-4'>
        <label className='text-sm'>Filter group (product page filter)</label>
        <input
          className={`${appStyles.input} py-1`}
          defaultValue={product ? getValues('filter') : ''}
          {...register('filter')}
        />
      </div>
      <div className='flex-col flex mt-4'>
        <label className='text-sm'>Link</label>
        <input
          className={`${appStyles.input} py-1`}
          defaultValue={product ? getValues('link') : ''}
          {...register('link')}
        />
      </div>
      <div className='flex-col flex mt-4'>
        <label className='text-sm'>Product Manager</label>
        <input
          className={`${appStyles.input} py-1`}
          defaultValue={product ? getValues('productmanager') : ''}
          {...register('productmanager')}
        />
      </div>
      <div className='flex-col flex mt-4'>
        <label className='text-sm'>Mailer</label>
        <input
          className={`${appStyles.input} py-1`}
          defaultValue={product ? getValues('mailer') : ''}
          {...register('mailer')}
        />
      </div>
      <div className='flex-col flex mt-4'>
        <label className='text-sm'>Sales</label>
        <input
          className={`${appStyles.input} py-1`}
          defaultValue={product ? getValues('sales') : ''}
          {...register('sales')}
        />
      </div>
    </AdminObjectForm>
  );
};

export default AdminProductForm;
