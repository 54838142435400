import React from 'react';
import { Link } from 'react-router-dom';
import Button from './complib/Button';
import appStyles from './support/appStyles';

const HomePage = () => {
  const styles = {
    card: [
      'hover:shadow-xl',
      'hover:border-gray-300',
      'transition-all',
      'h-full',
      'overflow-hidden',
      'group',
      'p-0',
      'bg-[rgba(255,255,255,0.4)]',
      'hover:bg-white',
    ].join(' '),
    p: ['text-sec-dark-gray', 'pt-2', 'pb-8'].join(' '),
    buttonHeading: ['font-cisco font-bold text-xl pl-6 pr-6'].join(' '),
    groupHeading: ['font-cisco text-3xl'].join(' '),
  };

  return (
    <>
      <section className='bg-pri-midnight px-8 pb-2 pt-6 sm:pt-0'>
        <section className='max-w-[1920px] grid grid-cols-1 sm:grid-cols-3 lg:h-[60vh] gap-8'>
          <div className='flex flex-col justify-center sm:pb-6'>
            <h2 className='font-cisco text-xl md:text-2xl text-pri-sky leading-tight mb-4'>
              Risk and Compliance Tool
            </h2>
            <h1 className='font-cisco text-2xl sm:text-3xl lg:text-4xl text-white leading-snug'>
              Increase consultative value add with risk based security design
            </h1>
          </div>
          <img
            src='/assets/images/home-marquee-bg-no-products.png'
            alt='Risk and Compliance Tool marquee image'
            className='col-span-2 max-h-[60vh]'
          />
        </section>
      </section>
      <section className={`${appStyles.container} pt-2`}>
        <section className='grid grid-cols-1 lg:grid-cols-2 gap-8'>
          <section className='flex flex-col gap-4 bg-white rounded shadow-md'>
            <header className='bg-gradient-to-r from-pri-midnight to-sec-blue pb-0 rounded-t flex overflow-x-hidden items-center'>
              <h2
                className={`${styles.groupHeading} p-4 z-10 text-pri-sky font-bold grow`}
              >
                SAFE Workshops
              </h2>
              <img
                src='/assets/images/safe-key.png'
                alt='SAFE key icon'
                className='grow-0 w-28 h-fit z-0 pr-4'
              />
            </header>
            <div className='p-4'>
              <Link to='/SAFE-architect'>
                <Button theme='ghost' className={styles.buttonHeading}>
                  SAFE Architect
                </Button>
              </Link>
              <p className={styles.p}>
                Gap Analysis Based on Cisco SAFE Reference Architectures
              </p>
              <Link to='/SAFE-risk'>
                <Button theme='ghost' className={styles.buttonHeading}>
                  SAFE Risk
                </Button>
              </Link>
              <p className={styles.p}>
                Qualitative Risk and Gap Analysis Based on Cisco SAFE Reference
                Architectures
              </p>
            </div>
          </section>
          <section className='flex flex-col gap-4 bg-white rounded shadow-md'>
            <header className='bg-gradient-to-r from-sec-dark-gray to-gray-600 pb-0 rounded-t flex overflow-x-hidden items-center'>
              <h2
                className={`${styles.groupHeading} p-4 z-10 text-sec-light-gray font-bold`}
              >
                Framework Mapping
              </h2>
            </header>
            <div className='p-4'>
              <Link to='/product'>
                <Button theme='ghost' className={styles.buttonHeading}>
                  Product
                </Button>
              </Link>
              <p className={styles.p}>
                Guide organizations in achieving their compliance goals with
                Cisco solutions
              </p>
              <Link to='/framework'>
                <Button theme='ghost' className={styles.buttonHeading}>
                  Framework
                </Button>
              </Link>
              <p className={styles.p}>
                Reveal Cisco solutions that satisfy specific compliance
                requirements
              </p>
              {/* <Link to='/business-need'>
                <Button theme='ghost' className={styles.buttonHeading}>
                  Business Need
                </Button>
              </Link>
              <p className={styles.p}>
                View Cisco solution coverage by business use case
              </p> */}
            </div>
          </section>
        </section>
      </section>
    </>
  );
};

export default HomePage;
