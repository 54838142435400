import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import '@aws-amplify/ui-react/styles.css';
import { Auth, Hub } from 'aws-amplify';
import React, { useCallback, useEffect, useState } from 'react';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Button from './complib/Button';
import Card from './complib/Card';
import Loader from './complib/Loader';
import FrameworkPage from './framework-product-tool/FrameworkPage';
import Header from './framework-product-tool/Header';
import ProductPage from './framework-product-tool/ProductPage';
import HomePage from './HomePage';
import ManageData from './manage-data/ManageData';
import Safe from './safe/Safe';
import SafeArchitect from './safe/SafeArchitect';
import { queryClient } from './support/queries';
import { useAppSettingsSelections } from './support/stores';
import { isAdminUser, isAuthenticated } from './support/utils';
import Toasts from './complib/Toasts';

const messages: { [key: string]: string } = {
  signin: 'Signing in',
  signout: 'Signed out',
  connected: 'Connected',
  error: 'There was an error with the sign-in',
};

// Auth.configure({
//   Auth: {
//     region: 'us-west-2',
//     userPoolId: process.env.REACT_APP_COGNITO_POOL,
//     // userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
//     // hardcoding: this should be injected by aws build step. check / delete hardcoding on next deployment
//     // DEV env only
//     userPoolWebClientId: '5mbadl0g93r9lchn5jb83fgpp2',
//     oauth: {
//       domain: process.env.REACT_APP_COGNITO_DOMAIN,
//       scope: ['email', 'openid'],
//       redirectSignIn: process.env.REACT_APP_COGNITO_REDIRECT,
//       responseType: 'code',
//     },
//   },
// });

Auth.configure({
  Auth: {
    region: 'us-west-2',
    userPoolId: 'us-west-2_zgWYBuert',
    userPoolWebClientId: 'sr6rlos7l6cj2tqrk84atktt8',
    oauth: {
      domain: 'risk-compliance.auth.us-west-2.amazoncognito.com',
      scope: ['email', 'openid'],
      redirectSignIn: 'https://frameworktool.cisco.com',
      responseType: 'code',
    },
  },
});

const App = () => {
  const { setSetting: setAppSetting } = useAppSettingsSelections();
  const [signinState, setSigninState] = useState(messages.signout);

  const federatedSignin = () => {
    setSigninState(messages.signin);
    Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Cognito,
    });
  };

  const buildMode = process.env.NODE_ENV;
  const hasAuth = () => isAuthenticated();

  const getToken = useCallback(() => {
    return Auth.currentSession()
      .then((userToken) => {
        return userToken;
      })
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    if (buildMode !== 'development') {
      getToken().then((userToken) => {
        if (userToken != null) {
          const idTokenObj = userToken.getIdToken();
          setAppSetting(
            'userGroups',
            idTokenObj.decodePayload()['cognito:groups']
          );
          setAppSetting('tokenExpiry', userToken.getIdToken().getExpiration());
          setSigninState(messages.connected);
        }
      });
    } else {
      // local dev: need to add a valid token to .env.local
      const devToken = process.env.REACT_APP_LOCAL_TOKEN ?? '';
      setAppSetting('devToken', devToken);
      const jwtPayload = JSON.parse(window.atob(devToken.split('.')[1]));
      setAppSetting('tokenExpiry', jwtPayload.exp as number);
    }
  }, [setAppSetting, buildMode, getToken]);

  useEffect(() => {
    setAppSetting('buildMode', buildMode ?? 'production');
  }, [buildMode, setAppSetting]);

  useEffect(() => {
    Hub.listen('auth', ({ payload }) => {
      switch (payload.event) {
        case 'signIn':
        case 'cognitoHostedUI':
          getToken().then((userToken) => {
            if (userToken != null) {
              setSigninState(messages.connected);
            }
          });
          break;
        case 'signOut':
          setAppSetting('tokenExpiry', 0);
          setSigninState(messages.signout);
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          setSigninState(messages.error);
          console.error('Sign in failure', payload.data);
          break;
        default:
          break;
      }
    });
  }, [setAppSetting, getToken]);

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <div className='App w-full min-h-[100vh] max-h-[100vh] bg-gray-200 font-sans font-light flex flex-col overflow-y-scroll'>
          <Header
            className='flex-none'
            links={[
              'SAFE-architect',
              'SAFE-risk',
              'product',
              'framework',
              //'business-need',
            ].concat(isAdminUser() ? ['manage-data'] : [])}
          />
          {hasAuth() ? (
            <section className='flex-1 relative bg-gray-200'>
              <Routes>
                <Route path='/' element={<HomePage />} />
                <Route path='SAFE-risk/*' element={<Safe />} />
                <Route path='SAFE-architect/*' element={<SafeArchitect />} />
                <Route path='product' element={<ProductPage />} />
                <Route path='framework' element={<FrameworkPage />} />
                {/* <Route path='business-need' element={<BusneedsPage />} /> */}
                {isAdminUser() && (
                  <Route path='manage-data/*' element={<ManageData />} />
                )}
                <Route path='/*' element={<Navigate replace to='/' />} />
              </Routes>
            </section>
          ) : (
            <section className='flex-1 flex items-center justify-center w-full h-full bg-gray-200'>
              <Card className='py-16 px-28 flex flex-col items-center justify-center'>
                {signinState === messages.signin && (
                  <Loader className='w-16 h-16'></Loader>
                )}
                <h1 className='text-2xl font-cisco text-gray-600 mt-4'>
                  {signinState}
                </h1>
                {signinState !== messages.signin && (
                  <Button className='mt-4' onClick={federatedSignin}>
                    Sign in
                  </Button>
                )}
              </Card>
            </section>
          )}
          <Toasts />
          {/* <Footer className='flex-none' /> */}
        </div>
      </BrowserRouter>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default App;
