import React, { ReactNode } from 'react';
import Loader from './Loader';

interface Props {
  ready: boolean;
  children: ReactNode;
  className?: string;
}

const AsyncContainer = ({ ready, children, className }: Props) => {
  return (
    <>
      {!ready && <Loader className={className} />}
      {ready && (
        <div
          className={`transition-opacity ${
            ready ? 'opacity-100' : 'opacity-0'
          }`}
        >
          {children}
        </div>
      )}
    </>
  );
};

export default AsyncContainer;
