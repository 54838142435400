import { Listbox, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import { Check, ChevronDown } from 'react-feather';
import appStyles from '../support/appStyles';

type Option = { [key: string]: unknown };
interface Props<T> {
  value?: T;
  defaultValue: T;
  options: T[];
  displayKey: string;
  selection: (option: T) => void;
  isSelected?: (option: T) => boolean;
  maxHeightCss?: string;
}

const HuiSelect = <T extends { [key: string]: unknown }>({
  value,
  defaultValue,
  options,
  displayKey,
  selection,
  isSelected,
  maxHeightCss,
}: Props<T>) => {
  const checkSelection = (option: T) =>
    isSelected
      ? isSelected(option)
      : JSON.stringify(option) === JSON.stringify(value);

  return (
    <Listbox value={value ?? defaultValue} onChange={selection}>
      <div className='w-full relative'>
        <Listbox.Button
          className={`${appStyles.input} flex pt-1 pb-1 w-full text-left bg-white`}
        >
          <span className='grow'>
            {(value ?? defaultValue)[displayKey] as string}
          </span>
          <span className='grow-0 self-center text-gray-600 ml-4'>
            <ChevronDown size='16px' />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave='transition ease-in duration-100'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <Listbox.Options
            className={`${appStyles.input} absolute w-full bg-white mt-1 pl-0 pr-0 z-10 ${maxHeightCss} overflow-y-auto`}
          >
            {(options ?? []).map((option, n) => (
              <div key={JSON.stringify(option) + n}>
                {(option[displayKey] as string) === '----' && (
                  <hr className='border-t-gray-300 ' />
                )}
                {(option[displayKey] as string) !== '----' && (
                  <Listbox.Option value={option}>
                    {({ active }) => (
                      <div
                        className={`px-2 py-1 cursor-pointer flex ${
                          checkSelection(option)
                            ? 'bg-sky text-white'
                            : active
                            ? 'bg-gray-200'
                            : 'bg-white'
                        }`}
                      >
                        <span className='grow'>
                          {option[displayKey] as string}
                        </span>
                        <Check
                          className={`grow-0 self-center mr-2 ${
                            checkSelection(option) ? 'visuble' : 'invisible'
                          }`}
                          size='16px'
                        />
                      </div>
                    )}
                  </Listbox.Option>
                )}
              </div>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};

export default HuiSelect;
export { Option };
