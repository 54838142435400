import React, { useEffect, useState } from 'react';
import { Controller, DefaultValues, useForm } from 'react-hook-form';
import appStyles from '../support/appStyles';
import { useQueryItems } from '../support/queries';
import AdminObjectForm from './AdminObjectForm';
import Architecture from './api-types/architecture';
import MultiSearchControl, {
  MTOptions,
} from './page-elements/MultiSearchControl';
interface Props {
  architecture?: Architecture;
  close: () => void;
  isOpen: boolean;
}

const displayCategory = 'Architecture';
const endpoint = 'architecture';

const AdminArchitectureForm = ({ architecture, close, isOpen }: Props) => {
  const capabilityData = useQueryItems('capability');
  const formConfig = useForm<Architecture>();
  const { register, reset, control, getValues } = formConfig;
  const isNew = (): boolean => !architecture?.id;
  const [capabilityOptions, setCapabilityOptions] = useState<
    {
      name: string;
      id: number;
    }[]
  >([]);

  const convertToApi = (data: Architecture): Architecture => {
    const apiData: Architecture = {
      ...architecture,
      ...data,
    };
    return apiData;
  };

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    const createDefaultValues = (
      a?: Architecture
    ): DefaultValues<Architecture> => ({
      id: a?.id,
      name: a?.name,
      capability: a?.capability,
    });
    reset(createDefaultValues(architecture));
  }, [reset, isOpen, architecture]);

  useEffect(() => {
    if (!capabilityData?.length || !!capabilityOptions.length) {
      return;
    }
    const options: MTOptions = [];
    capabilityData.forEach((d: { name: string; id: number }) =>
      options.push({ id: d.id, name: d.name })
    );
    setCapabilityOptions(options);
  }, [capabilityData, capabilityOptions]);

  return (
    <AdminObjectForm
      close={close}
      isOpen={isOpen}
      endpoint={endpoint}
      convertToApi={convertToApi}
      data={architecture ? { ...architecture } : undefined}
      displayCategory={displayCategory}
      formConfig={formConfig}
      isNew={() => isNew()}
    >
      <div className='flex-col flex mt-4'>
        <label className='text-sm'>Name</label>
        <input
          className={`${appStyles.input} text-lg py-1`}
          defaultValue={architecture ? getValues('name') : ''}
          {...register('name', { required: true })}
        />
      </div>
      <div className='flex-col flex mt-4'>
        <label className='text-sm'>Capabilities</label>
        <Controller
          control={control}
          name='capability'
          defaultValue={getValues().capability}
          render={({ field }) => (
            <MultiSearchControl
              options={capabilityOptions}
              onChange={field.onChange}
              value={field.value as MTOptions}
            />
          )}
        />
      </div>
    </AdminObjectForm>
  );
};

export default AdminArchitectureForm;
