export interface CapabilityIcon {
  domain: string;
  name: string;
  image: string;
}

const capabilityIcons = [
  {
    domain: 'Segmentation: Host',
    name: 'Account Use Policies',
    image: 'policy-configuration',
  },
  {
    domain: 'Visibility',
    name: 'Application User Behavior Analytics (UBA)',
    image: 'anomaly-detection',
  },
  {
    domain: 'Threat Defense: Cyber',
    name: 'Blacklist DNS / IP / URL Filtering',
    image: 'dns-security',
  },
  {
    domain: 'Segmentation: Physical',
    name: 'Bollards / Walls / Fence',
    image: 'firewall',
  },
  {
    domain: 'Management: Assets',
    name: 'Boot Code Integrity',
    image: 'data-integrity',
  },
  {
    domain: 'Threat Defense: Physical',
    name: 'Cameras / Motion Sensors / Visual Analytics',
    image: 'server-based-security',
  },
  {
    domain: 'Threat Defense: Physical',
    name: 'Cameras / Motion Sensors',
    image: 'server-based-security',
  },
  {
    domain: 'Management: Network Services',
    name: 'Cipher Management / PKI',
    image: 'certificate-services',
  },
  {
    domain: 'Secure Services',
    name: 'Cloud Access Security Broker (CASB)',
    image: 'casb',
  },
  {
    domain: 'Segmentation: Firewalling',
    name: 'Cloud Workload Protection / ADM',
    image: 'microsegmentation',
  },
  {
    domain: 'Segmentation: Physical',
    name: 'Conduit / Buried Cabling / Fiber',
    image: 'server-based-security',
  },
  {
    domain: 'Management: Governance',
    name: 'Configuration / Change Management',
    image: 'policy-configuration',
  },
  {
    domain: 'Management: Governance',
    name: 'Data Backup (Offline)',
    image: 'data-integrity',
  },
  {
    domain: 'Management: Governance',
    name: 'Data Backup (Online or Hot Replication)',
    image: 'data-integrity',
  },
  {
    domain: 'Visibility',
    name: 'Data Loss Prevention (DLP)',
    image: 'data-loss-prevention',
  },
  {
    domain: 'Threat Defense: Cyber',
    name: 'DDoS Security / Scrubbing',
    image: 'ddos-protection',
  },
  {
    domain: 'Threat Defense: Cyber',
    name: 'Deception / Honeynets ',
    image: 'federation',
  },
  {
    domain: 'Management: Assets',
    name: 'Device Image Management',
    image: 'logging-reporting',
  },
  {
    domain: 'Management: Assets',
    name: 'Device Lifecycle Management',
    image: 'policy-configuration',
  },
  {
    domain: 'Segmentation: Physical',
    name: 'Door Access Controls',
    image: 'identity',
  },
  { domain: 'Secure Services', name: 'Email Anti-Spam', image: 'anti-spam' },
  {
    domain: 'Segmentation: Encryption',
    name: 'Email Encryption',
    image: 'email-encryption',
  },
  {
    domain: 'Secure Services',
    name: 'Email Security',
    image: 'email-security',
  },
  {
    domain: 'Management: Building Services',
    name: 'Emergency Systems / HVAC',
    image: 'server-based-security',
  },
  {
    domain: 'Threat Defense: Cyber',
    name: 'Encrypted Traffic Analytics (ETA)',
    image: 'flow-analytics',
  },
  {
    domain: 'Threat Defense: Cyber',
    name: 'Network Traffic Analytics (NTA)',
    image: 'flow-analytics',
  },
  {
    domain: 'Threat Defense: Cyber',
    name: 'Endpoint Anti-Malware',
    image: 'anti-virus',
  },
  {
    domain: 'Segmentation: Host',
    name: 'Endpoint Browser Security / Isolation',
    image: 'api-interface',
  },
  {
    domain: 'Threat Defense: Cyber',
    name: 'Endpoint Detection & Response',
    image: 'file-trajectory',
  },
  {
    domain: 'Visibility',
    name: 'Endpoint Device Profiling (Asset Discovery)',
    image: 'device-profiling',
  },
  {
    domain: 'Segmentation: Encryption',
    name: 'Endpoint Disk Encryption',
    image: 'disc-encryption',
  },
  {
    domain: 'Threat Defense: Cyber',
    name: 'Endpoint Exploit Prevention / HIPS',
    image: 'anti-malware',
  },
  {
    domain: 'Management: Governance',
    name: 'Endpoint Posture Assessment',
    image: 'posture-assessment',
  },
  {
    domain: 'Management: Assets',
    name: 'Endpoint Privacy Screens',
    image: 'client-based-security',
  },
  {
    domain: 'Visibility',
    name: 'Endpoint RFID / Location Tracking',
    image: 'device-trajectory',
  },
  {
    domain: 'Segmentation: Firewalling',
    name: 'Endpoint Stateful Firewall',
    image: 'firewall',
  },
  {
    domain: 'Management: Assets',
    name: 'Enterprise Mobility Management/MDM',
    image: 'mobile-device-management',
  },
  {
    domain: 'Segmentation: Host',
    name: 'File / Directory Permissions',
    image: 'file-trajectory',
  },
  {
    domain: 'Threat Defense: Physical',
    name: 'Guards',
    image: 'micro-segmentation',
  },
  {
    domain: 'Management: Governance',
    name: 'Identity Management / 2FA',
    image: 'multi-factor-authentication',
  },
  {
    domain: 'Visibility',
    name: 'Incident Response Tool / SOAR',
    image: 'soar-xdr',
  },
  {
    domain: 'Visibility',
    name: 'Inventory Management',
    image: 'mobile-device-management',
  },
  {
    domain: 'Threat Defense: Cyber',
    name: 'Malware / URL Sandboxing',
    image: 'malware-sandbox',
  },
  {
    domain: 'Segmentation: Physical',
    name: 'Mantraps / Cages / Locks',
    image: 'micro-segmentation',
  },
  {
    domain: 'Segmentation: Firewalling',
    name: 'Network Access Control',
    image: 'identity',
  },
  {
    domain: 'Segmentation: Firewalling',
    name: 'Network Application/Stateful Firewall',
    image: 'firewall',
  },
  {
    domain: 'Visibility',
    name: 'Network Detection & Remediation (NDR)',
    image: 'anomaly-detection',
  },
  {
    domain: 'Management: Network Services',
    name: 'Network DNS Security',
    image: 'dns-security',
  },
  {
    domain: 'Threat Defense: Cyber',
    name: 'Network Intrusion Prevention System (NIPS)',
    image: 'intrusion-prevention',
  },
  {
    domain: 'Threat Defense: Cyber',
    name: 'Network Malware Filtering',
    image: 'network-anti-malware',
  },
  { domain: 'Visibility', name: 'Network Packet Capture', image: 'monitoring' },
  {
    domain: 'Threat Defense: Cyber',
    name: 'Network SSL Decrypt',
    image: 'tls-offload',
  },
  {
    domain: 'Management: Network Services',
    name: 'Network Switch / Router Security',
    image: 'server-based-security',
  },
  {
    domain: 'Management: Assets',
    name: 'Operating System Security',
    image: 'client-based-security',
  },
  {
    domain: 'Management: Governance',
    name: 'Operational / Audit Logging',
    image: 'logging-reporting',
  },
  {
    domain: 'Segmentation: Physical',
    name: 'Personal Protective Equipment (PPE)',
    image: 'anti-virus',
  },
  {
    domain: 'Threat Defense: Cyber',
    name: 'Rate-Limiting / RTBH Filtering',
    image: 'firewall',
  },
  {
    domain: 'Management: Governance',
    name: 'Redundancy / DR Planning',
    image: 'policy-configuration',
  },
  {
    domain: 'Management: Assets',
    name: 'Restrict Local Admin Rights',
    image: 'client-based-security',
  },
  {
    domain: 'Management: Network Services',
    name: 'Secure Active Directory Configuration',
    image: 'client-based-security',
  },
  {
    domain: 'Management: Assets',
    name: 'Secure Cloud Compute Configuration',
    image: 'cloud-security',
  },
  {
    domain: 'Management: Governance',
    name: 'Secure Development Lifecycle / Trusted Vendor',
    image: 'policy-configuration',
  },
  {
    domain: 'Visibility',
    name: 'SIEM / Security Alert Logging',
    image: 'analysis-correlation',
  },
  {
    domain: 'Secure Services',
    name: 'Social Media Security',
    image: 'cloud-security',
  },
  {
    domain: 'Management: Assets',
    name: 'Thin Client / Non-Persistent Disk',
    image: 'client-based-security',
  },
  {
    domain: 'Management: Governance',
    name: 'Threat Intelligence',
    image: 'threat-intelligence',
  },
  {
    domain: 'Management: Network Services',
    name: 'Time Synchronization',
    image: 'time-synchronization',
  },
  {
    domain: 'Segmentation: Encryption',
    name: 'Traffic Encryption / HTTPS / VPN',
    image: 'vpn',
  },
  {
    domain: 'Management: Governance',
    name: 'Training / Security Awareness Campaigns',
    image: 'policy-configuration',
  },
  {
    domain: 'Management: Assets',
    name: 'USB / Media Port Security',
    image: 'client-based-security',
  },
  {
    domain: 'Management: Governance',
    name: 'Vulnerability / Patch Management',
    image: 'vulnerability-management',
  },
  {
    domain: 'Secure Services',
    name: 'Web Application Firewall (WAF)',
    image: 'web-application-firewall',
  },
  {
    domain: 'Secure Services',
    name: 'Web Content Filtering',
    image: 'web-reputation-filtering-dcs',
  },
  {
    domain: 'Secure Services',
    name: 'Web Security Proxy',
    image: 'web-security',
  },
  {
    domain: 'Secure Services',
    name: 'Wireless IPS (WIPS) / RRM',
    image: 'wireless-intrusion-prevention-system',
  },
];

const capabilityIconsS3 = [
  'API Interface',
  'Analysis_Correlation',
  'Anomaly Detection',
  'AntiMalware',
  'AntiSpam',
  'AntiVirus',
  'App Visibility Control',
  'Block',
  'Cage',
  'Central Management',
  'Certificate Authority',
  'Certificate Services',
  'ClientBased Security',
  'Cloud Access Security Broker_CASB',
  'Cloud Security',
  'Conduit',
  'Conference Bridge',
  'DNS Security',
  'Data Integrity',
  'Data Loss Prevention',
  'Database',
  'Device Encryption',
  'Device Profiling',
  'Device Trajectory',
  'Disk Encryption',
  'Distributed Denial of Service Protection',
  'Email Encryption',
  'Email Security',
  'FSO',
  'Fabric Switching',
  'Federation',
  'Fence',
  'File Trajectory',
  'Firewall Virtual',
  'Firewall',
  'Flow Analytics',
  'Guard',
  'HVAC',
  'Host Context',
  'Identity',
  'Intrusion Detection',
  'Intrusion Prevention',
  'L2 Switching',
  'L2 Switching_Virtual',
  'L2-L3 Network',
  'L2-L3 Network_Virtual',
  'L3 Switching',
  'Load Balancer',
  'Logging_Reporting',
  'Malware Sandbox',
  'Microsegmentation',
  'Mobile Device Management',
  'Monitoring',
  'Multi-Factor Authentication',
  'Name Resolution',
  'Network Anti Malware',
  'PPE',
  'Policy Configuration',
  'Posture Assessment',
  'Quarantine',
  'Remediate',
  'Remote Access',
  'RoomAccess',
  'Routing',
  'SOAR_XDR',
  'Secure API Gateway',
  'Secure File Share',
  'Secure X',
  'ServerBased Security',
  'Storage',
  'Switch',
  'TLS Offload',
  'Tagging',
  'Threat Intelligence',
  'Time Synchronization',
  'USB-Security',
  'User',
  'VDC_VPC_OTV_Microsegmentation',
  'VPN Concentrator',
  'Video',
  'VideoCamera',
  'Virtual Private Network',
  'Virtualized Capabilities',
  'Voice',
  'Vulnerability Management',
  'Web Application Firewall',
  'Web Reputation Filtering_DCS',
  'Web Security',
  'Wireless Connection',
  'Wireless Intrusion Detection System',
  'Wireless Intrusion Prevention System',
  'Wireless Rogue Detection',
];

// export default capabilityIcons;
export { capabilityIconsS3 };
