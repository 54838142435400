import React from 'react';
import { Check, List } from 'react-feather';
import Button from '../complib/Button';
import Card from '../complib/Card';
import appStyles from '../support/appStyles';
import {
  BctSettings,
  useBctRiskSelections,
  useSafeSettingsSelections,
} from '../support/stores';
import { sanitisedNumber } from '../support/utils';

interface Props {
  bcts: BctSettings[];
  showAll: boolean;
}

const bctKey = (bct: BctSettings) => bct.id;

const BCTPanel = ({ bcts, showAll }: Props) => {
  const {
    selectItem,
    deselectItem,
    setItemSetting,
    getItemSetting,
    isInitialized,
  } = useBctRiskSelections();
  const { getSetting: getSafeSetting, setSetting: setSafeSetting } =
    useSafeSettingsSelections();

  const currentBct = () => getSafeSetting('currentBct', 0) as number;
  const setCurrentBct = (id: number | null) => setSafeSetting('currentBct', id);

  const bctIsSelected = (key: number): boolean => {
    if (!isInitialized()) {
      return false;
    }
    return getItemSetting(key, 'active') as boolean;
  };

  const toggleSelectBct = (key: number): void => {
    if (getItemSetting(key, 'active') as boolean) {
      deselectItem(key);
      if (currentBct() && currentBct() === key) {
        setCurrentBct(null);
      }
      return;
    }
    selectItem(key);
  };

  const setBctScore = (
    e: React.ChangeEvent<HTMLInputElement>,
    bct: BctSettings
  ) => {
    setItemSetting(bctKey(bct), 'score', sanitisedNumber(e.target.value));
  };

  return (
    <>
      <div className='flex text-sm'>
        <h3 className='flex-1 mx-2'>Selected / BCT</h3>
        <h3 className='w-16'>Threats</h3>
        <h3 className='w-16'>Edit</h3>
        <h3 className='w-16'>Priority</h3>
      </div>
      <Card className='mt-1 p-0 mb-6'>
        {bcts
          ?.filter((bct: BctSettings) => showAll || bct.active)
          .map((bct: BctSettings) => (
            <div
              className={`transition-colors rounded-sm bg-gray-200 flex ${
                currentBct() === bctKey(bct)
                  ? 'bg-sky text-white'
                  : 'bg-white text-gray-800'
              } ${
                bctIsSelected(bctKey(bct)) ? '' : 'bg-gray-100'
              } border-b border-gray-300 last:border-none`}
              key={bctKey(bct)}
            >
              <Check
                className={`flex-0 rounded-full p-1 m-2 transition-colors cursor-pointer ${
                  bctIsSelected(bctKey(bct))
                    ? 'bg-success text-white'
                    : 'bg-gray-400 text-gray-400'
                } mr-2 cursor-pointer`}
                onClick={() => toggleSelectBct(bctKey(bct))}
              ></Check>
              <h3
                className={`font-cisco text-lg flex-1 py-2 ${
                  bctIsSelected(bctKey(bct)) ? '' : 'text-gray-500'
                }`}
              >
                {bct.name}
              </h3>
              {bctIsSelected(bctKey(bct)) && (
                <>
                  <div className='bg-warning min-w-8 text-center text-white rounded-full mt-[11px] font-bold px-2 ml-2 self-start'>
                    {getItemSetting(bctKey(bct), 'count') as number}
                  </div>
                  <Button
                    className='self-start m-2 text-gray-800'
                    theme='ghost'
                    onClick={(e) => {
                      e.stopPropagation();
                      setCurrentBct(bctKey(bct));
                    }}
                  >
                    <List size={20} />
                  </Button>
                  <input
                    type='number'
                    min='0'
                    max='100'
                    value={getItemSetting(bctKey(bct), 'score', 100) as number}
                    onChange={(e) => setBctScore(e, bct)}
                    className={`${appStyles.input} my-2 mr-2 self-start w-16 pr-0 font-bold text-gray-800 text-lg`}
                  />
                </>
              )}
            </div>
          ))}
      </Card>
    </>
  );
};

export default BCTPanel;
