import * as d3 from 'd3';
import { ProductType } from '../../support/stores';
import {
  CapabilityBadge,
  capImageSize,
  colors,
  endDir,
  isSpecialPin,
  lines,
} from './chart-constants';
import { d3arc, splitLines, svgRect } from './chart-helpers';
import drawCapabilityIcon from './draw-capability-icon';
import { FlowType } from './SafeFlowChart';

const drawSafeFlow = (
  anchor: React.MutableRefObject<SVGSVGElement | null>,
  chartSettings: {
    cWidth: number;
    cHeight: number;
    color?: string;
    capabilities: { [key: string]: CapabilityBadge[] };
    start: string;
    end: string;
    startName: string;
    endName: string;
    maxPerPin: number;
    scoreMax: number;
    showProducts: boolean;
    showAllPins: boolean;
    flowType: FlowType;
  }
) => {
  const { cWidth, cHeight, start, end, capabilities } = chartSettings;
  const pathColor = chartSettings.color as string;
  const svgSelect = d3.select(anchor.current);

  // x and y scaling functions (dynamic with viewport / svg size)
  const x = d3.scaleLinear().domain([0, 1280]).range([0, cWidth]);
  const y = d3.scaleLinear().domain([0, 800]).range([cHeight, 0]);

  const drawBadge = (
    loc: [number, number],
    capability: CapabilityBadge,
    scale = 1,
    custom?: true
  ) => {
    const radius = x((scale * capImageSize) / 2);

    // capability border
    svgSelect
      .append('circle')
      .attr('fill', capability.border)
      //.attr('r', radius * 1.22)
      // wider borders: make status clearer
      .attr('r', radius * 1.3)
      .attr('cx', x(loc[0]))
      .attr('cy', y(loc[1]));

    if (custom) {
      // image file: endpoints
      svgSelect
        .append('image')
        .attr('xlink:href', capability.image)
        .attr('width', radius * 2)
        .attr('height', radius * 2)
        .attr('x', x(loc[0]) - radius)
        .attr('y', y(loc[1]) - radius);
    } else {
      drawCapabilityIcon(
        svgSelect,
        capability.image,
        x(loc[0]) - radius,
        y(loc[1]) - radius,
        2 * radius
      );
    }

    // capability text: limited length lines to avoid overlapping titles
    const lines: string[] = splitLines(capability.name, 13);
    lines.forEach((c, i) => {
      svgSelect
        .append('text')
        .attr('x', x(loc[0]))
        .attr('y', y(loc[1]))
        .attr('dy', radius + x((i + 1.75) * scale * capImageSize * 0.25))
        .attr('text-anchor', 'middle')
        .style('font-size', `${x((scale * capImageSize) / 5)}px`)
        .attr('fill', 'white')
        .text(c);
    });
  };

  const drawLine = (yloc: number) => {
    svgSelect
      .append('path')
      .attr(
        'd',
        d3.line()([
          [x(lines.left) - 2, y(yloc)],
          [x(lines.right) + 2, y(yloc)],
        ])
      )
      .attr('stroke', pathColor)
      .attr('stroke-width', x(capImageSize / 4));
  };

  const arc = (start: number) =>
    d3arc(start, x(lines.gap / 2), x(capImageSize / 4));
  const drawArc = (xloc: number, yloc: number, start: number) => {
    svgSelect
      .append('path')
      .attr('d', arc(start) as any)
      .attr('transform', `translate(${x(xloc)}, ${y(yloc)})`)
      .attr('fill', pathColor);
  };

  const drawPin = (
    name: string,
    range: [number, number],
    lineY: number,
    badgeScale: number,
    gap: number
  ) => {
    const scale = capImageSize * badgeScale;
    const color = isSpecialPin(name) ? 'white' : pathColor;
    // pin border
    svgRect(svgSelect, {
      x: x(Math.min(range[0], range[1]) - gap / 2.2),
      y: y(lineY + scale * 1.5),
      width: x(Math.abs(range[1] - range[0]) + gap / 1.1),
      height: x(scale * 3.6),
      radius: x(10),
      fill: 'none',
    })
      .attr('stroke-dasharray', `${x(3)} ${x(4)}`)
      .attr('stroke-width', x(2))
      .attr('stroke', color);

    // endpoint text
    svgSelect
      .append('text')
      .attr('x', x(range[1] + range[0]) / 2)
      .attr('y', y(lineY + scale * 1.15))
      .attr('text-anchor', 'middle')
      .style('font-size', `${x(scale / 5)}px`)
      .attr('fill', color)
      .text(name.toLocaleUpperCase());
  };

  const drawProductBox = (
    name: string,
    range: [number, number],
    lineY: number,
    badgeScale: number,
    gap: number,
    type: 'cisco' | '3rd-party'
  ) => {
    const scale = capImageSize * badgeScale;

    // product box
    svgRect(svgSelect, {
      x: x(Math.min(range[0], range[1]) - gap / 2.2),
      y: y(lineY + scale * 1.5),
      width: x(Math.abs(range[1] - range[0]) + gap / 1.1),
      height: x(scale * 3.6),
      radius: x(10),
      fill: type === 'cisco' ? colors.darkBlue : colors.green,
    }).style('opacity', type === 'cisco' ? 0.75 : 0.4);

    // product text
    const lines: string[] = splitLines(
      name,
      15 + Math.abs(range[1] - range[0]) / 45
    );
    lines.forEach((l, i) => {
      const textEl = svgSelect
        .append('text')
        .attr('x', x(range[1] + range[0]) / 2)
        .attr(
          'y',
          y(lineY + scale * (0.9 - 0.2 * i - (lines.length < 2 ? 0.1 : 0)))
        )
        .attr('text-anchor', 'middle')
        .style('font-size', `${x(scale / 6)}px`)
        .attr('fill', type === 'cisco' ? colors.lightBlue : colors.brightGreen)
        .text(l);
      textEl.style('font-style', 'italic');
    });
  };

  // clear previous svg elements
  svgSelect.selectAll('*').remove();

  // background
  svgSelect
    .append('rect')
    .attr('width', cWidth)
    .attr('height', cHeight)
    .attr('fill', '#212529');

  // scale, choose path section
  const capabilitiesTotal = Object.values(capabilities).flat(1).length;
  const rowLimit = Math.max(Math.ceil(capabilitiesTotal / 3), 8);
  const gap = 960 / rowLimit;
  const badgeScale = Math.max(0.8, 1.4 - Math.max(0, (rowLimit - 6) / 15));
  let position = 0;
  const getLocation = (): [number, number] => {
    const current = position;
    position++;
    if (current < rowLimit) {
      return [lines.left + gap + gap * current, lines.top];
    }
    if (current < rowLimit * 2) {
      return [
        lines.right - gap * 0.5 - gap * (current - rowLimit),
        lines.middle,
      ];
    }
    return [lines.left + gap * (current - 2 * rowLimit), lines.bottom];
  };

  // drawing data
  const pins: {
    [key: string]: { name: string; start: number; end: number; lineY: number };
  } = {};
  const products: {
    name: string;
    start: number;
    end: number;
    lineY: number;
    type: 'cisco' | '3rd-party';
    position: number[];
  }[] = [];
  const capBadges: {
    loc: [number, number];
    cb: CapabilityBadge;
    scale: number;
  }[] = [];

  // generate endpoint, product, capability, drawing data
  Object.keys(capabilities).forEach((pinId: string) => {
    let caps = capabilities[pinId];
    caps = capabilities[pinId].sort(
      (a: CapabilityBadge, b: CapabilityBadge) => {
        const cA = (a.products?.map((p) => p.name.toLocaleLowerCase()) ?? [])
          .sort()
          .join();
        const cB = (b.products?.map((p) => p.name.toLocaleLowerCase()) ?? [])
          .sort()
          .join();
        if (cA === '') {
          return 1;
        }
        if (cB === '') {
          return -1;
        }
        return cA > cB ? 1 : cB > cA ? -1 : 0;
      }
    );
    caps.forEach((c) => {
      const location = getLocation();
      const lineY = location[1].toString();
      if (chartSettings.showAllPins || isSpecialPin(pinId)) {
        if (!pins[pinId + lineY]) {
          pins[pinId + lineY] = {
            start: location[0],
            end: 0,
            lineY: 0,
            name: pinId,
          };
          pins[pinId + lineY].lineY = location[1];
        }
        pins[pinId + lineY].end = location[0];
      }
      // add product box: check whether to extend an adjacent box (cover multiple capabilities)
      // 'proposed' flow type adds a 3rd-party product to any capabilities without assigned product
      if (chartSettings.showProducts) {
        const cProduct = c.products?.length
          ? c.products[0]
          : chartSettings.flowType === 'proposed'
          ? {
              type: '3rd-party' as ProductType,
              name: 'Third Party Product',
              active: true,
            }
          : null;
        if (cProduct) {
          let previous = null;
          for (let i = products.length - 1; i > -1; i--) {
            if (
              products[i].name === cProduct.name &&
              products[i].lineY === location[1] &&
              products[i].position[products[i].position.length - 1] ===
                position - 1
            ) {
              previous = products[i];
              break;
            }
          }
          if (previous) {
            previous.end = location[0];
            previous.position.push(position);
          } else {
            products.push({
              name: cProduct.name,
              start: location[0],
              end: location[0],
              lineY: location[1],
              type: cProduct.type,
              position: [position],
            });
          }
        }
      }
      capBadges.push({ loc: location, cb: c, scale: badgeScale });
    });
  });

  // products
  products.forEach((product) => {
    drawProductBox(
      product.name,
      [product.start, product.end],
      product.lineY,
      badgeScale,
      gap,
      product.type
    );
  });

  // path
  drawLine(lines.top);
  drawLine(lines.middle);
  drawLine(lines.bottom);
  drawArc(lines.right, (lines.top + lines.middle) / 2, 0);
  drawArc(lines.left, (lines.bottom + lines.middle) / 2, Math.PI);

  // capabilities
  capBadges.forEach((cb) => drawBadge(cb.loc, cb.cb, cb.scale));
  Object.keys(pins).forEach((key: string) => {
    const point = pins[key];
    drawPin(point.name, [point.start, point.end], point.lineY, badgeScale, gap);
  });

  //endpoints
  drawBadge(
    [lines.left - capImageSize * 0.8, lines.top],
    {
      image: `${endDir}${start}.png`,
      name: chartSettings.startName,
      border: pathColor,
    },
    1.5,
    true
  );
  drawBadge(
    [lines.right + capImageSize * 0.8, lines.bottom],
    {
      image: `${endDir}${end}.png`,
      name: chartSettings.endName,
      border: pathColor,
    },
    1.5,
    true
  );
};

export default drawSafeFlow;
