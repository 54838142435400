import React, { useEffect, useState } from 'react';
import AsyncContainer from '../complib/AsyncContainer';
import CapabilityStandardFilters from '../safe/CapabilityStandardFilters';
import { capabilityInStandardFilters, SortConfig } from '../safe/safe-utils';
import TableSortHeading from '../safe/TableSortHeading';
import { useQueryItems } from '../support/queries';
import {
  useMaturitySelections,
  usePinFilterSelections,
  useSafeSettingsSelections,
  useSecurityFunctionSelections,
} from '../support/stores';
import { Capability } from '../types/capability';
import { Pin } from '../types/pin';
import AdminCapabilityForm from './AdminCapabilityForm';
import { compareItems, defaultSortConfig, updateSort } from './helpers';
import AdminListPage from './page-elements/AdminListPage';
import CapabilityIcon from './page-elements/CapabilityIcon';
import NoneShowing from './page-elements/NoneShowing';

const styles = {
  colDomain: 'flex-0 w-52 mr-2',
  colIcon: 'w-8 mr-2 flex items-center',
  colName: 'flex-1 mr-2 min-w-[16rem] my-2',
  colPin: 'flex-0 w-[30rem] flex items-center mr-2',
  colThreat: 'flex-0 w-16 mr-2',
  colProduct: 'flex-0 w-56 flex items-center mr-2',
  colUpdate: 'flex-0 w-52 flex items-center',
};

const AdminCapabilityPage = () => {
  const pinData = useQueryItems('pin', true);
  const capabilities = useQueryItems('capability', true);
  const [filteredCapabilities, setFilteredCapabilities] =
    useState<Capability[]>();
  const [sortConfig, setSortConfig] = useState<SortConfig>(defaultSortConfig);
  const { settings: safeSettings } = useSafeSettingsSelections();
  const { selections: pinSelections } = usePinFilterSelections();
  const { selections: maturitySelections } = useMaturitySelections();
  const { selections: securityFunctionSelections } =
    useSecurityFunctionSelections();
  const [openForm, setOpenForm] = useState<boolean>(false);
  const [selectedCapability, setSelectedCapability] = useState<Capability>();

  const closeForm = () => {
    setSelectedCapability(undefined);
    setOpenForm(false);
  };

  useEffect(() => {
    setFilteredCapabilities(
      (capabilities ?? []).filter(capabilityInStandardFilters)
    );
  }, [
    capabilities,
    setFilteredCapabilities,
    safeSettings,
    pinSelections,
    maturitySelections,
    securityFunctionSelections,
  ]);

  const changeSort = (key: string) =>
    setSortConfig(updateSort(sortConfig, key));

  const compareCapabilities = (a: Capability, b: Capability): number =>
    compareItems(a, b, sortConfig);

  const selectCapability = (cap?: Capability): void => {
    setSelectedCapability(cap);
    setOpenForm(true);
  };

  return (
    <>
      <AdminListPage
        itemLabel='capability'
        itemsLabel='capabilitie'
        addAction={selectCapability}
        filters={<CapabilityStandardFilters />}
        header={
          <>
            {!!filteredCapabilities?.length && (
              <div className='flex text-left text-sm mb-2'>
                <>
                  <TableSortHeading
                    updateSort={changeSort}
                    sort={sortConfig}
                    sortKey='domain'
                    className={styles.colDomain}
                  >
                    Domain
                  </TableSortHeading>
                  <div className={styles.colIcon}>Icon</div>
                  <TableSortHeading
                    updateSort={changeSort}
                    sort={sortConfig}
                    sortKey='name'
                    className={styles.colName}
                  >
                    Capability
                  </TableSortHeading>
                  <div className={styles.colPin}>PIN</div>
                  <TableSortHeading
                    updateSort={changeSort}
                    sort={sortConfig}
                    sortKey='risk'
                    className={styles.colThreat}
                  >
                    Threats
                  </TableSortHeading>
                  <div className={styles.colProduct}>Products</div>
                  <div className={styles.colUpdate}>Updated</div>
                </>
              </div>
            )}
          </>
        }
        list={
          <>
            <AsyncContainer ready={capabilities} className='m-4'>
              {filteredCapabilities
                ?.sort(compareCapabilities)
                .map((cap: Capability) => (
                  <div
                    key={cap.id}
                    className={`flex items-center border-gray-300 border-t px-4 text-sm`}
                  >
                    <div className={styles.colDomain}>{cap.domain}</div>
                    <div className={`${styles.colIcon} my-1`}>
                      <CapabilityIcon
                        icon={cap.image ?? cap.name}
                        scale={0.25}
                      />
                    </div>
                    <h3
                      className={`${styles.colName} font-semibold hover:underline cursor-pointer`}
                      onClick={() => selectCapability(cap)}
                    >
                      {cap.name}
                    </h3>
                    <div className={styles.colPin}>
                      {(pinData ?? [])
                        .sort((a: Pin, b: Pin) => (a.order > b.order ? -1 : 1))
                        .filter((p: Pin) =>
                          cap.pin.some((cp) => cp.name === p.name)
                        )
                        .map((p: Pin) => p.name)
                        .join(', ')}
                    </div>
                    <div
                      className={styles.colThreat}
                      title={cap.risk.map((r) => r.name).join(', ')}
                    >
                      <div className='bg-warning min-w-8 text-center text-white rounded-full font-bold px-2 py-[2px] text-lg'>
                        {cap.risk.length}
                      </div>
                    </div>
                    <div className={styles.colProduct}>
                      {cap.product.map((p) => p.name).join(', ')}
                    </div>
                    <div className={styles.colUpdate}>{cap.update ?? '-'}</div>
                  </div>
                ))}
              <NoneShowing show={!filteredCapabilities?.length} />
            </AsyncContainer>
            {openForm && (
              <AdminCapabilityForm
                capability={selectedCapability}
                isOpen={openForm}
                close={closeForm}
              />
            )}
          </>
        }
      />
    </>
  );
};

export default AdminCapabilityPage;
