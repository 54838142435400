import React, { useEffect, useState } from 'react';
import { Check } from 'react-feather';
import SearchInput from '../../complib/SearchInput';
import RiskScoreSelector, {
  BCTRiskSeverity,
} from '../../safe/RiskScoreSelector';
import appStyles from '../../support/appStyles';
import { useQueryItems } from '../../support/queries';
import { Risk } from '../../types/risk';
import { highlitContent } from '../helpers';

type BCTRiskConfig = {
  id: number;
  name: string;
  severity: BCTRiskSeverity;
  selected: boolean;
};

type BCTRisks = { [risk: string]: BCTRiskConfig };

interface Props {
  value: BCTRisks;
  onChange: (risks: BCTRisks) => void;
}

const BustechRiskSelector = ({ value, onChange }: Props) => {
  const [search, setSearch] = useState<string>('');
  const [filteredRisks, setFilteredRisks] = useState<Risk[]>();
  const riskData = useQueryItems('risk');

  useEffect(() => {
    const term = search.toLocaleLowerCase().trim();
    setFilteredRisks(
      riskData
        .filter(
          (risk: Risk) =>
            search.length < 1 ||
            risk.impact.toLocaleLowerCase().trim().includes(term) ||
            risk.name.toLocaleLowerCase().includes(term)
        )
        .sort((a: Risk, b: Risk) => {
          if (a.impact === b.impact) {
            return a.name > b.name ? 1 : -1;
          }
          return a.impact > b.impact ? 1 : -1;
        })
    );
  }, [search, riskData]);

  const updateRiskScore = (risk: Risk, score: BCTRiskSeverity): void => {
    const newValue = { ...value };
    newValue[risk.id] = { ...value[risk.id] };
    newValue[risk.id].severity = score;
    onChange(newValue);
  };

  const updateSelected = (risk: Risk): void => {
    const newValue = { ...value };
    newValue[risk.id] = { ...newValue[risk.id] };
    newValue[risk.id].selected = !newValue[risk.id].selected;
    onChange(newValue);
  };

  return (
    <>
      <SearchInput
        value={search}
        changeEvent={(term) => setSearch(term)}
        placeholder='Search threats&hellip;'
        className=''
      />
      <div className={`${appStyles.input} bg-white max-h-64 overflow-y-scroll`}>
        {filteredRisks &&
          filteredRisks.map((risk: Risk) => (
            <div
              key={risk.id}
              className={`grid grid-cols-[164px_32px_minmax(200px,_1fr)_242px] text-sm py-1 text-sec-dark-gray ${
                value[risk.id].selected ? '' : 'opacity-60 bg-sec-light-gray'
              }`}
            >
              <div className=''>
                <div className='inline text-xs uppercase py-[2px] px-2 bg-sec-light-gray rounded-sm'>
                  {highlitContent(risk.impact, search)}
                </div>
              </div>
              <Check
                className={`flex-0 rounded-full p-[2px] mx-1 transition-colors cursor-pointer ${
                  value[risk.id].selected
                    ? 'bg-sec-green text-white hover:bg-success-darker'
                    : 'bg-gray-400 text-gray-400 hover:bg-gray-500'
                } mr-2 cursor-pointer w-5 h-5 flex-0`}
                onClick={() => updateSelected(risk)}
              ></Check>
              <div
                className='font-semibold cursor-pointer hover:underline'
                onClick={() => updateSelected(risk)}
              >
                {highlitContent(risk.name, search)}
              </div>
              <div>
                {value[risk.id].selected && (
                  <RiskScoreSelector
                    setScore={(score: BCTRiskSeverity) =>
                      updateRiskScore(risk, score)
                    }
                    getCurrentRiskScore={() => value[risk.id].severity}
                  />
                )}
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

export default BustechRiskSelector;
export { BCTRiskConfig, BCTRisks };
