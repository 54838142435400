import { SortConfig, SortDir } from '../safe/safe-utils';
import { NameObject } from '../types/item';
import React from 'react';
import Highlighter from 'react-highlight-words';

const defaultSortConfig: SortConfig = {
  key: 'name',
  dir: 1,
};
const updateSort = (config: SortConfig, key: string) => {
  let dir = key === 'risk' ? -1 : 1;
  if (key === config.key) {
    dir = -config.dir;
  }
  return { key, dir: dir as SortDir };
};
const compareItems = <T extends NameObject>(
  a: T,
  b: T,
  config: SortConfig
): number => {
  type TKey = keyof T;
  const dir = config.dir;
  const aComp = a[config.key as 'name' | TKey] ?? ('' as number | string);
  const bComp = b[config.key as 'name' | TKey] ?? ('' as number | string);
  if (aComp === bComp) {
    return a.name > b.name ? dir : -dir;
  }
  return aComp > bComp ? dir : -dir;
};

const escapeRegexCharacters = (input: string): string =>
  input.replace(/[[\]{}()*+?.,\\^$|#\s]/g, (match) => `\\${match}`);

const highlitContent = (content = '', search = ''): React.ReactNode => {
  const term = search.toLocaleLowerCase().trim();
  return !term?.length
    ? content
    : React.createElement(
        Highlighter as React.ComponentClass<{
          highlightClassName: string;
          textToHighlight: string;
          searchWords: string[];
        }>,
        {
          highlightClassName: 'bg-sec-orange rounded-sm py-1',
          textToHighlight: content ?? '',
          searchWords: [escapeRegexCharacters(term)],
        }
      );
};

export { defaultSortConfig, updateSort, compareItems, highlitContent };
