import React, { useEffect, useState } from 'react';
import { Controller, DefaultValues, useForm } from 'react-hook-form';
import HuiSelect from '../complib/HuiSelect';
import appStyles from '../support/appStyles';
import { useQueryItems } from '../support/queries';
import { Bustech } from '../types/bustech';
import { Capability } from '../types/capability';
import AdminObjectForm, { DeleteRelationships } from './AdminObjectForm';

interface RiskValues {
  id?: number;
  name: string;
  impact: string;
}

interface Props {
  risk?: RiskValues;
  close: () => void;
  isOpen: boolean;
}

/* hardcoding */
/* will later need an admin page and related endpoints */
const impactOptions = [
  { name: 'Data Loss' },
  { name: 'Financial Loss' },
  { name: 'Reputation Loss' },
  { name: 'Service Disruption' },
  { name: 'Unauthorized Access' },
];

const displayCategory = 'Threat';
const endpoint = 'risk';

const AdminRiskForm = ({ risk, close, isOpen }: Props) => {
  const bctData = useQueryItems('bustech');
  const capabilityData = useQueryItems('capability');
  const formConfig = useForm<RiskValues>();
  const { register, reset, control, getValues } = formConfig;
  const isNew = (): boolean => !risk?.id;
  const [relationships, setRelationships] = useState<DeleteRelationships[]>([]);

  const convertToApi = (data: RiskValues): RiskValues => {
    const apiData: RiskValues = {
      ...risk,
      ...data,
    };
    return apiData;
  };

  useEffect(() => {
    const id = risk?.id;
    if (!id || !bctData || !capabilityData) {
      return;
    }
    const bcts: string[] = [];
    bctData.forEach((bct: Bustech) => {
      if (bct.risk.some((risk) => risk.riskid === id)) {
        bcts.push(bct.name);
      }
    });
    const caps: string[] = [];
    capabilityData.forEach((cap: Capability) => {
      if (cap.risk.some((risk) => risk.id === id)) {
        caps.push(cap.name);
      }
    });
    const impacted: DeleteRelationships[] = [];
    if (bcts.length) {
      impacted.push({
        relatedClass: 'BCTs',
        objectTitles: bcts,
      });
    }
    if (caps.length) {
      impacted.push({ relatedClass: 'Capabilities', objectTitles: caps });
    }
    setRelationships(impacted);
  }, [bctData, capabilityData, risk]);

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    const createDefaultValues = (
      risk?: RiskValues
    ): DefaultValues<RiskValues> => ({
      id: risk?.id,
      name: risk?.name,
      impact: risk?.impact,
    });
    reset(createDefaultValues(risk));
  }, [reset, isOpen, risk]);

  return (
    <AdminObjectForm
      close={close}
      isOpen={isOpen}
      endpoint={endpoint}
      convertToApi={convertToApi}
      data={risk ? { ...risk } : undefined}
      displayCategory={displayCategory}
      formConfig={formConfig}
      isNew={() => isNew()}
      deleteRelationships={relationships}
    >
      <div className='flex-col flex mt-4'>
        <label className='text-sm'>Name</label>
        <input
          className={`${appStyles.input} text-lg py-1`}
          defaultValue={risk ? getValues('name') : ''}
          {...register('name', { required: true })}
        />
      </div>
      <div className='flex-col flex mt-4'>
        <label className='text-sm'>Impact</label>
        <Controller
          control={control}
          name='impact'
          defaultValue={getValues().impact}
          render={({ field }) => (
            <HuiSelect
              options={impactOptions}
              displayKey='name'
              defaultValue={{ name: field.value ?? '-' }}
              selection={(selection) => field.onChange(selection.name)}
              maxHeightCss='max-h-64'
            />
          )}
        />
      </div>
    </AdminObjectForm>
  );
};

export default AdminRiskForm;
