import React, { ReactNode } from 'react';

interface Props {
  children: ReactNode;
  className?: string;
}

const TabList = ({ children, className }: Props) => (
  <ul
    className={`${className} border-b border-1 border-gray-400 flex flex-wrap mb-2`}
  >
    {children}
  </ul>
);

export default TabList;
