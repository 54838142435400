import React, { useEffect, useState } from 'react';
import AsyncContainer from '../complib/AsyncContainer';
import { SortConfig } from '../safe/safe-utils';
import TableSortHeading from '../safe/TableSortHeading';
import { useQueryItems } from '../support/queries';
import { Risk } from '../types/risk';
import AdminRiskForm from './AdminRiskForm';
import {
  compareItems,
  defaultSortConfig,
  highlitContent,
  updateSort,
} from './helpers';
import AdminListPage from './page-elements/AdminListPage';
import NoneShowing from './page-elements/NoneShowing';

const styles = {
  colImpact: 'flex-0 w-52 mr-2',
  colName: 'flex-1 mr-2 min-w-[16rem] my-2',
  colUpdate: 'flex-0 w-56 flex items-center',
};

const AdminRiskPage = () => {
  const riskData = useQueryItems('risk', true);

  const [filteredRisks, setFilteredRisks] = useState<Risk[]>();
  const [sortConfig, setSortConfig] = useState<SortConfig>(defaultSortConfig);
  const [openForm, setOpenForm] = useState<boolean>(false);
  const [selectedRisk, setSelectedRisk] = useState<Risk>();
  const [search, setSearch] = useState('');

  const closeForm = () => {
    setSelectedRisk(undefined);
    setOpenForm(false);
  };

  useEffect(() => {
    setFilteredRisks(
      (riskData ?? []).filter(
        (r: Risk) =>
          r.name
            .toLocaleLowerCase()
            .includes(search?.trim().toLocaleLowerCase()) ||
          r.impact
            .toLocaleLowerCase()
            .includes(search?.trim().toLocaleLowerCase())
      )
    );
  }, [riskData, setFilteredRisks, search]);

  const changeSort = (key: string) =>
    setSortConfig(updateSort(sortConfig, key));

  const compareRisks = (a: Risk, b: Risk): number =>
    compareItems(a, b, sortConfig);

  const selectRisk = (cap?: Risk): void => {
    setSelectedRisk(cap);
    setOpenForm(true);
  };

  return (
    <>
      <AdminListPage
        search={search}
        updateSearch={setSearch}
        addAction={selectRisk}
        header={
          <>
            {!!filteredRisks?.length && (
              <div className='flex text-left text-sm mb-2'>
                <>
                  <TableSortHeading
                    updateSort={changeSort}
                    sort={sortConfig}
                    sortKey='impact'
                    className={styles.colImpact}
                  >
                    Impact
                  </TableSortHeading>
                  <TableSortHeading
                    updateSort={changeSort}
                    sort={sortConfig}
                    sortKey='name'
                    className={styles.colName}
                  >
                    Risk
                  </TableSortHeading>
                  <div className={styles.colUpdate}>Updated</div>
                </>
              </div>
            )}
          </>
        }
        itemLabel='threat'
        list={
          <>
            <AsyncContainer ready={!!riskData} className='m-4'>
              {!!riskData &&
                filteredRisks?.sort(compareRisks).map((risk: Risk) => (
                  <div
                    key={risk.id}
                    className={`flex items-center border-gray-300 border-t px-4 text-sm`}
                  >
                    <div className={styles.colImpact}>
                      {highlitContent(risk.impact, search)}
                    </div>
                    <h3
                      className={`${styles.colName} font-semibold hover:underline cursor-pointer`}
                      onClick={() => selectRisk(risk)}
                    >
                      {highlitContent(risk.name, search)}
                    </h3>
                    <div className={styles.colUpdate}>{risk.update ?? '-'}</div>
                  </div>
                ))}
              <NoneShowing show={!filteredRisks?.length} />
            </AsyncContainer>
          </>
        }
      />
      {openForm && (
        <AdminRiskForm
          risk={selectedRisk}
          isOpen={openForm}
          close={closeForm}
        />
      )}
    </>
  );
};

export default AdminRiskPage;
