import React from 'react';
import { AlertOctagon, CheckCircle } from 'react-feather';
import { useToaster } from 'react-hot-toast/headless';
import Loader from './Loader';

const Toasts = () => {
  const { toasts, handlers } = useToaster();
  const { startPause, endPause, calculateOffset, updateHeight } = handlers;

  return (
    <div
      className='fixed right-[17rem] top-4'
      onMouseEnter={startPause}
      onMouseLeave={endPause}
    >
      {toasts.map((toast) => {
        const offset = calculateOffset(toast, {
          reverseOrder: false,
          gutter: 8,
        });

        const ref = (el: HTMLDivElement) => {
          if (el && typeof toast.height !== 'number') {
            const height = el.getBoundingClientRect().height;
            updateHeight(toast.id, height);
          }
        };
        return (
          <div
            key={toast.id}
            ref={ref}
            className='rounded-sm shadow-md bg-white w-60 absolute flex'
            style={{
              transition: 'all 0.2s ease-out',
              opacity: toast.visible ? 1 : 0,
              transform: `translateY(${offset}px)`,
            }}
            {...toast.ariaProps}
          >
            {toast.type === 'loading' && (
              <Loader className='w-6 h-6 flex-0 m-2' />
            )}
            {toast.type === 'success' && (
              <CheckCircle className='flex-0 text-sec-green m-2 w-8 h-8' />
            )}
            {toast.type === 'error' && (
              <AlertOctagon className='flex-0 text-sec-red m-2 w-8 h-8' />
            )}
            <div className='flex-1 m-2 ml-0 text-sm'>
              <h3 className='font-bold capitalize'>{toast.type}</h3>
              <p>{toast.message}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Toasts;
