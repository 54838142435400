import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import NumberInputRange from '../../complib/NumberInputRange';
import appStyles from '../../support/appStyles';
import {
  usePinFilterSelections,
  useSafeSettingsSelections,
} from '../../support/stores';
import { sanitisedNumber, titleCase } from '../../support/utils';
import CapabilityStandardFilters from '../CapabilityStandardFilters';
import { SafeProps } from '../safe-utils';
import { colors, endDir, endIcons } from './chart-constants';
import { FlowType } from './SafeFlowChart';

const SafeConfigPanel = ({ safeMode = 'risk' }: SafeProps) => {
  const { getSetting: getSafeSetting, setSetting: setSafeSetting } =
    useSafeSettingsSelections();
  const [pinMax, setPinMax] = useState<number>(3);
  const { selections: pinFilterSelections } = usePinFilterSelections();
  const [capCount, setCapCount] = useState<number>(
    getSafeSetting('maxCapabilitiesPerPin', 3) as number
  );
  const flowTypes = ['gap', 'proposed'];
  if (safeMode === 'risk') {
    flowTypes.unshift('risk');
  }

  const debouncedCountUpdate = React.useRef(
    debounce((count: number) => {
      if (!count) {
        return;
      }
      setSafeSetting('maxCapabilitiesPerPin', count);
    }, 300)
  ).current;

  useEffect(() => {
    const max = Math.floor(
      50 / Object.values(pinFilterSelections).filter((p) => p.active).length
    );
    setPinMax(max);
    if (capCount > max) {
      setCapCount(max);
      debouncedCountUpdate(max);
    }
  }, [pinFilterSelections, capCount, debouncedCountUpdate]);

  const handleCountUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const limited = sanitisedNumber(e.target.value, 0, pinMax);
    setCapCount(limited);
    debouncedCountUpdate(limited);
  };

  return (
    <>
      <CapabilityStandardFilters />
      <h2 className={appStyles.h2}>Flow Type</h2>
      <div className='text-sm mb-1 flex flex-wrap'>
        {flowTypes.map((type) => (
          <div
            key={type}
            className={`${appStyles.filterButton} ${
              getSafeSetting(
                'flowType',
                safeMode === 'risk' ? 'risk' : 'gap'
              ) === type
                ? 'bg-sky-darker hover:bg-ocean-lighter'
                : 'bg-gray-400 hover:bg-gray-500'
            } capitalize`}
            onClick={() => {
              setSafeSetting('flowType', type as FlowType);
              if (type === 'proposed') {
                setSafeSetting('showProducts', true);
              }
            }}
          >
            {type}
          </div>
        ))}
      </div>
      <div
        className={`${appStyles.filterButton} ${
          getSafeSetting('showProducts', true)
            ? 'bg-sky-darker hover:bg-ocean-lighter'
            : 'bg-gray-400 hover:bg-gray-500'
        } inline-block`}
        onClick={() =>
          setSafeSetting('showProducts', !getSafeSetting('showProducts', true))
        }
      >
        Show Products
      </div>
      <div
        className={`${appStyles.filterButton} ${
          getSafeSetting('showAllPins', true)
            ? 'bg-sky-darker hover:bg-ocean-lighter'
            : 'bg-gray-400 hover:bg-gray-500'
        } inline-block`}
        onClick={() =>
          setSafeSetting('showAllPins', !getSafeSetting('showAllPins', true))
        }
      >
        Show All Places
      </div>
      <h2 className={appStyles.h2}>Maximum Capabilities per PiN</h2>
      <NumberInputRange
        min={1}
        max={pinMax}
        value={capCount}
        onChange={handleCountUpdate}
      />
      <h2 className={appStyles.h2}>Path Color</h2>
      <div className='flex flex-wrap'>
        {Object.values(colors)
          .slice(0, 10)
          .map((c) => (
            <div
              key={c}
              style={{ background: c }}
              className={`w-8 h-8 rounded-full mr-1 mb-1 cursor-pointer opacity-50 hover:opacity-100 ${
                (getSafeSetting('pathColor', colors.lightBlue) as string) === c
                  ? 'border-2 border-gray-900 opacity-80'
                  : ''
              }`}
              onClick={() => setSafeSetting('pathColor', c)}
            ></div>
          ))}
      </div>
      <h2 className={appStyles.h2}>Start Point</h2>
      <div className='flex flex-wrap'>
        {endIcons.map((i) => (
          <div
            key={i}
            style={{
              backgroundColor: getSafeSetting(
                'pathColor',
                colors.lightBlue
              ) as string,
              backgroundImage: `url(${endDir}${i}.png)`,
            }}
            title={titleCase(i)}
            onClick={() => setSafeSetting('startPointImage', i)}
            className={`w-9 h-9 rounded-full mr-1 mb-1 cursor-pointer opacity-50 hover:opacity-100 bg-cover ${
              (getSafeSetting(
                'startPointImage',
                'remote-technician'
              ) as string) === i
                ? 'border-2 border-gray-900 opacity-80'
                : ''
            }`}
          ></div>
        ))}
      </div>
      <input
        className={`my-2 py-1 w-full ${appStyles.input}`}
        value={getSafeSetting('startPoint', 'Remote employee') as string}
        onChange={(e) => setSafeSetting('startPoint', e.target.value)}
      />
      <h2 className={appStyles.h2}>End Point</h2>
      <div className='flex flex-wrap'>
        {endIcons.map((i) => (
          <div
            key={i}
            style={{
              backgroundColor: getSafeSetting(
                'pathColor',
                colors.lightBlue
              ) as string,
              backgroundImage: `url(${endDir}${i}.png)`,
            }}
            title={titleCase(i)}
            onClick={() => setSafeSetting('endPointImage', i)}
            className={`w-9 h-9 rounded-full mr-1 mb-1 cursor-pointer opacity-50 hover:opacity-100 bg-cover ${
              (getSafeSetting('endPointImage', 'server') as string) === i
                ? 'border-2 border-gray-900 opacity-80'
                : ''
            }`}
          ></div>
        ))}
      </div>
      <input
        className={`my-2 py-1 w-full ${appStyles.input}`}
        value={getSafeSetting('endPoint', 'Server') as string}
        onChange={(e) => setSafeSetting('endPoint', e.target.value)}
      />
    </>
  );
};

export default SafeConfigPanel;
