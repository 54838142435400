import { CapabilityValues } from '../manage-data/AdminCapabilityForm';
import { queryClient } from '../support/queries';
import {
  CustomCapabilitySettings,
  useCustomCapabilitySelections,
  useMaturitySelections,
  usePinFilterSelections,
  useSafeSettingsSelections,
  useSecurityFunctionSelections,
} from '../support/stores';
import { sortedObjectArray } from '../support/utils';
import { Capability } from '../types/capability';

const groupedCapabilities = (capabilityData: Capability[]) => {
  const grouped: { [key: string]: Capability[] } = {};
  capabilityData.forEach((cap: Capability) => {
    const category: string = cap.domain ?? 'Other Domain';
    grouped[category] = grouped[category] ?? [];
    grouped[category].push(cap);
  });

  // maybe remove auto sorting when all views have sorting
  Object.keys(grouped).forEach(
    (cat: string) =>
      (grouped[cat] = sortedObjectArray<Capability>(grouped[cat], 'name'))
  );
  return grouped;
};

const statusSettings = [
  { name: 'Hide', color: 'bg-sec-dark-gray', selected: true, sort: 0 },
  { name: 'None', color: 'bg-danger', selected: true, sort: 1 },
  { name: 'Planned', color: 'bg-sky-darker', selected: true, sort: 2 },
  { name: 'Partial', color: 'bg-warning', selected: true, sort: 3 },
  { name: 'Full', color: 'bg-sec-green', selected: true, sort: 4 },
];

type SortDir = 1 | -1;
interface SortConfig {
  key: string;
  dir: SortDir;
}

const scoreOpacity = 0.7;
const isCurrent = (route: string) => location.pathname.endsWith(route);

// required for architect mode: capability-worker normally populates these
// const getCapabilityImage = (capName: string | undefined): string => {
//   const defaultImage = 'identity';
//   if (!capName) {
//     return defaultImage;
//   }
//   const iconData = capabilityIcons.find((i) => i.name === capName);
//   return iconData?.image ?? defaultImage;
// };

type SafeMode = 'risk' | 'architect';
interface SafeProps {
  safeMode?: SafeMode;
}

/* should a capability be included: Capability Selection filter */
const capabilityInSelection = (capability: Capability): boolean => {
  const capabilityModeType = useSafeSettingsSelections
    .getState()
    .getSetting('capabilityModeType', 'score') as string;
  const capabilityModeName = useSafeSettingsSelections
    .getState()
    .getSetting('capabilityModeName', 'score') as string;
  if (capabilityModeType === 'architecture') {
    // filter capabilities to selected architecture only
    // assuming that the query has already been triggered: a component requesting this should contain CapabilityModeSelect
    const architectureQueryState = queryClient.getQueryState(
      'architecture'
    ) as {
      status: string;
      data: { name: string; capability: { id: number }[] }[];
    };
    if (architectureQueryState.status === 'success') {
      const architectureData = architectureQueryState?.data ?? [];
      const architecture = architectureData.find(
        (a) => a.name === capabilityModeName
      );
      return (
        !!architecture &&
        architecture?.capability.some((c) => c.id === capability.id)
      );
    }
    return false;
  } else if (capabilityModeType === 'product') {
    // filter capabilities to selected product only
    if (
      !capability.product.length ||
      capability.product.every((p) => p.name !== capabilityModeName)
    ) {
      return false;
    }
  }
  return true;
};

const capabilityInPins = (capability: Capability): boolean => {
  const pinSelections = usePinFilterSelections.getState().selections;
  return (capability.pin ?? []).some(
    (p) => pinSelections[p.id as number]?.active
  );
};

const capabilityInMaturity = (capability: Capability): boolean => {
  const maturitySelections = useMaturitySelections.getState().selections;
  return (
    Object.values(maturitySelections).every((s) => s.active) ||
    (maturitySelections[capability.maturitylevel ?? 0]?.active ?? false)
  );
};

const capabilityInSecurityFunction = (capability: Capability): boolean => {
  const securityFunctionSelections =
    useSecurityFunctionSelections.getState().selections;
  return (
    Object.values(securityFunctionSelections).every((s) => s.active) ||
    capability.securityfunction.some(
      (s) => securityFunctionSelections[s.id].active
    )
  );
};

const capabilityInStandardFilters = (capability: Capability): boolean => {
  return (
    // Least complex first: try to return false quickly
    capabilityInMaturity(capability) &&
    capabilityInSecurityFunction(capability) &&
    capabilityInPins(capability) &&
    capabilityInSelection(capability)
  );
};

const openSurveyOnce = () => {
  if (!useSafeSettingsSelections.getState().getSetting('sentSurvey')) {
    useSafeSettingsSelections.getState().setSetting('showSurvey', true);
  }
};

const addCustomCapability = (data: CapabilityValues) => {
  const customCapabilityStore = useCustomCapabilitySelections.getState();
  const storeObject: CustomCapabilitySettings = {
    ...data,
    id: new Date().getTime(),
    active: true,
  };
  customCapabilityStore.setItem(storeObject.id, storeObject);
  customCapabilityStore.setItems(customCapabilityStore.selections);
};

export {
  groupedCapabilities,
  statusSettings,
  SortDir,
  SortConfig,
  scoreOpacity,
  isCurrent,
  // getCapabilityImage,
  SafeProps,
  SafeMode,
  capabilityInStandardFilters,
  openSurveyOnce,
  addCustomCapability,
};
