import { Canvg } from 'canvg';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import Button from '../../complib/Button';
import { getCapabilityArbitraryPriority } from '../../framework-product-tool/hardcodedFutureProblems';
import FlowHelp from '../../help/FlowHelp';
import HelpSection from '../../help/HelpSection';
import { useQueryItems } from '../../support/queries';
import {
  CapabilityProduct,
  CapabilitySettings,
  useCapabilitySelections,
  useCustomCapabilitySelections,
  useMaturitySelections,
  usePinFilterSelections,
  useSafeSettingsSelections,
  useSecurityFunctionSelections,
} from '../../support/stores';
import { sortedObjectArray } from '../../support/utils';
import { Capability } from '../../types/capability';
import { NameObject } from '../../types/item';
import { Pin } from '../../types/pin';
import {
  capabilityInStandardFilters,
  openSurveyOnce,
  SafeProps,
} from '../safe-utils';
import {
  CapabilityBadge,
  capBadge,
  colors,
  gapColors,
  heatColor,
} from './chart-constants';
import drawSafeFlow from './drawSafeFlow';
import SafeConfigPanel from './SafeConfigPanel';

export type FlowType = 'risk' | 'gap' | 'proposed';

const SafeFlowChart = ({ safeMode = 'risk' }: SafeProps) => {
  const anchor = useRef<SVGSVGElement | null>(null);
  const container = useRef<HTMLDivElement | null>(null);
  const [windowSize, setWindowSize] = useState<[number, number]>([0, 0]);
  const [cHeight, setCHeight] = useState<number>(0);
  const [cWidth, setCWidth] = useState<number>(0);
  const pinData = useQueryItems('pin');
  const capabilityData = useQueryItems('capability');
  const { selections: customCapabilitySelections } =
    useCustomCapabilitySelections();

  const { selections: pinFilterSelections } = usePinFilterSelections();
  const {
    getSetting: getSafeSetting,
    settings: safeSelections,
    setSetting: setSafeSetting,
  } = useSafeSettingsSelections();
  const { selections: capabilitySelections } = useCapabilitySelections();
  const { selections: maturitySelections } = useMaturitySelections();
  const { selections: secFunSelections } = useSecurityFunctionSelections();
  const [capabilities, setCapabilities] = useState<{
    [key: string]: CapabilityBadge[];
  }>();

  const updateSize = () => {
    setWindowSize([window.innerWidth, window.innerHeight]);
  };

  useLayoutEffect(() => {
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  useEffect(() => {
    if (container.current) {
      const containerRect = container.current.getBoundingClientRect();
      const canvasWidth =
        containerRect.width * 4 > 1280 ? containerRect.width * 4 : 1280;
      setCWidth(canvasWidth);
      setCHeight((canvasWidth * 800) / 1280);
    }
  }, [windowSize]);

  useEffect(() => {
    if (
      safeMode === 'architect' &&
      getSafeSetting('flowType', 'gap') === 'risk'
    ) {
      setSafeSetting('flowType', 'gap');
    }
  }, [safeMode, getSafeSetting, setSafeSetting]);

  // pick / sort / format capabilities for diagram
  useEffect(() => {
    const capabilityModeType = getSafeSetting('capabilityModeType') as string;
    const capabilityModeName = getSafeSetting('capabilityModeName') as string;
    if (!capabilitySelections) {
      return;
    }
    // filter for active pins only. order by score.
    let biggestCapabilitiesFirst = sortedObjectArray(
      Object.values(capabilitySelections).filter((cs) => {
        if (cs.status === 'Hide') {
          return false;
        }
        const capData: Capability = [
          ...capabilityData,
          ...Object.values(customCapabilitySelections),
        ]?.find(
          (c: CapabilitySettings) => c.name === cs.name || c.id === cs.id
        );
        if (!capData) {
          return false;
        }
        return capabilityInStandardFilters(capData);
      }),
      'score',
      1
    ).reverse();
    if (safeMode === 'architect') {
      // use the arbitrary capability priorities for ordering
      biggestCapabilitiesFirst = biggestCapabilitiesFirst.sort(
        (a: CapabilitySettings, b: CapabilitySettings) => {
          const aComp = getCapabilityArbitraryPriority(a.name ?? '', a.domain);
          const bComp = getCapabilityArbitraryPriority(b.name ?? '', b.domain);
          if (aComp === bComp) {
            return (a.name ?? '') > (b.name ?? '') ? 1 : -1;
          }
          return aComp > bComp ? -1 : 1;
        }
      );
    }
    // repeat for each related product.
    // capabilities show once for each product, for each pin
    const biggestCapabilitiesProductRepeated: CapabilitySettings[] = [];
    biggestCapabilitiesFirst.forEach((cs: CapabilitySettings) => {
      // for proposed flow type, if proposed products are set and active,
      // we show these only. Not current products.
      let productSource = cs.products ?? [];
      const flowType = getSafeSetting(
        'flowType',
        safeMode === 'risk' ? 'risk' : 'gap'
      ) as FlowType;
      if (flowType === 'proposed' && cs.proposed?.some((p) => p.active)) {
        productSource = cs.proposed;
      }
      let products = productSource.filter((p) => p.active);
      // product mode: just show the selected product
      // because valid capabilities can be linked to multiple products
      if (capabilityModeType === 'product') {
        products = products.filter((p) => p.name === capabilityModeName);
        productSource = [...products];
      }
      if (products?.length) {
        products.forEach((product: CapabilityProduct, i) => {
          // don't repeat per product if products are hidden
          if (getSafeSetting('showProducts', true) || i === 0) {
            biggestCapabilitiesProductRepeated.push(
              Object.assign({}, cs, { products: [product] })
            );
          }
        });
      } else {
        biggestCapabilitiesProductRepeated.push(cs);
      }
    });
    // group by pin and convert to badge display object
    const capabilitiesByPin: { [key: string]: CapabilityBadge[] } = {};
    (pinData ?? []).forEach((pin: Pin) => {
      if (pinFilterSelections[pin.id]?.active) {
        capabilitiesByPin[pin.name] = biggestCapabilitiesProductRepeated
          .filter((c: CapabilitySettings) => {
            const cData = [
              ...capabilityData,
              ...Object.values(customCapabilitySelections),
            ].find((cd: Capability) => cd.name === c.name);
            return (
              cData &&
              cData.pin.map((p: NameObject) => p.name).includes(pin.name)
            );
          })
          .slice(0, getSafeSetting('maxCapabilitiesPerPin', 3) as number)
          .map((c: CapabilitySettings) => {
            let color = '';
            switch (
              getSafeSetting(
                'flowType',
                safeMode === 'risk' ? 'risk' : 'gap'
              ) as FlowType
            ) {
              case 'gap':
                color = gapColors[c.status ?? 'None'];
                break;
              case 'proposed':
                color = colors.borderGreen;
                break;
              default:
                color = heatColor(
                  (c.score ?? 0) /
                    (getSafeSetting('capabilityMaxScore', 'score') as number)
                );
                break;
            }
            // not strict: need to fix - some ids are strings
            const capData: Capability = [
              ...capabilityData,
              ...Object.values(customCapabilitySelections),
            ]?.find((capability: Capability) => capability.id == c.id);
            return capBadge(c, color, capData?.image ?? '');
          });
      }
    });
    setCapabilities(capabilitiesByPin);
  }, [
    capabilitySelections,
    getSafeSetting,
    safeSelections,
    pinFilterSelections,
    capabilityData,
    pinData,
    safeMode,
    maturitySelections,
    secFunSelections,
    customCapabilitySelections,
  ]);

  useEffect(() => {
    if (!capabilities || !pinData) {
      return;
    }
    drawSafeFlow(anchor, {
      cWidth,
      cHeight,
      maxPerPin: getSafeSetting('maxCapabilitiesPerPin', 3) as number,
      capabilities,
      color: getSafeSetting('pathColor', colors.lightBlue) as string,
      start: getSafeSetting('startPointImage', 'remote-technician') as string,
      end: getSafeSetting('endPointImage', 'server') as string,
      startName: getSafeSetting('startPoint', 'Remote Employee') as string,
      endName: getSafeSetting('endPoint', 'Server') as string,
      scoreMax: getSafeSetting('capabilityMaxScore', 'score') as number,
      showProducts: getSafeSetting('showProducts', true) as boolean,
      showAllPins: getSafeSetting('showAllPins', true) as boolean,
      flowType: getSafeSetting(
        'flowType',
        safeMode === 'risk' ? 'risk' : 'gap'
      ) as FlowType,
    });

    const canvas = document.querySelector('canvas');
    if (!canvas) {
      return;
    }
    const ctx = canvas?.getContext('2d');
    if (!ctx) {
      return;
    }
    if (!anchor?.current?.outerHTML) {
      return;
    }
    const canvg = Canvg.fromString(ctx, anchor.current.outerHTML);
    canvas.width = cWidth;
    canvas.height = cHeight;
    canvg.render();
  }, [
    anchor,
    cHeight,
    cWidth,
    safeSelections,
    capabilities,
    getSafeSetting,
    pinData,
    safeMode,
  ]);

  const downloadImage = () => {
    openSurveyOnce();
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const canvasEl: HTMLCanvasElement = document.querySelector('canvas')!;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const anchorEl: HTMLAnchorElement = document.querySelector('#dla')!;
    if (!canvasEl || !anchorEl) {
      return;
    }
    anchorEl.href = canvasEl.toDataURL('image/png');
    anchorEl.click();
  };

  return (
    <>
      <section className='grid grid-cols-[minmax(200px,1fr)_4fr] gap-4'>
        <section>
          <div
            className='overflow-y-scroll mt-6 pr-4 border-y border-gray-400'
            style={{ maxHeight: `${Math.max(cHeight / 4, 320) - 50}px` }}
          >
            <SafeConfigPanel safeMode={safeMode} />
          </div>
          <Button className='mt-4' onClick={downloadImage}>
            Download image
          </Button>
        </section>
        <div
          ref={container}
          className='relative min-h-[400px] min-w-[640px] my-6'
        >
          <div className='relative scale-[0.25] translate-x-[-37.5%] translate-y-[-37.5%] border border-sky h-[40vh]'>
            <svg
              xmlnsXlink='http://www.w3.org/1999/xlink'
              xmlns='http://www.w3.org/2000/svg'
              ref={anchor}
              width={cWidth}
              height={cHeight}
            ></svg>
          </div>
          <canvas className='hidden'></canvas>
          {/* eslint-disable-next-line */}
          <a id='dla' download='Safe_Flow_Diagram.png'></a>
        </div>
      </section>
      <HelpSection>
        <FlowHelp safeMode={safeMode} />
      </HelpSection>
    </>
  );
};

export default SafeFlowChart;
