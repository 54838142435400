import React from 'react';
import { Link, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Tab from '../complib/Tab';
import TabList from '../complib/TabList';
import appStyles from '../support/appStyles';
import AdminArchitecturePage from './AdminArchitecturePage';
import AdminBCTPage from './AdminBCTPage';
import AdminCapabilityPage from './AdminCapabilityPage';
import AdminControlPage from './AdminControlPage';
import AdminFrameworkPage from './AdminFrameworkPage';
import AdminPinPage from './AdminPinPage';
import AdminProductPage from './AdminProductPage';
import AdminRiskPage from './AdminRiskPage';

const styles = {
  link: 'px-4 font-normal block pb-2 font-cisco text-semibold text-lg',
};

const ManageData = () => {
  const location = useLocation();
  const isCurrent = (route: string) => location.pathname.endsWith(`/${route}`);

  return (
    <section className={appStyles.container}>
      <TabList>
        <Tab selected={isCurrent('bcts')}>
          <Link to='bcts' className={styles.link}>
            BCTs
          </Link>
        </Tab>
        <Tab selected={isCurrent('capabilities')}>
          <Link to='capabilities' className={styles.link}>
            Capabilities
          </Link>
        </Tab>
        <Tab selected={isCurrent('risks')}>
          <Link to='risks' className={styles.link}>
            Threats
          </Link>
        </Tab>
        <Tab selected={isCurrent('architectures')}>
          <Link to='architectures' className={styles.link}>
            Architectures
          </Link>
        </Tab>
        <Tab selected={isCurrent('products')}>
          <Link to='products' className={styles.link}>
            Products
          </Link>
        </Tab>
        <Tab selected={isCurrent('pins')}>
          <Link to='pins' className={styles.link}>
            PINs
          </Link>
        </Tab>
        <Tab selected={isCurrent('frameworks')}>
          <Link to='frameworks' className={styles.link}>
            Frameworks
          </Link>
        </Tab>
        <Tab selected={isCurrent('controls')}>
          <Link to='controls' className={styles.link}>
            Controls
          </Link>
        </Tab>
        <Tab selected={isCurrent('subcontrols')}>
          <Link to='subcontrols' className={styles.link}>
            Subcontrols
          </Link>
        </Tab>
      </TabList>
      <Routes>
        <Route path='bcts' element={<AdminBCTPage />} />
        <Route path='capabilities' element={<AdminCapabilityPage />} />
        <Route path='risks' element={<AdminRiskPage />} />
        <Route path='architectures' element={<AdminArchitecturePage />} />
        <Route path='frameworks' element={<AdminFrameworkPage />} />
        <Route path='controls' element={<AdminControlPage />} />
        <Route path='subcontrols' element={<AdminControlPage sub={true} />} />
        <Route path='products' element={<AdminProductPage />} />
        <Route path='pins' element={<AdminPinPage />} />
        <Route
          path='product-to-framework'
          element={<p>Product to Framework Mapping</p>}
        />
        <Route path='/' element={<Navigate replace to='bcts' />} />
      </Routes>
    </section>
  );
};

export default ManageData;
