import React, { ReactNode } from 'react';
import { X } from 'react-feather';
import Modal from 'react-modal';
import Button from './Button';
import appStyles from '../support/appStyles';

interface Props {
  close: () => void;
  isOpen: boolean;
  children: ReactNode;
  title?: string;
  cssClass?: string;
}

const ModalFrame = ({ close, isOpen, title, children, cssClass }: Props) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={close}
      overlayClassName='bg-[rgba(0,0,0,0.2)] fixed top-0 left-0 right-0 bottom-0 flex h-[100vh] w-[100vw] items-center justify-center z-20'
      className={`max-h-[83vh] relative bg-gray-200 rounded-sm shadow-lg p-6 ${
        cssClass ? cssClass : 'w-5/6 max-w-xl'
      }`}
    >
      <Button
        theme='ghost'
        onClick={close}
        className='top-4 right-4 px-1 py-1 absolute mr-0'
      >
        <X size={16} />
      </Button>
      {title && <h2 className={appStyles.h2}>{title}</h2>}
      <>{children}</>
    </Modal>
  );
};

export default ModalFrame;
