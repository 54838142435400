import { FieldGroupIcon } from '@aws-amplify/ui-react';
import React, { useEffect } from 'react';
import { Controller, DefaultValues, useForm } from 'react-hook-form';
import appStyles from '../support/appStyles';
import { useQueryItems } from '../support/queries';
import AdminObjectForm from './AdminObjectForm';
import AdminSubcontrolPage from './AdminSubcontrolPage';
import Control from './api-types/control';
import Framework from './api-types/framework';
import FrameworkControlSelector from './page-elements/FrameworkControlSelector';

interface Props {
  item?: Framework;
  close: () => void;
  isOpen: boolean;
}

interface ControlValue {
  id: number;
  subcontrols: { id: number }[];
}

const endpoint = 'framework';
const displayCategory = 'Framework';

const AdminFrameworkForm = ({ item, close, isOpen }: Props) => {
  const formConfig = useForm<Framework>();
  const { register, reset, control, getValues } = formConfig;
  const isNew = (): boolean => !item?.id;

  const convertToApi = (data: Framework): Framework => {
    const apiData: Framework = {
      ...item,
      ...data,
    };
    return apiData;
  };

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    const createDefaultValues = (a?: Framework): DefaultValues<Framework> => ({
      id: a?.id,
      name: a?.name,
      controls: a?.controls?.map((control: Control) => ({
        id: control.id,
        subcontrols: control.subcontrols?.map((subcontrol: Control) => ({
          id: subcontrol.id,
        })),
      })),
      description: a?.description,
    });
    reset(createDefaultValues(item));
  }, [reset, isOpen, item]);

  return (
    <AdminObjectForm
      close={close}
      isOpen={isOpen}
      endpoint={endpoint}
      convertToApi={convertToApi}
      data={item ? { ...item } : undefined}
      displayCategory={displayCategory}
      formConfig={formConfig}
      isNew={() => isNew()}
    >
      <div className='flex-col flex mt-4'>
        <label className='text-sm'>Name</label>
        <input
          className={`${appStyles.input} text-lg py-1`}
          defaultValue={item ? getValues('name') : ''}
          {...register('name', { required: true })}
        />
      </div>
      <div className='flex-col flex mt-4'>
        <label className='text-sm'>Name</label>
        <textarea
          className={`${appStyles.input} text-lg py-1`}
          defaultValue={item ? getValues('description') : ''}
          {...register('description')}
        />
      </div>
      <div className='flex-col flex mt-4'>
        <label className='text-sm'>Controls</label>
        <Controller
          control={control}
          name='controls'
          defaultValue={getValues().controls}
          render={({ field }) => (
            <FrameworkControlSelector
              onChange={field.onChange}
              value={field.value as ControlValue[]}
            />
          )}
        />
      </div>
      {/* <pre className='text-xs'>{JSON.stringify(getValues(), null, 2)}</pre> */}
    </AdminObjectForm>
  );
};

export default AdminFrameworkForm;
