import { CapabilityRisk } from '../types/capability';
import { NameObject } from '../types/item';
import { getLocalStorageValue, saveLocalStorageValue } from './local-storage';
import {
  getSelectionsStoreHook,
  ItemSelections,
  ItemSettings,
} from './selections.store';
import getSettingsStoreHook, { Settings } from './settings.store';

export type ProductType = 'cisco' | '3rd-party';
type CapabilityProduct = {
  type: ProductType;
  name: string;
  active: boolean;
};
interface CapabilitySettings extends ItemSettings {
  products?: CapabilityProduct[];
  proposed?: CapabilityProduct[];
  status?: string;
  score?: number;
  domain?: string;
  image?: string;
}

interface BctSettings extends ItemSettings {
  count: number;
  score: number;
  groups: { [key: string]: ScoreSettings };
  risks: { [key: number]: RiskSettings };
  type: string;
  description?: string;
}

interface RiskSettings extends ScoreSettings {
  group?: string;
}

interface ScoreSettings extends ItemSettings {
  score?: number;
}

interface CustomCapabilitySettings extends ItemSettings {
  image?: string;
  name: string;
  pin: NameObject[];
  product: NameObject[];
  risk: CapabilityRisk[];
  type?: string;
  update?: number;
  domain: string;
  securityfunction: { name: string; id: number }[];
  maturitylevel: number;
  [key: string]:
    | number
    | boolean
    | undefined
    | string
    | NameObject[]
    | CapabilityRisk[]
    | null
    | { name: string; id: number }[];
}

const localStores = {
  CapGroup: 'capability_group',
  Cap: 'capability',
  PinFilter: 'pin_filter',
  CapScore: 'capability_score',
  Safe: 'safe_settings',
  Bct: 'bct_risk',
  App: 'app_settings',
  BctCategories: 'bct_categories',
  ThirdPartyProducts: '3rd_party_products',
  MaturityLevel: 'maturitylevel',
  SecurityFunction: 'securityfunction',
  FWProduct: 'fw_product',
  CustomCapability: 'custom_capability',
} as const;

/**
 * There doesn't seem to be a performance hit on writing to localstorage after
 * every selections store edit.
 * If needed, these saveLocalStorageValue calls should be debounced per store
 */

const useCapabilitySelections = getSelectionsStoreHook<CapabilitySettings>(
  getLocalStorageValue(localStores.Cap, {}),
  (selections: ItemSelections<CapabilitySettings>) =>
    saveLocalStorageValue(localStores.Cap, selections)
);

// disabling local persistance for this
// - not a useful feature for these filters
// - can cause issues when a saved config meets a data update
const usePinFilterSelections = getSelectionsStoreHook<ItemSettings>(
  // getLocalStorageValue(localStores.PinFilter, {})
  {}
  // (selections: ItemSelections<ItemSettings>) => saveLocalStorageValue(localStores.PinFilter, selections)
);

// temporary: convert to selections once api support these (need reqs from bill)
const useBctCategoriesSettings = getSettingsStoreHook(
  getLocalStorageValue(localStores.BctCategories, {}),
  (settings: Settings) =>
    saveLocalStorageValue(localStores.BctCategories, settings)
);

// also temp.
const useCapabilityGroupsSettings = getSettingsStoreHook(
  getLocalStorageValue(localStores.CapGroup, {}),
  (settings: Settings) => saveLocalStorageValue(localStores.CapGroup, settings)
);

// disabling local persistance for this too
const useSafeSettingsSelections = getSettingsStoreHook(
  // getLocalStorageValue(localStores.Safe, {})
  {}
  // (settings: Settings) => saveLocalStorageValue(localStores.Safe, settings)
);

const useBctRiskSelections = getSelectionsStoreHook<BctSettings>(
  getLocalStorageValue(localStores.Bct, {}),
  (selections: ItemSelections<BctSettings>) =>
    saveLocalStorageValue(localStores.Bct, selections)
);

const useAppSettingsSelections = getSettingsStoreHook(
  getLocalStorageValue(localStores.App, {}),
  (settings: Settings) => saveLocalStorageValue(localStores.App, settings)
);

// settingsStore: string key required for user-created products
const useThirdPartyProductSettings = getSettingsStoreHook(
  getLocalStorageValue(localStores.ThirdPartyProducts, {}),
  (settings: Settings) =>
    saveLocalStorageValue(localStores.ThirdPartyProducts, settings)
);

const useMaturitySelections = getSelectionsStoreHook<ItemSettings>(
  getLocalStorageValue(localStores.MaturityLevel, {}),
  (selections: ItemSelections<ItemSettings>) =>
    saveLocalStorageValue(localStores.MaturityLevel, selections)
);

const useSecurityFunctionSelections = getSelectionsStoreHook<ItemSettings>(
  getLocalStorageValue(localStores.SecurityFunction, {}),
  (selections: ItemSelections<ItemSettings>) =>
    saveLocalStorageValue(localStores.SecurityFunction, selections)
);

const useFWProductSelections = getSelectionsStoreHook<ItemSettings>(
  {} //getLocalStorageValue(localStores.FWProduct, {}),
  //(selections: ItemSelections<ItemSettings>) => saveLocalStorageValue(localStores.FWProduct, selections)
);

const useCustomCapabilitySelections =
  getSelectionsStoreHook<CustomCapabilitySettings>(
    getLocalStorageValue(localStores.CustomCapability, {}),
    (selections: ItemSelections<CustomCapabilitySettings>) =>
      saveLocalStorageValue(localStores.CustomCapability, selections)
  );

const resetStores = () => {
  [
    useCapabilitySelections,
    usePinFilterSelections,
    useBctRiskSelections,
    useSecurityFunctionSelections,
    useMaturitySelections,
    useCustomCapabilitySelections,
  ].forEach((store) => store.getState().setItems({}));
  [
    useCapabilityGroupsSettings,
    useSafeSettingsSelections,
    // useAppSettingsSelections,
    useThirdPartyProductSettings,
    useBctCategoriesSettings,
  ].forEach((store) => store.getState().clearSettings());
};

export {
  CapabilitySettings,
  CapabilityProduct,
  ScoreSettings,
  RiskSettings,
  BctSettings,
  CustomCapabilitySettings,
  useCapabilitySelections,
  useCapabilityGroupsSettings,
  usePinFilterSelections,
  useSafeSettingsSelections,
  useBctRiskSelections,
  useAppSettingsSelections,
  useBctCategoriesSettings,
  useThirdPartyProductSettings,
  useMaturitySelections,
  useSecurityFunctionSelections,
  useCustomCapabilitySelections,
  resetStores,
  useFWProductSelections,
};
