import React from 'react';
import appStyles from '../../support/appStyles';

type MTOption = { id: number; name: string };
type MTOptions = MTOption[];

interface Props {
  options: MTOptions;
  value?: MTOptions;
  onChange: (value: MTOptions) => void;
}

const MultiToggleControl = ({ options, value, onChange }: Props) => {
  const optionPosition = (option: MTOption): number => {
    return value?.findIndex ? value?.findIndex((o) => o.id === option.id) : -1;
  };
  const isSelected = (option: MTOption): boolean => optionPosition(option) > -1;
  const toggleCategory = (option: MTOption) => {
    const newValue = [...(value ?? [])];
    const selectedOption = optionPosition(option);
    if (selectedOption > -1) {
      newValue.splice(selectedOption, 1);
    } else {
      newValue.push(option);
    }
    onChange(newValue);
  };

  return (
    <div className='flex flex-wrap'>
      {options.map((option: MTOption) => (
        <div
          key={option.id}
          className={`${appStyles.filterButton} ${
            isSelected(option)
              ? 'bg-sky-darker hover:bg-ocean-lighter'
              : 'bg-gray-400 hover:bg-gray-500'
          } capitalize pt-[1px] pb-[1px] pl-3 pr-3 text-sm`}
          onClick={() => toggleCategory(option)}
        >
          {option.name}
        </div>
      ))}
    </div>
  );
};

export default MultiToggleControl;
export { MTOptions, MTOption };
