// we should be storing this list in the db
const verticals = [
  //'assets',
  'commercial',
  'education',
  'enterprise',
  'federalgov',
  'financial',
  'healthcare',
  'individuals',
  'localgov',
  'manufacturing',
  'nation',
  'operations',
  'thirdparty',
  'utilities',
];

export { verticals };
