import React, { useEffect, useState } from 'react';
import { ChevronDown, ChevronUp, Loader } from 'react-feather';
import { useQuery } from 'react-query';
import SearchInput from '../complib/SearchInput';
import { highlitContent } from '../manage-data/helpers';
import appStyles from '../support/appStyles';
import {
  fetchProduct,
  getQueryCacheFrom,
  queryConfig,
  useQueryItems,
} from '../support/queries';
import { useAppSettingsSelections } from '../support/stores';
import { FrameworkElement, Integration, Product } from '../types/product';
import ItemsTable, { TableData } from './ItemsTable';

const fwProps: (
  | 'framework'
  | 'group'
  | 'name'
  | 'description'
  | 'coverage'
  | 'coverageDescription'
  | 'licenceLevel'
)[] = [
  'framework',
  'group',
  'name',
  'coverageDescription',
  'coverage',
  'description',
  'licenceLevel',
];
interface Props {
  name: string;
}

interface FwCount {
  framework: string;
  total_subcontrols: number;
}

const ProductTable = ({ name }: Props) => {
  const [filter, setFilter] = useState('');
  const [open, setOpen] = useState(true);
  // const ref = useRef<HTMLDivElement>(null);
  // const [tableH, setTableH] = useState(0);
  const { data } = useQuery(`product-${name}`, () => fetchProduct(name), {
    ...queryConfig,
    initialData: () => getQueryCacheFrom<Product>('product', name),
  });
  const fwCounts = useQueryItems('subcontrol/frameworkcount');
  const getFwCount = (): number => {
    return (
      fwCounts?.find((f: FwCount) => f.framework === frameworkSelection)
        ?.total_subcontrols ?? 0
    );
  };
  const { getSetting } = useAppSettingsSelections();
  const frameworkSelection = getSetting('filterFramework');

  const [tableData, setTableData] = useState<TableData>();
  useEffect(() => {
    if (!data) {
      setTableData(undefined);
    } else {
      const tData: TableData = {
        columns: ['Integration', 'Capability', 'Integration Description'],
        rows: data.integrations?.map((integration: Integration) => [
          integration.name,
          integration.integration,
          integration.des,
        ]),
      };
      setTableData(tData);
    }
  }, [data]);

  // useEffect(() => {
  //   if (!data?.name) {
  //     return;
  //   }
  //   requestAnimationFrame(() => setTableH(ref.current?.clientHeight ?? 0));
  // }, [data, setTableH]);

  const filterFrameworks = (): FrameworkElement[] => {
    const frameworkOnly: FrameworkElement[] =
      data?.framework.filter((f) => f.framework === frameworkSelection) ?? [];
    if (!filter?.length) {
      return frameworkOnly;
    }
    return frameworkOnly.filter((f) =>
      fwProps.some(
        (prop) =>
          !!f[prop] &&
          (f[prop] ?? '')
            .toLocaleLowerCase()
            .includes(filter.trim().toLocaleLowerCase())
      )
    );
  };

  return (
    <>
      {data && (
        <>
          {!!data.busneed?.length && (
            <div className='text-sm mb-1 flex flex-wrap'>
              {data.busneed.map((bn) => (
                <div key={bn.name} className={appStyles.tag}>
                  {bn.name}
                </div>
              ))}
            </div>
          )}
          <p className='text-sm mb-2'>{data.description}</p>
          {!tableData && <Loader />}
          {!!tableData?.rows.length && <ItemsTable tableData={tableData} />}
          <section className='border border-gray-300 rounded-sm'>
            <header
              className='flex  cursor-pointer hover:text-sky-darker'
              onClick={() => setOpen(!open)}
            >
              {!open && <ChevronDown size={32} className='self-center py-1' />}
              {open && <ChevronUp size={32} className='self-center my-1' />}
              <h3 className='self-center my-2 text-lg'>Controls</h3>
              <span
                className={`${
                  filterFrameworks().length > 0
                    ? 'bg-success'
                    : 'bg-sec-light-gray'
                } min-w-[29px] text-center text-white rounded-full px-2 ml-2 self-center`}
              >
                {filterFrameworks().length} of {getFwCount()}
              </span>
            </header>
            <div
              style={{
                maxHeight: (open ? '360' : '0') + 'px',
              }}
              className='overflow-y-auto transition-max-h'
            >
              {/* <div ref={ref}> */}
              <div className='mb-3'>
                <div className='bg-white sticky top-0'>
                  <SearchInput
                    value={filter}
                    changeEvent={(value: string) => setFilter(value)}
                    placeholder='Search'
                    className='mx-4'
                  />
                </div>
                {!!filterFrameworks()?.length && (
                  <table className={`min-w-full ${appStyles.table} mb-0`}>
                    <thead className='sticky top-[26px] bg-white'>
                      <tr className={appStyles.ths}>
                        <th className={appStyles.cell}>Framework</th>
                        <th className={appStyles.cell}>Group</th>
                        <th className={appStyles.cell}>Control</th>
                        <th className={appStyles.cell}>Description</th>
                        <th className={appStyles.cell}>Coverage</th>
                        <th className={appStyles.cell}>Coverage Description</th>
                        <th className={appStyles.cell}>Licence Level</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filterFrameworks()
                        ?.sort((a, b) => {
                          if (a.coverage === b.coverage) {
                            return a.name > b.name ? 1 : -1;
                          }
                          return a.coverage > b.coverage ? 1 : -1;
                        })
                        .map((f, i) => (
                          <tr key={f.name + i} className={appStyles.tr}>
                            {fwProps.map((prop) => (
                              <td className={appStyles.cell} key={prop}>
                                {highlitContent(f[prop] ?? '', filter)}
                              </td>
                            ))}
                          </tr>
                        ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};
export default ProductTable;
