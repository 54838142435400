import React, { ReactNode, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useMutation } from 'react-query';
import ModalFrame from '../complib/ModalFrame';
import ConfirmActionModal from '../safe/ConfirmActionModal';
import { deleteItem, postItem, putItem, queryClient } from '../support/queries';
import { NameObject } from '../types/item';
import FormActionButtons from './page-elements/FormActionButtons';
import { toast } from 'react-hot-toast/headless';

interface DeleteRelationships {
  relatedClass: string;
  objectTitles: string[];
}
interface Props<T> {
  close: () => void;
  isOpen: boolean;
  children: ReactNode;
  endpoint: string;
  invalidate?: string;
  convertToApi: (values: T) => any;
  data?: FormData;
  displayCategory: string;
  formConfig: any;
  isNew: () => boolean;
  deleteRelationships?: DeleteRelationships[];
  customSubmit?: (data: T) => void; // Don't call api: form creating local objects only
}

interface FormData extends NameObject {
  id?: number;
  [key: string]: unknown;
}

const AdminObjectForm = <T extends { [key: string]: any }>({
  isOpen,
  close,
  children,
  endpoint,
  convertToApi,
  data,
  displayCategory,
  formConfig,
  isNew,
  invalidate, // some endpoints are different for get all. 2023/07
  deleteRelationships,
  customSubmit,
}: Props<T>) => {
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const { handleSubmit, getValues } = formConfig;

  const toastName = `${displayCategory}${data?.name ? `: ${data.name}` : ''}`;

  const mutation = useMutation({
    mutationFn: (itemData: T) => {
      toast.loading(`Saving ${toastName}`, {
        id: 'save-' + data?.id,
      });
      const apiData = convertToApi(itemData);
      return isNew() ? postItem(endpoint, apiData) : putItem(endpoint, apiData);
    },
    onError: () => {
      toast.error(`Failed to save ${toastName}`, {
        id: 'save-' + data?.id,
      });
    },
    onSuccess: () => {
      toast.success(`Saved ${toastName}`, {
        id: 'save-' + data?.id,
      });
      return queryClient.invalidateQueries({
        queryKey: [invalidate ?? endpoint],
      });
    },
  });

  const onSubmit: SubmitHandler<T> = (data) => {
    if (customSubmit) {
      customSubmit(data);
    } else {
      mutation.mutate(data);
    }
    close();
  };

  const deleteMutation = useMutation({
    mutationFn: (itemData: T) => {
      toast.loading(`Removing ${toastName}`, {
        id: 'delete-' + data?.id,
      });
      return deleteItem(endpoint, itemData);
    },
    onError: () => {
      toast.error(`Failed to remove ${toastName}`, {
        id: 'delete-' + data?.id,
      });
    },
    onSuccess: () => {
      toast.success(`Removed ${toastName}`, {
        id: 'delete-' + data?.id,
      });
      return queryClient.invalidateQueries({
        queryKey: [invalidate ?? endpoint],
      });
    },
  });

  const clickDelete = () => {
    deleteMutation.mutate(getValues());
    setConfirmDelete(false);
    close();
  };

  const deleteMessageLines = (): string[] => {
    const lines = [`Permanently delete this ${displayCategory}.`];
    if (deleteRelationships?.length) {
      deleteRelationships.forEach((relationship: DeleteRelationships) => {
        lines.push(
          `${
            relationship.relatedClass
          } affected: ${relationship.objectTitles.join(', ')}`
        );
      });
    }
    lines.push('This action cannot be undone.');
    return lines;
  };

  return (
    <ModalFrame close={close} isOpen={isOpen} cssClass='w-2/3 max-w-4xl h-5/6'>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className='flex-col flex text-sec-dark-gray h-full'
      >
        <h2 className='font-cisco text-xl mb-4 text-sec-dark-gray titlecase flex-0'>
          {isNew()
            ? `Add ${displayCategory}`
            : `Edit ${displayCategory}: ${data?.name}`}
        </h2>
        <div className='flex-1 h-full overflow-y-auto'>{children}</div>
        <div className='flex-0'>
          <FormActionButtons
            displayCategory={displayCategory}
            isNew={isNew}
            deleteItem={() => setConfirmDelete(true)}
            close={close}
          />
        </div>
      </form>
      {confirmDelete && (
        <ConfirmActionModal
          isOpen={confirmDelete}
          close={() => setConfirmDelete(false)}
          action={clickDelete}
          title={'Delete ' + data?.name}
          body={deleteMessageLines()}
          actionButton='Delete'
          actionIcon='delete'
        />
      )}
    </ModalFrame>
  );
};

export default AdminObjectForm;
export { DeleteRelationships };
