import { getSavedIDToken } from './local-storage';
import { useAppSettingsSelections } from './stores';

const getAppSetting = useAppSettingsSelections.getState().getSetting;

const isDevMode = (): boolean => {
  const buildMode = getAppSetting('buildMode', 'production');
  return buildMode === 'development';
};

const sortedObjectArray = <T extends { [key: string]: string | any }>(
  data: T[],
  property: string,
  mult = 1
) => {
  const array = [...data];
  return array.sort((x: T, y: T) => {
    const a0 = x[property];
    const b0 = y[property];
    const a = typeof a0 === 'string' ? a0.toLocaleLowerCase() : a0;
    const b = typeof b0 === 'string' ? b0.toLocaleLowerCase() : b0;
    if (a > b) {
      return 1 * mult;
    }
    if (b > a) {
      return -1 * mult;
    }
    return 0;
  });
};

const titleCase = (s: string): string => {
  if (!s) {
    return '';
  }
  return s
    .split('-')
    .map((s) => s[0].toUpperCase() + s.slice(1))
    .join(' ');
};

const sanitisedNumber = (s: string, min = 0, max = 100): number => {
  const inputInt = parseInt(s) || 0;
  return Math.max(min, Math.min(max, inputInt));
};

const isAdminUser = (): boolean => {
  const buildMode = getAppSetting('buildMode', 'production');
  if (buildMode === 'development') {
    return true;
  }
  const userGroups = getAppSetting('userGroups', []) as string[];
  if (userGroups.includes('Comply-Tool-Admins')) {
    return true;
  }
  return false;
};

const tokenRemaining = (): number => {
  const expiry = getAppSetting('tokenExpiry', 0) as number;
  const currentTime = Math.round(new Date().getTime() / 1000);
  return Math.max(expiry - currentTime, 0);
};

const isAuthenticated = (): boolean => {
  const remaining = tokenRemaining();
  return remaining > 0;
};

const getToken = (): string | undefined => {
  if (isDevMode()) {
    return useAppSettingsSelections
      .getState()
      .getSetting('devToken', '') as string;
  }
  return getSavedIDToken();
};

export {
  sortedObjectArray,
  titleCase,
  sanitisedNumber,
  isAdminUser,
  isDevMode,
  isAuthenticated,
  tokenRemaining,
  getToken,
};
