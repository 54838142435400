import React, { ReactNode } from 'react';
import { Plus } from 'react-feather';
import Button from '../../complib/Button';
import Card from '../../complib/Card';
import SearchInput from '../../complib/SearchInput';
import appStyles from '../../support/appStyles';

interface Props {
  itemLabel: string;
  itemsLabel?: string;
  filters?: ReactNode;
  header: ReactNode;
  list: ReactNode;
  search?: string;
  addAction?: () => void;
  updateSearch?: (term: string) => void;
}

const AdminListPage = ({
  itemLabel,
  itemsLabel,
  filters,
  header,
  list,
  search,
  addAction,
  updateSearch,
}: Props) => {
  return (
    <section className='grid grid-cols-[minmax(200px,1fr)_6fr] gap-8'>
      <section className='my-6'>
        {updateSearch && (
          <SearchInput
            value={search}
            changeEvent={updateSearch}
            placeholder={`Search ${itemsLabel ?? `${itemLabel}s`}`}
            className='w-full py-1 text-l'
          />
        )}
        {filters}
      </section>
      <Card className={`my-6 px-0 pb-3 pt-1 overflow-x-auto`}>
        <header className='px-4'>
          <h2 className={`pb-2 ${appStyles.h2} capitalize flex items-center`}>
            <span className='flex-1'>{`${itemsLabel ?? itemLabel}s`}</span>
            {addAction && (
              <Button
                theme='success'
                className='flex-none px-1 py-1'
                onClick={() => addAction()}
              >
                <Plus size={22} />
              </Button>
            )}
          </h2>
          {header}
        </header>
        {list}
      </Card>
    </section>
  );
};

export default AdminListPage;
