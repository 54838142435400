import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { isAuthenticated, isDevMode, tokenRemaining } from '../support/utils';

interface Props {
  className?: string;
  links: string[];
}

const Header = ({ className, links = [] }: Props) => {
  const location = useLocation();
  const isCurrent = (route: string) =>
    location.pathname.split('/')[1] === route.replace('/', '');
  const [remaining, setRemaining] = React.useState(tokenRemaining);

  useEffect(() => {
    if (isDevMode()) {
      setInterval(() => {
        setRemaining(tokenRemaining());
      }, 1000);
    }
  }, []);

  return (
    <header
      className={`bg-ocean-darker text-white text-left px-8 py-4 flex items-center ${className}`}
    >
      <h1 className='font-cisco text-xl hover:text-white whitespace-nowrap'>
        <Link to='/'>Risk and Compliance Tool</Link>
      </h1>
      <ul className='flex mx-12'>
        {links.map((n) => (
          <li key={n}>
            <Link
              to={`/${n}`}
              className={`p-4 text-gray-300 hover:text-white capitalize whitespace-nowrap ${
                isCurrent(`/${n}`) ? 'border-b-4 border-sky-lighter' : ''
              }`}
            >
              {n.replace('-', ' ')}
            </Link>
          </li>
        ))}
        {isDevMode() && (
          <li>
            <div
              className={`-mt-1 absolute ml-4 ${
                isAuthenticated() ? 'bg-sec-green' : 'bg-sec-red animate-pulse'
              } rounded-full p-2 text-sm font-bold text-black min-w-[2rem] h-8 flex items-center justify-items-center`}
            >
              {remaining / 60 < 10 ? '0' : ''}
              {Math.floor(remaining / 60)}:{remaining % 60 < 10 ? '0' : ''}
              {remaining % 60}
            </div>
          </li>
        )}
      </ul>
    </header>
  );
};
export default Header;
