import React from 'react';
import { SafeProps } from '../safe/safe-utils';
import appStyles from '../support/appStyles';
import HelpContentStandardFilters from './HelpContentStandardFilters';

const ReportHelp = ({ safeMode }: SafeProps) => {
  return (
    <>
      {safeMode === 'risk' && (
        <>
          <h2 className={appStyles.h2}>
            SAFE Risk Assessment – Gap Report (3)
          </h2>
          <p className='mb-4'>
            The final gapping report is displayed on this page. Security
            capability scoring, threat scoring and solution scoring is previewed
            here and the lists are stack ranked by score. You can leverage the
            filters on the left to show specific capabilities in relation to:
          </p>
        </>
      )}
      {safeMode === 'architect' && (
        <>
          <h2 className={appStyles.h2}>SAFE Architecture – Gap Report (2)</h2>
          <p className='mb-4'>
            The final gapping report is displayed on this page. You can leverage
            the filters on the left to show specific capabilities in relation
            to:
          </p>
        </>
      )}
      <HelpContentStandardFilters />
      <p>
        To export this report in a raw data format hit the button labeled,&nbsp;
        <strong>“Download Sheet.”</strong> To export this report in an image
        format applicable for import into the PowerPoint report template hit the
        button labeled,&nbsp;<strong>“Download Image.”</strong> (Image may need
        cropping within PowerPoint)
      </p>
    </>
  );
};

export default ReportHelp;
