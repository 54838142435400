import React, { useEffect, useMemo, useState } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import Step from '../complib/Step';
import Steps from '../complib/Steps';
import appStyles from '../support/appStyles';
import { useQueryItems } from '../support/queries';
import { ItemSelections } from '../support/selections.store';
import {
  BctSettings,
  CapabilitySettings,
  useBctRiskSelections,
  useCapabilitySelections,
  useCustomCapabilitySelections,
  usePinFilterSelections,
  useSafeSettingsSelections,
} from '../support/stores';
import BCTRiskPage from './BCTRiskPage';
import CapabilityPage from './CapabilityPage';
import ReportPage from './Report/ReportPage';
import { isCurrent } from './safe-utils';
import SafeFileActionsMenu from './SafeFileActionsMenu';
import SafeFlowChart from './SafeFlowChart/SafeFlowChart';
import SafeRecommendations from './SafeRecommendations';
import SafeUsageSurveyModal from './SafeUsageSurveyModal';

const Safe = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const riskData = useQueryItems('risk');
  const { batchSetSettings: batchSetCapabilities } = useCapabilitySelections();
  const { selections: customCapabilitySelections } =
    useCustomCapabilitySelections();
  const { selections: pinFilterSelections } = usePinFilterSelections();
  const { setSetting: setSafeSetting, getSetting: getSafeSetting } =
    useSafeSettingsSelections();
  const { isInitialized, selections: selectedBcts } = useBctRiskSelections();
  const [enoughRisk, setEnoughRisk] = useState<boolean>(false);

  const capabilityWorker: Worker = useMemo(
    () => new Worker('/capability-worker.js'),
    []
  );

  const pinsReady = !!Object.keys(pinFilterSelections ?? {}).length;

  useEffect(() => {
    if (location.pathname.endsWith('bct-risk')) {
      return;
    }
    if (location.pathname.endsWith('safe-flow') && pinsReady && enoughRisk) {
      return;
    }
    if (enoughRisk) {
      return;
    }
    navigate('bct-risk');
  }, [navigate, enoughRisk, location, pinsReady]);

  useEffect(() => {
    if (!isInitialized()) {
      return;
    }
    capabilityWorker.postMessage([
      selectedBcts,
      riskData,
      Object.values(customCapabilitySelections),
    ]);
    capabilityWorker.onmessage = (event: MessageEvent) => {
      const { data } = event;
      const capabilityItems: ItemSelections<CapabilitySettings> = {};
      Object.keys(data.payload.capScores).forEach(
        (key) =>
          (capabilityItems[parseInt(key)] = {
            ...data.payload.capScores[key],
            id: parseInt(key),
          })
      );
      batchSetCapabilities(capabilityItems, { score: 0 });
      setSafeSetting('capabilityMaxScore', data.payload.max ?? 58);
    };
    setEnoughRisk(
      isInitialized() &&
        Object.values(selectedBcts).some(
          (b: BctSettings) =>
            b.active &&
            Object.values(b.risks ?? {}).filter((r) => r.active).length
        )
    );
  }, [
    selectedBcts,
    riskData,
    capabilityWorker,
    batchSetCapabilities,
    setSafeSetting,
    isInitialized,
    customCapabilitySelections,
  ]);

  return (
    <section className={`${appStyles.container} mt-3 block`}>
      <div>
        <Steps>
          <Step
            title='BCTs &amp; Threats'
            position={1}
            enabled={true}
            active={isCurrent('bct-risk')}
            link='bct-risk'
          ></Step>

          <Step
            title='Capabilities'
            position={2}
            enabled={enoughRisk}
            active={isCurrent('capability')}
            link='capability'
          ></Step>

          <Step
            title='Gap Report'
            position={3}
            enabled={enoughRisk && pinsReady}
            active={isCurrent('report')}
            link='report'
          ></Step>

          <Step
            title='Flow Diagram'
            position={4}
            enabled={enoughRisk && pinsReady}
            active={isCurrent('safe-flow')}
            link='safe-flow'
          ></Step>
          <Step
            title='Recommendations'
            position={5}
            enabled={enoughRisk && pinsReady}
            active={isCurrent('recommendations')}
            link='recommendations'
          ></Step>
        </Steps>
        <hr className='bg-none border-b border-b-gray-400 my-2' />
        <Routes>
          <Route path='/' element={<Navigate replace to='bct-risk' />} />
          <Route path='bct-risk' element={<BCTRiskPage />} />
          {enoughRisk && (
            <>
              <Route path='capability' element={<CapabilityPage />} />
              {pinsReady && (
                <>
                  <Route path='report' element={<ReportPage />} />
                  <Route path='safe-flow' element={<SafeFlowChart />} />
                  <Route
                    path='recommendations'
                    element={<SafeRecommendations safeMode='risk' />}
                  />
                </>
              )}
            </>
          )}
        </Routes>
      </div>
      {(getSafeSetting('showSurvey') as boolean) && (
        <SafeUsageSurveyModal context='safe-risk' />
      )}
      <SafeFileActionsMenu
        redirect='bct-risk'
        disableExport={location.pathname.endsWith('bct-risk')}
      />
    </section>
  );
};

export default Safe;
