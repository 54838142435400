import saveAs from 'file-saver';
import html2canvas from 'html2canvas';
import React, { useEffect, useState } from 'react';
import { ArrowDown, ArrowRight, Download, X } from 'react-feather';
import Button from '../complib/Button';
import ModalFrame from '../complib/ModalFrame';
import { useQueryItems } from '../support/queries';
import { BctSettings, useBctRiskSelections } from '../support/stores';
import { Risk } from '../types/risk';
import JSZip from 'jszip';
import { openSurveyOnce } from './safe-utils';

const styles = {
  circ: [
    'rounded-full',
    'flex',
    'flex-col',
    'items-center',
    'justify-center',
  ].join(' '),
};

interface Props {
  close: () => void;
  isOpen: boolean;
}

const GoalBlob = () => (
  <div className={`${styles.circ} bg-sec-light-gray w-24 h-24`}>
    <div className='text-center leading-none font-cisco vertical-offset'>
      Goal / Initiative
    </div>
    <ArrowRight size={32} className='font-bold text-white' />
  </div>
);

const getRiskScore = (
  riskId: number,
  bct: BctSettings,
  riskData: Risk[]
): number => {
  const risk = bct.risks[riskId];
  if (risk.score) {
    return risk.score;
  } else {
    const groupName = riskData.find((r) => r.id === (riskId as number))?.impact;
    return (bct.groups ?? {})[groupName as string]?.score ?? 100;
  }
};

// const getBCTScore = (bct: BctSettings, riskData: Risk[]): number => {
//   let riskScores = 0;
//   Object.entries(bct.risks)
//     .filter(([, risk]) => risk.active)
//     .forEach(
//       ([id]) => (riskScores += getRiskScore(parseInt(id), bct, riskData))
//     );
//   return (bct.score ?? 100) * riskScores;
// };

const getBCTRisks = (bct: BctSettings, riskData: Risk[]): string[] => {
  const risks: any[] = [];
  Object.entries(bct.risks)
    .filter(([, r]) => r.active)
    .forEach(([id, r]) => {
      risks.push({ ...r, id: parseInt(id) });
    });
  return Object.values(risks)
    .filter((r) => getRiskScore(r.id, bct, riskData) === 100)
    .sort((a, b) => {
      const aScore = getRiskScore(a.id, bct, riskData);
      const bScore = getRiskScore(b.id, bct, riskData);
      if (aScore === bScore) {
        return a.name > b.name ? 1 : -1;
      }
      return aScore < bScore ? 1 : -1;
    })
    .slice(0, 6)
    .map((r) => r.name as string);
};

const BCTRiskPrintModal = ({ close, isOpen }: Props) => {
  const riskData = useQueryItems('risk');
  const { selections, isInitialized } = useBctRiskSelections();
  const [ordered, setOrdered] = useState<BctSettings[][]>();

  useEffect(() => {
    if (!isInitialized || !riskData) {
      return;
    }
    const prioritized = Object.values(selections)
      .filter(
        (bs: BctSettings) =>
          bs.active &&
          Object.keys(bs.risks).some((id) => {
            const riskScore = getRiskScore(parseInt(id), bs, riskData);
            return riskScore === 100;
          })
      )
      .sort((a: BctSettings, b: BctSettings) => {
        // const aScore = getBCTScore(a, riskData);
        // const bScore = getBCTScore(b, riskData);
        // return bScore > aScore ? 1 : -1;
        return a.score < b.score ? 1 : -1;
      });
    const newOrder = [];
    for (let i = 0, il = prioritized.length + 1; i < il; i += 3) {
      const bctPage = [];
      for (let j = 0; j < 3; j++) {
        if (i + j < il - 1) {
          bctPage.push(prioritized[i + j]);
        }
      }
      newOrder.push(bctPage);
    }
    setOrdered(newOrder);
  }, [selections, isInitialized, riskData]);

  const downloadImages = async () => {
    openSurveyOnce();
    const zip = new JSZip();
    const promises: Promise<HTMLCanvasElement>[] = [];
    ordered?.forEach((bct, i) => {
      const id = `#bctImageContainer-${i}`;
      const sourceElement = document.querySelector(id) as HTMLElement;
      if (!sourceElement) {
        console.error('Unable to find page elements for image.');
        return '';
      }
      promises.push(
        html2canvas(sourceElement, {
          windowWidth: 1920,
          scale: 3,
          onclone: (documentClone) => {
            const headingEls = documentClone.querySelectorAll(
              '.vertical-offset'
            ) as NodeListOf<HTMLElement>;
            headingEls.forEach((h: HTMLElement) => {
              h.style.paddingBottom = '15px';
            });
          },
        })
      );
    });
    Promise.all(promises).then((canvases) => {
      canvases.forEach((canvas, i) => {
        const imageData = canvas.toDataURL('image/png');
        zip.file(
          `BCT_Threats_Alignment_${i + 1}.png`,
          imageData.substr(imageData.indexOf(',') + 1),
          { base64: true }
        );
      });
      return zip.generateAsync({ type: 'blob' }).then((blob) => {
        saveAs(
          blob,
          `BCT_Threats_Alignment_Images_${new Date().getTime()}.zip`
        );
      });
    });
  };

  return (
    <ModalFrame isOpen={isOpen} close={close} cssClass='w-11/12 max-w-11/12'>
      <section className='overflow-auto max-h-[70vh] max-w-fitbg-white text-sec-dark-gray border-gray-200 border-8'>
        {ordered
          ?.filter((bcts) => !!bcts.length)
          .map((bcts: BctSettings[], i) => (
            <div
              className='w-[1340px] h-[710px] box-border bg-white mb-8 last:mb-0'
              id={`bctImageContainer-${i}`}
              key={i}
            >
              <h2 className='text-5xl font-cisco mb-4 vertical-offset px-8 pt-8'>
                Alignment of Threats to BCT
              </h2>
              <div className='grid grid-cols-[1fr_4fr_4fr_4fr_1fr]'>
                <div></div>
                <div>
                  <div
                    className={`${styles.circ} w-32 h-32 bg-sec-blue text-white`}
                  >
                    <div className='-mb-1 font-cisco text-xl vertical-offset'>
                      Function
                    </div>
                    <ArrowDown size={40} />
                  </div>
                </div>
                <div>
                  <div
                    className={`${styles.circ} w-32 h-32 bg-pri-sky text-white`}
                  >
                    <div className='-mb-1 font-cisco text-xl vertical-offset'>
                      Technology
                    </div>
                    <ArrowDown size={40} />
                  </div>
                </div>
                <div>
                  <div
                    className={`${styles.circ} w-32 h-32 bg-sec-red text-white`}
                  >
                    <div className='-mb-1 font-cisco text-xl vertical-offset'>
                      Threats
                    </div>
                    <ArrowDown size={40} />
                  </div>
                </div>
                <div></div>
                {bcts?.map((bct, i) => (
                  <React.Fragment key={i}>
                    <div className='border-t border-sec-light-gray py-2 mt-2 px-8'>
                      <GoalBlob />
                    </div>
                    <div className='border-t border-sec-light-gray py-2 mt-2 text-2xl font-cisco pr-8'>
                      {bct.name}
                    </div>
                    <div className='border-t border-sec-light-gray py-2 mt-2 pr-8'>
                      {bct.description}
                    </div>
                    <div className='border-t border-sec-light-gray py-2 mt-2 pr-4'>
                      {getBCTRisks(bct, riskData).map((r) => (
                        <div key={r}>{r}</div>
                      ))}
                    </div>
                    <div className='border-t border-sec-light-gray py-2 mt-2 px-8'>
                      <div
                        className={`${styles.circ} bg-sec-dark-gray text-white font-bold w-20 h-20 text-2xl vertical-offset`}
                      >
                        {bct.score ?? 0}
                      </div>
                    </div>
                  </React.Fragment>
                ))}
              </div>
            </div>
          ))}
      </section>
      <div className='flex justify-end mt-6'>
        <Button
          onClick={close}
          className='flex mt-2 mr-2 items-center'
          theme='ghost'
        >
          <X size={20} className='mr-2' />
          Cancel
        </Button>
        <Button onClick={downloadImages} className='flex mt-2 mr-0'>
          <Download size={20} className='mr-2' />
          Download
        </Button>
      </div>
    </ModalFrame>
  );
};

export default BCTRiskPrintModal;
