import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'react-feather';
import appStyles from '../support/appStyles';

interface Props {
  value?: string;
  options: string[];
  onChange: (value: string) => void;
  placeholder?: string;
}

const Dropdown = ({ options, placeholder, onChange, value }: Props) => {
  const selectItem = (item: string) => {
    onChange(item);
    setIsOpen(false);
  };
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <div className='relative'>
      <div
        tabIndex={0}
        onClick={() => setIsOpen(!isOpen)}
        className={`min-w-[20rem] relative ${appStyles.input} font-light p-1 bg-white`}
      >
        {value && <span>{value}</span>}
        {!value && (
          <span className='text-gray-400'>{placeholder ?? 'Select item'}</span>
        )}
        {!isOpen && <ChevronDown className='absolute top-1 right-2' />}
        {isOpen && <ChevronUp className='absolute top-1 right-2' />}
      </div>
      <ul
        className={`border rounded-xs absolute z-10 ${
          isOpen ? 'border border-gray-300' : ''
        } max-h-96 overflow-y-auto`}
      >
        {isOpen &&
          options.map((item, index) => (
            <li
              onClick={() => selectItem(item)}
              className={`odd:bg-gray-100 ${
                value === item
                  ? 'bg-sky odd:bg-sky hover:bg-sky-darker text-white'
                  : 'bg-white hover:bg-gray-200'
              }  py-1 px-4 text-sm`}
              key={`${index}`}
            >
              {item}
            </li>
          ))}
      </ul>
    </div>
  );
};

export default Dropdown;
