import React from 'react';
import { itemStatusCircleStyle } from './report-utils';

const ReportKey = () => (
  <div className='border border-gray-400 rounded-sm mt-6 px-6 py-4 w-fit flex'>
    <div className='pr-6 border-r border-r-gray-400 mr-6'>
      <h3 className='font-cisco mb-2 text-center image-vertical-offset'>
        Coverage
      </h3>
      <div className='flex'>
        <div className='mr-4 text-center flex flex-col items-center'>
          <div className={`${itemStatusCircleStyle} bg-danger`}>
            <div className='image-circle-offset'>No</div>
          </div>
          <div className='text-sm mt-1 image-vertical-offset'>None</div>
        </div>
        <div className='mr-4 text-center flex flex-col items-center'>
          <div className={`${itemStatusCircleStyle} bg-sky-darker`}>
            <div className='image-circle-offset'>Pl</div>
          </div>
          <div className='text-sm mt-1 image-vertical-offset'>Planned</div>
        </div>
        <div className='mr-4 text-center flex flex-col items-center'>
          <div className={`${itemStatusCircleStyle} bg-warning`}>
            <div className='image-circle-offset'>Pa</div>
          </div>
          <div className='text-sm mt-1 image-vertical-offset'>Partial</div>
        </div>
        <div className='text-center flex flex-col items-center'>
          <div className={`${itemStatusCircleStyle} bg-success`}>
            <div className='image-circle-offset'>Fu</div>
          </div>
          <div className='text-sm mt-1 image-vertical-offset'>Full</div>
        </div>
      </div>
    </div>
    <div className=''>
      <h3 className='font-cisco mb-2 text-center image-vertical-offset'>
        Product type
      </h3>
      <div className='flex'>
        <div className='mr-4 text-center flex flex-col items-center'>
          <div className={`${itemStatusCircleStyle} bg-sky-darker`}>
            <div className='image-circle-offset'>C</div>
          </div>
          <div className='text-sm mt-1 image-vertical-offset'>Cisco</div>
        </div>
        <div className='text-center flex flex-col items-center'>
          <div className={`${itemStatusCircleStyle} bg-success-darker`}>
            <div className='image-circle-offset'>3</div>
          </div>
          <div className='text-sm mt-1 image-vertical-offset'>Third party</div>
        </div>
      </div>
    </div>
  </div>
);

export default ReportKey;
