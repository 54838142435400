import React from 'react';
import appStyles from '../support/appStyles';

interface TableData {
  columns: string[];
  rows: string[][];
}

interface Props {
  tableData: TableData;
}

const ItemsTable = ({ tableData }: Props) => {
  return (
    <table className={`${appStyles.table} border border-gray-300 rounded-sm`}>
      <thead>
        <tr className={appStyles.ths}>
          {tableData.columns.map((column: string, i) => (
            <th key={i} className={appStyles.cell}>
              {column}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {tableData.rows.map((row: string[], i) => (
          <tr key={i} className={appStyles.tr}>
            {tableData.columns.map((column: string, j) => (
              <td key={j} className={appStyles.cell}>
                {row[j]}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ItemsTable;
export { TableData };
