import React from 'react';
import Button from '../../complib/Button';

interface Props {
  isNew: () => boolean;
  displayCategory: string;
  deleteItem: () => void;
  close: () => void;
}

const FormActionButtons = ({
  deleteItem,
  isNew,
  displayCategory,
  close,
}: Props) => {
  return (
    <div className='flex justify-end'>
      <Button theme='ghost' onClick={close} className='mt-4 mr-4'>
        Cancel
      </Button>
      {!isNew() && (
        <Button theme='danger' className='mt-4 mr-4' onClick={deleteItem}>
          Delete&hellip;
        </Button>
      )}
      <Button
        type='submit'
        className='capitalize mt-4'
        theme={isNew() ? 'success' : 'primary'}
      >
        {isNew() ? `Add new ${displayCategory}` : 'Save changes'}
      </Button>
    </div>
  );
};

export default FormActionButtons;
