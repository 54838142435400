import create from 'zustand';

type Setting = number | string | string[] | boolean | null;

interface Settings {
  [name: string]: Setting;
}

interface SettingsState {
  settings: Settings;
  removeSetting: (name: string) => void;
  getSetting: (name: string, fallback?: Setting) => Setting | undefined;
  setSetting: (name: string, value: Setting) => void;
  clearSettings: () => void;
}

const getSettingsStoreHook = (
  settings: Settings = {},
  persist?: (settings: Settings) => void
) =>
  create<SettingsState>((set, get) => ({
    settings,
    removeSetting: (name: string) =>
      set((state) => {
        delete state.settings[name];
        if (persist) {
          persist(state.settings);
        }
        return { settings: state.settings };
      }),
    setSetting: (name: string, value: Setting) =>
      set((state) => {
        const newSettings = {
          ...state.settings,
          [name]: value,
        };
        if (persist) {
          persist(newSettings);
        }
        return {
          settings: newSettings,
        };
      }),
    getSetting: (name: string, fallback?: Setting) =>
      get().settings[name] ?? fallback,
    clearSettings: () =>
      set(() => {
        if (persist) {
          persist({});
        }
        return { settings: {} };
      }),
  }));

export default getSettingsStoreHook;
export { Setting, Settings, SettingsState };
