import React from 'react';
import { Check, X } from 'react-feather';
import {
  Controller,
  SubmitHandler,
  useForm,
  UseFormReturn,
} from 'react-hook-form';
import Modal from 'react-modal';
import Button from '../complib/Button';
import appStyles from '../support/appStyles';
import { useSafeSettingsSelections } from '../support/stores';
import { toast } from 'react-hot-toast/headless';
import { postItem } from '../support/queries';

interface SurveyValues {
  amcss_name: string;
  tsase_name: string;
  area: string;
  customer_name: string;
  vertical: string;
  partner_company: string;
  partner_rep: string;
  is_related_deal_ea: boolean;
  deal_size: string;
  deal_id: string;
  deal_desc: string;
  usefor_safelite: boolean;
  usefor_safefull: boolean;
  usefor_fworkmap: boolean;
  if_safe_aware_enablement_site: boolean;
  if_safe_will_use_pp_template: boolean;
  if_fwmap_fw_mapped_to: string;
}

interface InputProps {
  type: 'boolean' | 'string';
  limit?: 100 | 1000;
  name: keyof SurveyValues;
  title: string;
  required?: boolean;
  formConfig: UseFormReturn<SurveyValues, any>;
}
const InputElement = (props: InputProps) => {
  const validationConfig: { required: boolean; limit?: number } = {
    required: !!props.required,
  };
  if (props.limit) {
    validationConfig.limit = props.limit;
  }

  return (
    <div className='flex-col flex mt-4'>
      <label className='text-sm'>
        {props.title}{' '}
        {props.required && props.type === 'string' && (
          <span className='font-bold text-sec-red'>*</span>
        )}
      </label>
      {props.type === 'string' && (
        <input
          className={`${appStyles.input} text-lg py-1 ${
            (props.required &&
              props.formConfig.formState.touchedFields[props.name] &&
              !(props.formConfig.getValues(props.name) as string).trim()) ||
            (props.limit &&
              (props.formConfig.getValues(props.name) as string)?.length >
                props.limit)
              ? appStyles.inputError
              : ''
          }`}
          {...props.formConfig.register(props.name, validationConfig)}
        />
      )}
      {props.type === 'boolean' && (
        <Controller
          control={props.formConfig.control}
          name={props.name}
          defaultValue={props.formConfig.getValues()[props.name]}
          render={({ field }) => (
            <>
              <Check
                className={`flex-0 rounded-full mt-1 p-1 transition-colors cursor-pointer ${
                  field.value
                    ? 'bg-sec-green text-white hover:bg-success-darker'
                    : 'bg-gray-400 text-gray-400 hover:bg-gray-500'
                } cursor-pointer w-7 h-7 flex-0`}
                onClick={() => field.onChange(!field.value)}
              ></Check>
            </>
          )}
        />
      )}
    </div>
  );
};

interface Props {
  context: 'safe-risk' | 'safe-architect' | 'framework';
}

const SafeUsageSurveyModal = ({ context }: Props) => {
  const { getSetting, setSetting } = useSafeSettingsSelections();

  const formConfig = useForm<SurveyValues>({
    defaultValues: {
      usefor_safefull: context === 'safe-risk',
      usefor_safelite: context === 'safe-architect',
      usefor_fworkmap: context === 'framework',
      if_safe_aware_enablement_site: false,
      is_related_deal_ea: false,
    },
  });
  const close = () => setSetting('showSurvey', false);

  // this needs to send POST once configured with correct form / object
  const onSubmit: SubmitHandler<SurveyValues> = (data) => {
    // send data
    console.info('submit survey', data);
    postItem('questionnaire', data as { id?: number });
    toast.success('Form sent. Thank you.', {
      id: 'customer-form-' + new Date().getTime(),
    });
    setSetting('sentSurvey', true);
    close();
  };

  const onOptOut = () => {
    setSetting('sentSurvey', true);
    close();
  };

  return (
    <Modal
      isOpen={getSetting('showSurvey') as boolean}
      onRequestClose={close}
      overlayClassName='bg-[rgba(0,0,0,0.2)] fixed top-0 left-0 right-0 bottom-0 flex h-[100vh] w-[100vw] items-center justify-center z-20'
      className='max-h-[83vh] relative bg-gray-200 rounded-sm shadow-lg p-6 w-5/6 max-w-xl overflow-y-auto'
    >
      <Button
        theme='ghost'
        onClick={close}
        className='top-4 right-4 px-1 py-1 absolute mr-0'
      >
        <X size={16} />
      </Button>
      <h2 className='font-cisco text-xl mb-4 text-sec-dark-gray'>
        Help us to improve Risk and Compliance Tool
      </h2>
      <form
        onSubmit={formConfig.handleSubmit(onSubmit)}
        className='flex-col flex text-sec-dark-gray h-full'
      >
        <div>
          <div className='flex justify-end mt-6'>
            <p className='font-sans font-light text-sec-dark-gray mb-2'>
              We are using anonymous data to understand usage and to make
              improvements. this should be completed once for every time you
              &ldquo;Start over&rdquo;.
              <br />
              <a
                href='https://cisco.sharepoint.com/sites/SAFEWorkshops'
                target='_blank'
                rel='noreferrer'
                className='text-sec-blue no-underline hover:underline'
              >
                Complimentary SAFE Workshop Enablement SharePoint website
              </a>
            </p>
          </div>
          <div className='flex flex-col justify-end mt-2'>
            <InputElement
              type='string'
              title='Cisco AM/CSS Name'
              name='amcss_name'
              limit={100}
              required={true}
              formConfig={formConfig}
            />
            <InputElement
              type='string'
              title='Cisco TSA/SE Name'
              name='tsase_name'
              limit={100}
              required={true}
              formConfig={formConfig}
            />
            <InputElement
              type='string'
              title='Area (US/EMEAR/APJC)'
              name='area'
              limit={100}
              required={true}
              formConfig={formConfig}
            />
            <InputElement
              type='string'
              title='Customer Name'
              name='customer_name'
              limit={100}
              required={true}
              formConfig={formConfig}
            />
            <InputElement
              type='string'
              title='Vertical'
              name='vertical'
              limit={100}
              required={true}
              formConfig={formConfig}
            />
            <InputElement
              type='string'
              title='Partner Company Involved'
              name='partner_company'
              limit={100}
              formConfig={formConfig}
            />
            <InputElement
              type='string'
              title='Partner Representative Name'
              name='partner_rep'
              limit={100}
              formConfig={formConfig}
            />
            <InputElement
              type='boolean'
              title='Is this related to any past or future deals or EAs?'
              name='is_related_deal_ea'
              required={true}
              formConfig={formConfig}
            />
            {formConfig.watch('is_related_deal_ea') === true && (
              <>
                <InputElement
                  type='string'
                  title='Deal Size'
                  name='deal_size'
                  limit={100}
                  formConfig={formConfig}
                />
                <InputElement
                  type='string'
                  title='Deal ID'
                  name='deal_id'
                  limit={100}
                  formConfig={formConfig}
                />
                <InputElement
                  type='string'
                  title='Deal Description'
                  name='deal_desc'
                  limit={1000}
                  formConfig={formConfig}
                />
              </>
            )}
            {(context === 'safe-risk' || context === 'safe-architect') && (
              <>
                <InputElement
                  type='boolean'
                  title='For your final report will you use the SAFE PowerPoint report
            template provided on the SAFE Workshop Enablement SharePoint website?'
                  name='if_safe_will_use_pp_template'
                  formConfig={formConfig}
                />
              </>
            )}
            {context === 'framework' && (
              <InputElement
                type='string'
                title='What framework(s) were you mapping to?'
                name='if_fwmap_fw_mapped_to'
                limit={100}
                formConfig={formConfig}
              />
            )}
          </div>
          <div className='flex justify-end mt-6'>
            <Button
              onClick={close}
              className='flex items-center mt-2 mr-2'
              theme='ghost'
            >
              <X size={20} className='mr-2' />
              Complete later
            </Button>
            <Button
              onClick={onOptOut}
              className='flex items-center mt-2 mr-2'
              theme='ghost'
            >
              <X size={20} className='mr-2' />
              Don&apos;t ask again
            </Button>
            <Button
              type='submit'
              className={`flex items-center mt-2 ${
                formConfig.formState.isValid
                  ? ''
                  : 'cursor-not-allowed opacity-50'
              }`}
              theme='success'
            >
              <Check size={20} className='mr-2' />
              Done
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default SafeUsageSurveyModal;
