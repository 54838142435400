import React, { useState } from 'react';
import { useEffect } from 'react';
import { Check, Plus, X } from 'react-feather';
import Modal from 'react-modal';
import Button from '../complib/Button';
import appStyles from '../support/appStyles';
import {
  CapabilityProduct,
  CapabilitySettings,
  useCapabilitySelections,
  useSafeSettingsSelections,
  useThirdPartyProductSettings,
} from '../support/stores';

interface Props {
  id: number;
  prop: 'products' | 'proposed';
  className: string;
}

const CapabilityProductSelector = ({ id, prop, className }: Props) => {
  const {
    getItemSetting,
    setItemSetting,
    selections: capabilitySelections,
  } = useCapabilitySelections();
  const { settings: safeSettings, getSetting: getSafeSetting } =
    useSafeSettingsSelections();
  const capabilityProducts = () =>
    getItemSetting(id, 'products', []) as CapabilityProduct[];
  const { setSetting: setProduct3, settings: product3Settings } =
    useThirdPartyProductSettings();
  const thisProducts = () =>
    getItemSetting(
      id,
      prop,
      capabilityProducts()
        .filter((p) => p.type === 'cisco')
        .map((p) => ({ name: p.name, type: 'cisco', active: false }))
    ) as CapabilityProduct[];
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [newProduct, setNewProduct] = useState<string>('');
  const [capabilityMode, setCapabilityMode] = useState('all');
  const [capabilityModeName, setCapabilityModeName] = useState<string | null>(
    null
  );

  const setActive = (pName: string, active = true) => {
    const context = [...thisProducts()];
    let product = context.find((p) => p.name === pName);
    if (!product) {
      product = {
        name: pName,
        active,
        type: '3rd-party',
      };
      context.push(product);
    }
    product.active = active;
    setItemSetting(id, prop, context);
  };

  const addProduct = () => {
    const product = newProduct.trim();
    if (!product) {
      return;
    }
    const products = [...thisProducts()];
    if (!products.find((p) => p.name === product)) {
      products.push({
        name: product,
        type: '3rd-party',
        active: true,
      });
      setProduct3(product, true);
      setItemSetting(id, prop, products);
    }
    setNewProduct('');
  };

  useEffect(() => {
    let TPProducts: string[] = [];
    if (capabilityMode !== 'product') {
      Object.values(capabilitySelections).forEach((cs: CapabilitySettings) => {
        if (cs.active) {
          const products = (cs.products ?? [])
            .filter((p) => p.type === '3rd-party')
            .map((p) => p.name);
          TPProducts = [...new Set([...TPProducts, ...products])];
        }
      });
    }
  }, [capabilitySelections, capabilityMode]);

  useEffect(() => {
    setCapabilityMode(getSafeSetting('capabilityModeType', 'all') as string);
    setCapabilityModeName(
      getSafeSetting('capabilityModeName', null) as string | null
    );
  }, [safeSettings, getSafeSetting]);

  // 3rd party selector: show selected on capability row
  const toggleShowProduct = (product: string) => {
    const cProducts = [
      ...((getItemSetting(id, prop) as CapabilityProduct[]) ?? []),
    ];
    const index = cProducts.findIndex((cp) => cp.name === product);
    if (index > -1) {
      cProducts.splice(index, 1);
      setItemSetting(id, prop, cProducts);
      return;
    }
    cProducts.push({ name: product, type: '3rd-party', active: true });
    setItemSetting(id, prop, cProducts);
  };

  return (
    <div className={`flex ${className} relative`}>
      <div className={`flex-1 flex flex-wrap mt-2 mb-1 relative`}>
        {thisProducts()
          .filter(
            (cp) =>
              capabilityMode !== 'product' || capabilityModeName === cp.name
          )
          .map((cp, i) => (
            <div
              className={`mr-1 mb-1 last:mr-0 px-4 py-1 rounded ${
                cp.active
                  ? cp.type === 'cisco'
                    ? 'bg-sky hover:bg-sky-lighter'
                    : 'bg-success hover:bg-success-lighter'
                  : 'bg-gray-300 hover:bg-gray-400 text-gray-500 hover:text-gray-700'
              } cursor-pointer text-sm font-semibold truncate max-w-[332px]`}
              key={cp.name + i}
              onClick={() => setActive(cp.name, !cp.active)}
            >
              {cp.name}
            </div>
          ))}
      </div>
      {capabilityMode !== 'product' && (
        <Button
          className='px-1 text-black flex-0 self-center'
          onClick={() => setIsEditing(!isEditing)}
          theme='ghost'
          title='Add a 3rd-Party Product'
        >
          <Plus size={18} />
        </Button>
      )}
      {isEditing && (
        <Modal
          isOpen={isEditing}
          onRequestClose={() => setIsEditing(false)}
          overlayClassName='bg-[rgba(0,0,0,0.2)] fixed top-0 left-0 right-0 bottom-0 flex h-[100vh] w-[100vw] items-center justify-center z-10'
          className='max-h-[83vh] relative bg-gray-200 rounded-sm shadow-lg p-6 w-5/6 max-w-xl'
        >
          <Button
            theme='ghost'
            onClick={() => setIsEditing(false)}
            className='top-4 right-4 px-1 py-1 absolute mr-0'
          >
            <X size={16} />
          </Button>
          <h2 className='font-cisco text-xl mb-4'>Select 3rd Party Products</h2>
          <div>
            <div className='flex flex-wrap'>
              {Object.keys(product3Settings).map((product: string) => (
                <div
                  key={product}
                  onClick={() => toggleShowProduct(product)}
                  className={`mr-1 mb-1 last:mr-0 px-4 py-1 rounded cursor-pointer ${
                    (
                      (getItemSetting(id, prop) as CapabilityProduct[]) ?? []
                    ).find((c) => c.name === product)
                      ? 'bg-sec-green'
                      : 'bg-sec-light-gray'
                  } text-sm font-semibold`}
                >
                  {product}
                </div>
              ))}
            </div>
            <div className='relative flex items-center'>
              <input
                autoFocus
                placeholder='Add third-party product to list'
                className={`${appStyles.input} my-4 px-4 py-2 text-xl w-full`}
                type='text'
                value={newProduct}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    addProduct();
                  }
                  if (e.key === 'Escape' && !!newProduct) {
                    e.stopPropagation();
                    setNewProduct('');
                  }
                }}
                onChange={(e) => setNewProduct(e.target.value)}
              />
              {!!newProduct.trim() && (
                <Button
                  className='absolute right-0 flex items-center'
                  onClick={addProduct}
                >
                  <Plus />
                  Add to list
                </Button>
              )}
            </div>
            <Button
              onClick={() => setIsEditing(false)}
              className='flex mt-2'
              theme='ghost'
            >
              <Check size={20} className='mt-[2px] mr-2' />
              Accept selected products
            </Button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default CapabilityProductSelector;
