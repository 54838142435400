import React, { useEffect, useState } from 'react';
import { Check, Minus } from 'react-feather';
import Card from '../complib/Card';
import Switch from '../complib/Switch';
import appStyles from '../support/appStyles';
import { useQueryItems } from '../support/queries';
import {
  RiskSettings,
  ScoreSettings,
  useBctRiskSelections,
  useSafeSettingsSelections,
} from '../support/stores';
import { sortedObjectArray } from '../support/utils';
import { Risk } from '../types/risk';
import RiskScoreSelector, { BCTRiskSeverity } from './RiskScoreSelector';
import { SortConfig } from './safe-utils';
import TableSortHeading from './TableSortHeading';

const cardColor = (i: number): string => {
  const r = 241 - i * 20;
  const g = 118;
  const b = 48 + i * 40;
  return `rgb(${r},${g},${b})`;
};

interface Props {
  bctKey: number;
  updateSort: (key: string) => void;
  sortConfig: SortConfig;
}

const BCTRiskPanel = ({ bctKey, updateSort, sortConfig }: Props) => {
  const data = useQueryItems('risk');

  const [allRisks, setAllRisks] = useState<{ [key: string]: Risk[] }>();
  const { selections, getItemSetting, setItemSetting } = useBctRiskSelections();
  const { getSetting: getSafeSetting, setSetting: setSafeSetting } =
    useSafeSettingsSelections();

  const selectedRisk = (riskId: number) => {
    return (
      (getItemSetting(bctKey, 'risks') as { [key: string]: RiskSettings })[
        riskId
      ] ?? { active: false }
    );
  };

  useEffect(() => {
    if (!data?.length) {
      return;
    }
    const grouped: { [key: string]: Risk[] } = {};
    data.forEach((risk: Risk) => {
      const category: string = risk.impact;
      grouped[category] = grouped[category] ?? [];
      grouped[category].push(risk);
    });
    Object.keys(grouped).forEach(
      (cat: string) =>
        (grouped[cat] = sortedObjectArray<Risk>(grouped[cat], 'name'))
    );
    setAllRisks(grouped);
  }, [data]);

  const showAll = (): boolean =>
    getSafeSetting('showAllRisks', true) as boolean;

  const updateShowAll = () => {
    setSafeSetting('showAllRisks', !showAll());
  };

  const setScore = (s: number, risk: Risk) => {
    const risks = getItemSetting(bctKey, 'risks') as {
      [key: string]: RiskSettings;
    };
    risks[risk.id].score = s;
    setItemSetting(bctKey, 'risks', risks);
  };

  const getGroupSettings = (groupName: string): ScoreSettings => {
    const groups = getItemSetting(bctKey, 'groups', {}) as {
      [key: string]: ScoreSettings;
    };
    const group = groups[groupName] ?? { active: true, settings: {} };
    return group;
  };

  const getGroupScore = (groupName: string) => {
    const groupSettings = getGroupSettings(groupName) ?? {};
    return groupSettings.score ?? 100;
  };

  const setGroupScore = (s: number, groupName: string) => {
    // reset all group risk scores
    const risks = getItemSetting(bctKey, 'risks') as {
      [key: string]: RiskSettings;
    };
    Object.keys(risks)
      .filter((key) => risks[key].group === groupName)
      .forEach((key) => (risks[key].score = undefined));
    setItemSetting(bctKey, 'risks', risks);
    const groups = getItemSetting(bctKey, 'groups') as {
      [key: string]: ScoreSettings;
    };
    groups[groupName].score = s;
    setItemSetting(bctKey, 'groups', groups);
  };

  const updateBctRiskCounts = () => {
    const activeRisks = Object.values(
      getItemSetting(bctKey, 'risks') as { [key: string]: RiskSettings }
    ).filter((r: RiskSettings) => r.active).length;
    setItemSetting(bctKey, 'count', activeRisks);
  };

  const toggleRisk = (risk: Risk) => {
    const riskId = risk.id.toString();
    const risks = getItemSetting(bctKey, 'risks') as {
      [key: string]: RiskSettings;
    };
    risks[riskId] = risks[riskId] ?? {
      score: null,
      group: risk.impact,
      name: risk.name,
    };
    risks[riskId].active = !risks[riskId].active;
    setItemSetting(bctKey, 'risks', risks);
    updateBctRiskCounts();
  };

  const toggleAll = (groupKey: string): void => {
    if (!allRisks) {
      return;
    }
    const newState = bctRiskSelectionState(groupKey) !== 'all';
    const risks = getItemSetting(bctKey, 'risks') as {
      [key: string]: RiskSettings;
    };
    if (!Object.keys(risks).length) {
      data.forEach((risk: Risk) => {
        risks[risk.id] = {
          id: risk.id,
          active: false,
          name: risk.name,
          group: risk.impact,
          score: getGroupScore(risk.impact),
        };
      });
    }
    allRisks[groupKey].forEach((r: Risk) => {
      if (risks[r.id]) {
        risks[r.id].active = newState;
      }
    });
    setItemSetting(bctKey, 'risks', risks);
    updateBctRiskCounts();
  };

  type BctRiskState = 'none' | 'some' | 'all';
  const bctRiskSelectionState = (groupKey: string): BctRiskState => {
    if (!allRisks) {
      return 'none';
    }
    let state: BctRiskState = 'none';
    if (
      allRisks[groupKey]?.every((risk: Risk) => selectedRisk(risk.id)?.active)
    ) {
      state = 'all';
    } else if (
      allRisks[groupKey]?.some((risk: Risk) => selectedRisk(risk.id)?.active)
    ) {
      state = 'some';
    }
    return state;
  };

  const calculateOverallRisk = (groupKey: string, risk: Risk): number =>
    Math.round(
      ((getItemSetting(bctKey, 'score', 100) as number) *
        (selectedRisk(risk.id).score ?? getGroupScore(groupKey))) /
        100
    ) ?? 100;

  const compareRisks = (a: Risk, b: Risk): number => {
    const property = sortConfig.key as 'name' | 'score';
    let aComp: number | string = a.name;
    let bComp: number | string = b.name;
    if (property === 'score') {
      const risks = getItemSetting(bctKey, 'risks', {}) as {
        [key: string]: RiskSettings;
      };
      const groups = getItemSetting(bctKey, 'groups') as {
        [key: string]: ScoreSettings;
      };
      const groupScore = groups[a.impact].score;
      const getComp = (risk: RiskSettings) =>
        risk.active ? (risk.score as number) ?? groupScore ?? 100 : 0;
      aComp = getComp(risks[a.id]);
      bComp = getComp(risks[b.id]);
    }
    if (aComp === bComp) {
      return a.name > b.name ? 1 : -1;
    }
    return aComp > bComp ? sortConfig.dir : -sortConfig.dir;
  };

  return (
    <section>
      <div className='flex justify-end h-0 items-center relative top-6'>
        <p className='mx-2 text-sm'>Show unused</p>
        <Switch value={showAll()} onClick={updateShowAll} />
      </div>
      <h2 className={appStyles.h2}>
        Threats ({getItemSetting(bctKey, 'name')})
      </h2>
      {bctKey && (
        <p className='text-sm mt-0 mb-2'>
          {getItemSetting(bctKey, 'description', '') as string}
        </p>
      )}
      <h3 className='font-semibold text-lg mb-4'>
        Base priority: {getItemSetting(bctKey, 'score') ?? 'Select Edit'}
      </h3>
      {/* {!selections[bctKey] && <Loader className='w-16 h-16' />} */}
      {allRisks &&
        selections[bctKey] &&
        Object.keys(allRisks)
          .filter(
            (groupKey: string) =>
              showAll() ||
              allRisks[groupKey].some((risk) => selectedRisk(risk.id).active)
          )
          .map((groupKey: string, i: number) => (
            <Card
              key={groupKey}
              className={`mb-6 border-l-[10px] px-0 py-0 relative`}
              style={{
                borderColor: cardColor(i),
              }}
            >
              <header className=''>
                <div className='px-4 flex items-center justify-items-center border-b border-gray-300'>
                  <h2 className={`flex-1 mt-2 mb-2 ${appStyles.h2}`}>
                    {groupKey}
                  </h2>
                  <RiskScoreSelector
                    getCurrentRiskScore={() =>
                      getGroupScore(groupKey) as BCTRiskSeverity
                    }
                    setScore={(s) => setGroupScore(s, groupKey)}
                  />
                  <div className='w-12'></div>
                </div>
                <div className='px-4 flex text-left text-sm bg-gray-100 py-2'>
                  <div
                    className='w-[28px] cursor-pointer'
                    onClick={() => toggleAll(groupKey)}
                  >
                    {(() => {
                      switch (bctRiskSelectionState(groupKey)) {
                        case 'none':
                          return (
                            <Check
                              className={`flex-0 rounded-full p-1 mr-2 transition-colors bg-gray-400 text-gray-400 w-5 h-5 flex-0`}
                            ></Check>
                          );
                        case 'some':
                          return (
                            <Minus
                              className={`flex-0 rounded-full p-1 mr-2 transition-colors bg-sec-green text-white w-5 h-5 flex-0`}
                            />
                          );
                        case 'all':
                          return (
                            <Check
                              className={`flex-0 rounded-full p-1 mr-2 transition-colors bg-sec-green text-white w-5 h-5 flex-0`}
                            ></Check>
                          );
                        default:
                          return null;
                      }
                    })()}
                  </div>
                  <TableSortHeading
                    updateSort={updateSort}
                    sort={sortConfig}
                    sortKey='name'
                    className='flex-1'
                  >
                    Risk
                  </TableSortHeading>
                  <TableSortHeading
                    updateSort={updateSort}
                    sort={sortConfig}
                    className='w-60'
                    sortKey='score'
                  >
                    Coverage
                  </TableSortHeading>
                  <TableSortHeading
                    updateSort={updateSort}
                    sort={sortConfig}
                    className='w-12'
                    sortKey='score'
                  >
                    Final
                  </TableSortHeading>
                </div>
              </header>
              {allRisks[groupKey]
                .filter((risk) => showAll() || selectedRisk(risk.id)?.active)
                .sort(compareRisks)
                .map((risk) => (
                  <div
                    className={`flex items-center border-gray-300 border-t min h-10 min-h-full ${
                      selectedRisk(risk.id)?.active ? '' : 'bg-gray-100'
                    } px-4`}
                    key={risk.id}
                  >
                    <Check
                      className={`flex-0 rounded-full p-1 mr-2 transition-colors cursor-pointer ${
                        selectedRisk(risk.id)?.active
                          ? 'bg-success text-white'
                          : 'bg-gray-400 text-gray-400'
                      } mr-2 cursor-pointer w-5 h-5 flex-0`}
                      onClick={() => toggleRisk(risk)}
                    ></Check>
                    <h3 className='font-cisco flex-1 whitespace-nowrap'>
                      {risk.name}
                    </h3>
                    {selectedRisk(risk.id).active && (
                      <>
                        <RiskScoreSelector
                          getCurrentRiskScore={() =>
                            (selectedRisk(risk.id).score as BCTRiskSeverity) ??
                            getGroupScore(groupKey)
                          }
                          setScore={(s) => setScore(s, risk)}
                        />
                        <div>
                          <div className='w-12 font-bold text-xl text-right'>
                            {calculateOverallRisk(groupKey, risk)}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                ))}
            </Card>
          ))}
    </section>
  );
};

export default BCTRiskPanel;
