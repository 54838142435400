import React from 'react';
import HelpContentSecurityFunction from './HelpContentSecurityFunction';

const HelpContentStandardFilters = () => (
  <ul className='mb-4 pl-4 list-disc'>
    <li>
      <strong>Place in the Network</strong> – applicable part of the network the
      control applies to
    </li>
    <li>
      <strong>Customer Maturity Level</strong> – level of acceptable risk based
      on assets and funding
    </li>
    <HelpContentSecurityFunction />
    <li>
      <strong>Coverage</strong> – current success level of a particular
      capability (<strong>No</strong>ne, <strong>Pl</strong>anned,{' '}
      <strong>Pa</strong>rtial, <strong>Co</strong>mplete)
    </li>
  </ul>
);

export default HelpContentStandardFilters;
