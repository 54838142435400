import React, { useState } from 'react';
import Card from '../../complib/Card';
import Loader from '../../complib/Loader';
import { getCapabilityArbitraryPriority } from '../../framework-product-tool/hardcodedFutureProblems';
import appStyles from '../../support/appStyles';
import { useQueryItems } from '../../support/queries';
import {
  CapabilityProduct,
  useCapabilitySelections,
  useCustomCapabilitySelections,
  useSafeSettingsSelections,
} from '../../support/stores';
import { Capability } from '../../types/capability';
import {
  SafeMode,
  scoreOpacity,
  SortConfig,
  statusSettings,
} from '../safe-utils';
import { heatColor } from '../SafeFlowChart/chart-constants';
import TableSortHeading from '../TableSortHeading';
import {
  changeSorting,
  DisplayType,
  itemDisplay,
  itemStatusCircleStyle,
  itemStyle,
  reportPrintOmitRows,
} from './report-utils';

interface Props {
  filteredCapabilities: string[] | undefined;
  select: (c: Capability | null) => void;
  highlight: number[];
  currentSelection: { id: string; type: string } | null;
  safeMode: SafeMode;
  productType: 'products' | 'proposed';
}

const ReportCapabilityPanel = ({
  filteredCapabilities,
  select,
  highlight,
  currentSelection,
  safeMode,
  productType,
}: Props) => {
  const capabilityData: Capability[] = useQueryItems('capability');
  const { getItemSetting: getCapabilitySetting } = useCapabilitySelections();
  const { selections: customCapabilitySelections } =
    useCustomCapabilitySelections();
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    key: 'score',
    dir: -1,
  });
  const [status] = useState(statusSettings);
  const { getSetting: getSafeSetting } = useSafeSettingsSelections();

  const showCapability = (cap: Capability): boolean =>
    (filteredCapabilities ?? []).includes(cap.name);

  const compareCapabilities = (a: Capability, b: Capability): number => {
    let aComp = (a.id ? getCapabilitySetting(a.id, sortConfig.key) : 0) as
      | number
      | string
      | CapabilityProduct[];
    let bComp = (b.id ? getCapabilitySetting(b.id, sortConfig.key) : 0) as
      | number
      | string
      | CapabilityProduct[];
    if (safeMode === 'architect' && sortConfig.key === 'score') {
      aComp = getCapabilityArbitraryPriority(a.name, a.domain);
      bComp = getCapabilityArbitraryPriority(b.name, b.domain);
    }
    if (sortConfig.key === 'status') {
      aComp = status.find((s) => s.name === aComp)?.sort ?? 0;
      bComp = status.find((s) => s.name === bComp)?.sort ?? 0;
    }
    if (aComp === bComp) {
      return a.name > b.name ? 1 : -1;
    }
    return aComp > bComp ? sortConfig.dir : -sortConfig.dir;
  };

  const heatBg = (cap: Capability): string => {
    let max = 58;
    if (safeMode === 'risk') {
      max = getSafeSetting('capabilityMaxScore') as number;
    }
    return heatColor(capabilityScore(cap) / max, scoreOpacity);
  };

  const capabilityScore = (cap: Capability): number => {
    if (safeMode === 'risk') {
      return (cap.id ? getCapabilitySetting(cap.id, 'score') : 0) as number;
    }
    return getCapabilityArbitraryPriority(cap.name, cap.domain);
  };

  const updateSort = (key: string) =>
    setSortConfig(changeSorting(sortConfig, key));

  const displayStatus = (cap: Capability): DisplayType =>
    itemDisplay(currentSelection, cap, 'capability', highlight);

  const getStatusBadge = (cId: number): { text: string; color: string } => {
    let statusName: string;
    if (productType === 'proposed') {
      statusName = 'Full';
    } else {
      const savedStatusName = getCapabilitySetting(
        cId,
        'status',
        'None'
      ) as string;
      statusName = savedStatusName;
    }
    return {
      text: statusName.substr(0, 2),
      color:
        statusSettings.find((s) => s.name === statusName)?.color ?? '#dee2e6',
    };
  };

  return (
    <section>
      {!capabilityData && <Loader className='w-16 h-16' />}
      {capabilityData && (
        <>
          <header className='text-center'>
            <h2 className={`${appStyles.h2} image-vertical-offset`}>
              Capabilities
            </h2>
            <div
              className='flex items-stretch text-sm mb-2 -mr-6'
              data-html2canvas-ignore
            >
              {safeMode === 'risk' && (
                <>
                  <TableSortHeading
                    updateSort={updateSort}
                    sort={sortConfig}
                    sortKey='score'
                    className='pl-4 w-[90px]'
                  >
                    Priority
                  </TableSortHeading>
                  <TableSortHeading
                    updateSort={updateSort}
                    sort={sortConfig}
                    sortKey='name'
                    className='flex-1 flex justify-items-center'
                  >
                    Capability
                  </TableSortHeading>
                  <TableSortHeading
                    updateSort={updateSort}
                    sort={sortConfig}
                    className='w-24 text-right'
                    sortKey='status'
                  >
                    Coverage
                  </TableSortHeading>
                </>
              )}
              {safeMode === 'architect' && (
                <>
                  <div className='flex-1 font-semibold text-left'>
                    Capability
                  </div>
                  <div className='w-24 text-right font-semibold mr-6'>
                    Coverage
                  </div>
                </>
              )}
            </div>
          </header>
          <Card className={`mb-6 p-0`}>
            {[...capabilityData, ...Object.values(customCapabilitySelections)]
              .filter((cap: Capability) => showCapability(cap))
              .sort(compareCapabilities)
              .map((cap: Capability, n) => (
                <div
                  key={cap.id}
                  className={itemStyle(displayStatus(cap))}
                  onClick={() => select(cap)}
                  {...reportPrintOmitRows(n)}
                >
                  {safeMode === 'risk' && (
                    <div
                      className={appStyles.numberColorBox}
                      style={{
                        backgroundColor: heatBg(cap),
                      }}
                    >
                      <div className='image-vertical-offset'>
                        {capabilityScore(cap)}
                      </div>
                    </div>
                  )}
                  <h3 className='font-cisco flex-1 m-2 image-vertical-offset'>
                    {cap.name}
                  </h3>
                  <div
                    className={`${itemStatusCircleStyle} ${
                      getStatusBadge(cap.id as number).color
                    }`}
                  >
                    <div className='image-circle-offset'>
                      {getStatusBadge(cap.id as number).text}
                    </div>
                  </div>
                </div>
              ))}
          </Card>
        </>
      )}
    </section>
  );
};

export default ReportCapabilityPanel;
