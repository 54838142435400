import { saveAs } from 'file-saver';
import html2canvas from 'html2canvas';
import React, { useCallback, useEffect, useState } from 'react';
import { ChevronDown, ChevronUp } from 'react-feather';
import Button from '../../complib/Button';
import HelpSection from '../../help/HelpSection';
import ReportHelp from '../../help/ReportHelp';
import appStyles from '../../support/appStyles';
import { generatePagedExcel } from '../../support/generate-excel';
import { useQueryItems } from '../../support/queries';
import {
  RiskSettings,
  useBctRiskSelections,
  useCapabilitySelections,
  useCustomCapabilitySelections,
  useMaturitySelections,
  usePinFilterSelections,
  useSafeSettingsSelections,
  useSecurityFunctionSelections,
} from '../../support/stores';
import { Capability, CapabilityRisk } from '../../types/capability';
import CapabilityStandardFilters from '../CapabilityStandardFilters';
import {
  capabilityInStandardFilters,
  openSurveyOnce,
  SafeProps,
  statusSettings,
} from '../safe-utils';
import StatusFilter from '../StatusFilter';
import ReportCapabilityPanel from './ReportCapabilityPanel';
import ReportKey from './ReportKey';
import ReportProductPanel, { ProductScore } from './ReportProductPanel';
import ReportRiskPanel from './ReportRiskPanel';
import ReportRiskPanelArchitect from './ReportRiskPanelArchitect';

interface ReportFilterItem {
  name: string;
  active: boolean;
}

const ReportPage = ({ safeMode = 'risk' }: SafeProps) => {
  const { selections: pinSelections } = usePinFilterSelections();
  const { selections: bctSelections } = useBctRiskSelections();
  const { selections: maturitySelections } = useMaturitySelections();
  const { selections: secFunSelections } = useSecurityFunctionSelections();
  const capabilityData = useQueryItems('capability');
  const [selectedObject, setSelectedObject] = useState<{
    type: string;
    id: string;
  } | null>(null);
  const [filteredRisks, setFilteredRisks] = useState<string[]>();
  const [filteredProducts, setFilteredProducts] = useState<string[]>();
  const [filteredCapabilities, setFilteredCapabilities] = useState<string[]>();
  const {
    getItemSetting: getCapabilitySetting,
    selections: capabilitySelections,
  } = useCapabilitySelections();
  const { selections: customCapabilitySelections } =
    useCustomCapabilitySelections();
  const { settings: safeSettings, getSetting: getSafeSetting } =
    useSafeSettingsSelections();
  const [status, setStatus] = useState(statusSettings);
  const [highlightRisk, setHighlightRisk] = useState<CapabilityRisk[]>([]);
  const [highlightProduct, setHighlightProduct] = useState<string[]>([]);
  const [highlightCapability, setHighlightCapability] = useState<number[]>([]);
  const [showingKey, setShowingKey] = useState<boolean>(false);
  const [productType, setProductType] = useState<'products' | 'proposed'>(
    'products'
  );

  /**
   * current: just the active cisco / 3rd party products for showing capabilities
   * proposed: each capability full coverage
   * - uses proposed (active)
   * - OR - current products (active) if no proposed
   * - OR - 'Third Party Product' if nothing has been selected
   */
  const capabilityProducts = useCallback(
    (cId: number): string[] => {
      if (productType === 'products') {
        return (getCapabilitySetting(cId, 'products', []) as ReportFilterItem[])
          .filter((p) => p.active)
          .map((p) => p.name);
      }
      let products: ReportFilterItem[] = (
        getCapabilitySetting(cId, 'proposed', []) as ReportFilterItem[]
      ).filter((p) => p.active);
      if (!products.length) {
        products = (
          getCapabilitySetting(cId, 'products', []) as ReportFilterItem[]
        ).filter((p) => p.active);
      }
      if (!products.length) {
        products = [{ name: 'Third Party Product', active: true }];
      }
      return products.map((p) => p.name);
    },
    [productType, getCapabilitySetting]
  );

  const capabilityRisks = (c: Capability | null): CapabilityRisk[] =>
    (c?.risk ?? []).map((r) => ({ name: r.name, impact: r.impact }));

  const riskCapabilities = (
    r: RiskSettings | CapabilityRisk | null
  ): number[] => {
    if (!r) {
      return [];
    }
    return (
      [...capabilityData, ...Object.values(customCapabilitySelections)] as any
    )
      .filter(
        (c: Capability) =>
          r &&
          c.risk.some((rObj) => {
            if (rObj.name === r.name) {
              if ('impact' in r) {
                return rObj.impact === r.impact;
              }
              return true;
            }
            return false;
          })
      )
      .map((c: Capability) => c.id);
  };

  const productCapabilities = (product: ProductScore | null): number[] => {
    if (!product) {
      return [];
    }
    return Object.keys(capabilitySelections)
      .filter(
        (cId: string) =>
          capabilityProducts(parseInt(cId)).filter((p) => p === product.name)
            .length
      )
      .map((cId) => parseInt(cId)) as number[];
  };

  const selectObject = (object: { id: string; type: string }): void => {
    if (
      selectedObject?.id === object.id &&
      selectedObject?.type === object.type
    ) {
      setSelectedObject(null);
    } else {
      setSelectedObject(object);
    }
  };

  const selectRisk = (r: RiskSettings | CapabilityRisk | null) => {
    const rCapabilities = riskCapabilities(r);
    const rProducts: string[] = rCapabilities
      .map((cId: number) => {
        return (
          getCapabilitySetting(cId, productType, []) as {
            name: string;
            active: boolean;
          }[]
        )
          .filter((p) => p.active)
          .map((p) => p.name);
      })
      .flat(1);
    selectObject({ id: r?.name ?? '', type: 'risk' });
    setHighlightCapability(rCapabilities);
    setHighlightProduct(rProducts);
  };
  const selectCapability = (c: Capability | null) => {
    selectObject({ id: c?.name ?? '', type: 'capability' });
    setHighlightRisk(capabilityRisks(c));
    setHighlightProduct(capabilityProducts(c?.id as number));
  };
  const selectProduct = (p: ProductScore | null) => {
    selectObject({ id: p?.name ?? '', type: 'product' });
    const pCapabilities = productCapabilities(p);
    const pRisks: CapabilityRisk[] = pCapabilities
      .map((cId: number | undefined) => {
        const capData = [
          ...capabilityData,
          ...Object.values(customCapabilitySelections),
        ].find((c: Capability) => cId && c.id === cId);
        if (!capData) {
          return [];
        }
        return capData.risk.map((r: CapabilityRisk) => ({
          name: r.name,
          impact: r.impact,
        }));
      })
      .flat(1);
    setHighlightCapability(pCapabilities);
    setHighlightRisk(pRisks);
  };

  useEffect(() => {
    if (!capabilityData) {
      return;
    }
    const filteredCaps = [
      ...capabilityData,
      ...Object.values(customCapabilitySelections),
    ]
      .filter(capabilityInStandardFilters)
      .filter((c: Capability) => {
        const cSetting = c?.id
          ? getCapabilitySetting(c.id, 'status', 'None')
          : 'None';
        return (
          cSetting !== 'Hide' &&
          status.find((s) => s.name === cSetting)?.selected
        );
      });
    setFilteredCapabilities(filteredCaps.map((c: Capability) => c.name));
    const riskNames = [
      ...new Set(
        (filteredCaps ?? [])
          .map((c: Capability) => c.risk.map((r) => r.name))
          .flat(1)
      ),
    ];
    setFilteredRisks(riskNames as string[]);
    const productNames = [
      ...new Set(
        (filteredCaps ?? [])
          .map((c: Capability) => capabilityProducts(c.id as number))
          .flat(1)
      ),
    ];
    const capabilitySelectionType = getSafeSetting('capabilityModeType', 'all');
    const capabilitySelectionName = getSafeSetting('capabilityModeName', null);
    setFilteredProducts(
      (productNames as string[]).filter(
        (p) =>
          capabilitySelectionType !== 'product' || capabilitySelectionName === p
      )
    );
  }, [
    pinSelections,
    capabilityData,
    getCapabilitySetting,
    status,
    productType,
    safeSettings,
    getSafeSetting,
    maturitySelections,
    secFunSelections,
    capabilityProducts,
  ]);

  const toggleStatus = (id: string) => {
    const temp = [...status];
    const state = temp.find((s) => s.name === id);
    if (state) {
      state.selected = !state?.selected;
      setStatus(temp);
    }
  };

  const exportSheet = () => {
    openSurveyOnce();
    generatePagedExcel(bctSelections, capabilitySelections);
  };

  const exportReportImage = async () => {
    openSurveyOnce();
    const sourceElement = document.querySelector(
      '#reportImageElement'
    ) as HTMLElement;
    if (!sourceElement) {
      console.error('Unable to find page elements for image.');
      return;
    }
    const canvas = await html2canvas(sourceElement, {
      windowWidth: 1920,
      scale: 3,
      /*
       * onclone: layout is broken when rendering to canvas, css properties don't all get translated correctly.
       * here we clone the element and apply canvas-specific styles before rendering the image
       * currently there are layout fixes AND modifications to improve display in the intended document
       */
      onclone: (documentClone) => {
        const headingEls = documentClone.querySelectorAll(
          'h2.image-vertical-offset'
        ) as NodeListOf<HTMLElement>;
        headingEls.forEach((h: HTMLElement) => {
          h.style.lineHeight = '3';
          h.style.fontSize = '2rem';
          h.style.textAlign = 'left';
          h.style.paddingLeft = '18px';
        });
        const textEls = documentClone.querySelectorAll(
          '.image-vertical-offset'
        ) as NodeListOf<HTMLElement>;
        textEls.forEach((s: HTMLElement) => {
          s.style.position = 'relative';
          s.style.marginTop = '-8px';
          s.style.marginBottom = '8px';
          s.style.paddingTop = '4px';
          s.style.paddingBottom = '4px';
          s.style.color = 'black';
        });
        const cardEls = documentClone.querySelectorAll(
          '.shadow-md'
        ) as NodeListOf<HTMLElement>;
        cardEls.forEach((c: HTMLElement) => {
          c.style.border = '2px solid #ced4da';
        });
        const indicatorCircles = documentClone.querySelectorAll(
          '.image-circle-offset'
        ) as NodeListOf<HTMLElement>;
        indicatorCircles.forEach((i: HTMLElement) => {
          i.style.marginTop = '-8px';
        });
        // always render the report key
        const reportKeyElement = documentClone.querySelector(
          '#report-key-container'
        ) as HTMLElement;
        reportKeyElement.style.minHeight = '150px';
      },
    });
    const image = canvas.toDataURL('image/png', 1.0);
    saveAs(image, 'Safe_Report');
  };

  const switchProductType = (type: 'products' | 'proposed') => {
    setSelectedObject(null);
    setProductType(type);
  };

  return (
    <>
      <section className='grid grid-cols-[minmax(200px,1fr)_6fr] gap-8 min-h-0 min-w-0 relative'>
        <section>
          <CapabilityStandardFilters />
          <StatusFilter toggleStatus={toggleStatus} status={status} />
          <h2 className={appStyles.h2}>Product Selection</h2>
          <div className='flex text-sm'>
            <div
              onClick={() => switchProductType('products')}
              className={`${appStyles.filterButton} ${
                productType === 'products'
                  ? 'bg-sky-darker hover:bg-ocean-lighter'
                  : 'bg-gray-400 hover:bg-gray-500'
              }`}
            >
              Current
            </div>
            <div
              onClick={() => switchProductType('proposed')}
              className={`${appStyles.filterButton} ${
                productType === 'proposed'
                  ? 'bg-sky-darker hover:bg-ocean-lighter'
                  : 'bg-gray-400 hover:bg-gray-500'
              }`}
            >
              Proposed
            </div>
          </div>
          <Button onClick={exportSheet} className='mr-2 mt-8'>
            Download sheet
          </Button>
          <Button className='mr-2 mt-8' onClick={exportReportImage}>
            Download image
          </Button>
        </section>
        <section id='reportImageElement'>
          <div
            id='report-key-container'
            className={`${
              showingKey ? 'max-h-[150px]' : 'max-h-0'
            } overflow-y-hidden transition-max-h text-sec-dark-gray flex justify-end`}
          >
            <ReportKey />
          </div>
          <section className='grid grid-cols-3 gap-8 min-h-0 min-w-0'>
            {safeMode === 'risk' && (
              <ReportRiskPanel
                filteredRisks={filteredRisks}
                highlight={highlightRisk}
                select={selectRisk}
                currentSelection={selectedObject}
              />
            )}
            {safeMode === 'architect' && (
              <ReportRiskPanelArchitect
                filteredRisks={filteredRisks}
                highlight={highlightRisk}
                select={selectRisk}
                currentSelection={selectedObject}
              />
            )}
            <ReportCapabilityPanel
              filteredCapabilities={filteredCapabilities}
              select={selectCapability}
              highlight={highlightCapability}
              safeMode={safeMode}
              currentSelection={selectedObject}
              productType={productType}
            />
            <ReportProductPanel
              filteredProducts={filteredProducts}
              select={selectProduct}
              highlight={highlightProduct}
              safeMode={safeMode}
              currentSelection={selectedObject}
              capabilityKey={productType}
            />
          </section>
          <section className='absolute right-0 top-0' data-html2canvas-ignore>
            <div
              className='text-xs font-bold flex cursor-pointer justify-end text-gray-600 hover:text-gray-800 transition-colors'
              onClick={() => setShowingKey(!showingKey)}
            >
              {showingKey ? 'Hide' : 'Show'} report key
              {showingKey ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
            </div>
          </section>
        </section>
      </section>
      <HelpSection>
        <ReportHelp safeMode={safeMode} />
      </HelpSection>
    </>
  );
};

export default ReportPage;
