import create from 'zustand';
interface ItemSettings {
  name?: string;
  active: boolean;
  settings?: {
    score?: number;
    status?: string;
    count?: number;
    children?: ItemSelections;
    groups?: ItemSelections;
  };
}
interface ItemSelections {
  [name: string]: ItemSettings;
}
interface SelectionLists {
  [type: string]: ItemSelections;
}
interface ItemSelectionsState {
  selections: SelectionLists;
  populateItems: (type: string, names: string[]) => void;
  setItems: (type: string, items: ItemSelections) => void;
  addItem: (type: string, name: string) => void;
  removeItem: (type: string, name: string) => void;
  setItemSetting: (
    type: string,
    name: string,
    setting: 'score' | 'status' | 'count' | 'children' | 'groups',
    value: number | string | ItemSelections
  ) => void;
  addAllItems: (type: string) => void;
  removeAllItems: (type: string) => void;
}

const useStore = create<ItemSelectionsState>((set) => ({
  selections: {},
  populateItems: (type: string, names: string[]) =>
    set((state) => {
      const newItems: ItemSelections = {};
      names.forEach((name: string) => (newItems[name] = { active: false }));
      return { selections: { ...state.selections, [type]: newItems } };
    }),
  setItems: (type: string, items: ItemSelections) =>
    set((state) => {
      return { selections: { ...state.selections, [type]: items } };
    }),
  addItem: (type: string, name: string) =>
    set((state) => {
      state.selections[type][name].active = true;
      return {
        selections: {
          ...state.selections,
          [type]: { ...state.selections[type] },
        },
      };
    }),
  setItemSetting: (
    type: string,
    name: string,
    setting: 'score' | 'status' | 'count' | 'children' | 'groups',
    value: number | string | ItemSelections
  ) =>
    set((state) => {
      state.selections[type][name].settings = Object.assign(
        {},
        state.selections[type][name].settings,
        { [setting]: value }
      );
      return {
        selections: {
          ...state.selections,
          [type]: { ...state.selections[type] },
        },
      };
    }),
  removeItem: (type: string, name: string) =>
    set((state) => {
      state.selections[type][name].active = false;
      return {
        selections: {
          ...state.selections,
          [type]: { ...state.selections[type] },
        },
      };
    }),
  addAllItems: (type: string) => {
    set((state) => {
      const itemSelections: ItemSelections = { ...state.selections[type] };
      Object.keys(itemSelections).forEach(
        (key: string) => (itemSelections[key].active = true)
      );
      return {
        selections: {
          ...state.selections,
          [type]: { ...state.selections[type] },
        },
      };
    });
  },
  removeAllItems: (type: string) => {
    set((state) => {
      const itemSelections: ItemSelections = { ...state.selections[type] };
      Object.keys(itemSelections).forEach(
        (key: string) => (itemSelections[key].active = false)
      );
      return {
        selections: {
          ...state.selections,
          [type]: { ...state.selections[type] },
        },
      };
    });
  },
}));

export default useStore;
export { ItemSettings, ItemSelections, ItemSelectionsState };
