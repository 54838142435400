import { Menu } from '@headlessui/react';
import React, { useState } from 'react';
import { ChevronDown, ChevronUp, RefreshCcw, Save } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import ModalFrame from '../complib/ModalFrame';
import { useQueryItems } from '../support/queries';
import { ItemSelections } from '../support/selections.store';
import { Settings } from '../support/settings.store';
import {
  BctSettings,
  CapabilitySettings,
  resetStores,
  useBctRiskSelections,
  useCapabilitySelections,
  useThirdPartyProductSettings,
} from '../support/stores';
import { Capability } from '../types/capability';
import { UniObject } from '../types/item';
import ConfirmActionModal from './ConfirmActionModal';
import UploadFileModal, { FileData } from './UploadFileModal';

const styles = {
  menuItem: [
    'flex pl-4 pr-6 py-2 items-center transition-colors',
    'border-b border-gray-500',
    'last:border-none',
  ].join(' '),
  enabledItem: ['hover:bg-gray-400', 'cursor-pointer'].join(' '),
  menuItemIcon: 'mr-2 text-sky-darker',
  disabledItem: [
    'bg-gray-300',
    'hover:bg-gray-300',
    'text-gray-500',
    'cursor-not-allowed',
  ].join(' '),
};

interface SAFEExport {
  bct: ItemSelections<BctSettings>;
  capability: ItemSelections<CapabilitySettings>;
  customProduct: Settings;
}

interface Props {
  redirect: string;
  disableExport?: boolean;
}

const SafeFileActionsMenu = ({ redirect, disableExport }: Props) => {
  const navigate = useNavigate();
  const capabilityData = useQueryItems('capability');
  const {
    setItems: setCapabilitySelections,
    selections: capabilitySelections,
  } = useCapabilitySelections();
  const { setItems: setBctSelections, selections: bctSelections } =
    useBctRiskSelections();
  const { settings: thirdPartyProductSettings, setSetting: setCustomProduct } =
    useThirdPartyProductSettings();
  const [confirmReset, setConfirmReset] = useState<boolean>(false);
  const [importing, setImporting] = useState<boolean>(false);
  const [missingCapabilities, setMissingCapabilities] = useState<UniObject[]>(
    []
  );
  const [importMessage, setImportMessage] = useState<string>('');

  const resetUserChanges = () => {
    resetStores();
    setConfirmReset(false);
    navigate(redirect);
  };

  const exportSAFE = () => {
    if (disableExport) {
      return;
    }
    const exportJson = {
      bct: bctSelections,
      capability: capabilitySelections,
      customProduct: thirdPartyProductSettings,
    };
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(exportJson)
    )}`;
    const link = document.createElement('a');
    link.href = jsonString;
    link.download = `SAFE-export-${new Date().toJSON().slice(0, 19)}.json`;
    link.click();
  };

  const updateStores = (data: SAFEExport) => {
    setBctSelections(data.bct);
    setCapabilitySelections(data.capability);
    Object.keys(data.customProduct).forEach((productName: string) => {
      setCustomProduct(productName, true);
    });
  };

  const importSAFE = (data: FileData) => {
    if (typeof data === 'string') {
      const importData: SAFEExport = JSON.parse(data as string) as SAFEExport;
      if (
        ['bct', 'capability', 'customProduct'].some(
          (prop) => !Object.prototype.hasOwnProperty.call(importData, prop)
        )
      ) {
        setImportMessage(
          'Error: File not recognized. This file might not have been exported using this tool.'
        );
      } else {
        if (!capabilityData) {
          // this shouldn't happen: buttons not showing
          return;
        }
        const missingCapability: UniObject[] = [];
        const foundCapabilities: ItemSelections<CapabilitySettings> = {};
        Object.entries(importData.capability).forEach(([key, c]) => {
          const id = parseInt(key);
          const apiCapability = capabilityData.find(
            (cData: Capability) => cData.id === id
          );
          if (apiCapability) {
            foundCapabilities[parseInt(key)] = c;
          } else {
            missingCapability.push({ name: c.name, id });
          }
        });
        if (!missingCapability.length) {
          updateStores(importData);
          setImportMessage('Success: imported SAFE config.');
        } else {
          setMissingCapabilities(missingCapability);
          updateStores({ ...importData, capability: foundCapabilities });
          setImportMessage(
            'Success: imported SAFE conifg. However these capabilities are no longer available, so cannot be shown.'
          );
        }
      }
    } else {
      setImportMessage('Error: file read failed.');
    }
    setImporting(false);
  };

  const closeImportMessageModal = () => {
    setImportMessage('');
    setMissingCapabilities([]);
  };

  return (
    <>
      <div className='absolute top-4 left-8 z-10'>
        <Menu>
          {({ open }) => (
            <>
              <Menu.Button className='flex items-center'>
                {/* <Button theme='ghost' className='flex mb-1 items-center'> */}
                {open && <ChevronUp size={18} className='-ml-1 mr-1' />}
                {!open && <ChevronDown size={18} className='-ml-1 mr-1' />}
                File
                {/* </Button> */}
              </Menu.Button>
              <Menu.Items className='bg-gray-300 rounded-sm border border-gray-500  text-sec-dark-gray'>
                <Menu.Item>
                  <div
                    className={`${styles.menuItem} ${styles.enabledItem}`}
                    onClick={() => setConfirmReset(true)}
                  >
                    <RefreshCcw size={20} className={styles.menuItemIcon} />
                    Start over &hellip;
                  </div>
                </Menu.Item>
                {capabilityData && (
                  <>
                    <Menu.Item>
                      <div
                        className={`${styles.menuItem} ${
                          disableExport
                            ? styles.disabledItem
                            : styles.enabledItem
                        }`}
                        onClick={exportSAFE}
                      >
                        <Save
                          size={20}
                          className={`${styles.menuItemIcon} ${
                            disableExport ? 'opacity-40' : ''
                          }`}
                        />
                        Export SAFE assessment
                      </div>
                    </Menu.Item>
                    <Menu.Item>
                      <div
                        className={`${styles.menuItem} ${styles.enabledItem}`}
                        onClick={() => setImporting(true)}
                      >
                        <Save size={20} className={styles.menuItemIcon} />
                        Import SAFE assessment
                      </div>
                    </Menu.Item>
                  </>
                )}
              </Menu.Items>
            </>
          )}
        </Menu>
      </div>
      {confirmReset && (
        <ConfirmActionModal
          isOpen={confirmReset}
          close={() => setConfirmReset(false)}
          action={resetUserChanges}
          title='Reset All changes'
          body={[
            'This will remove any configuration you have done, restore initial settings, and return to the first page.',
            "Useful when you're starting a new report and diagram or you just want to revert to defaults.",
          ]}
          actionButton='Reset All'
          actionIcon='refresh'
        />
      )}
      {importing && (
        <UploadFileModal
          close={() => setImporting(false)}
          isOpen={importing}
          title='Import SAFE Config'
          body={[
            'Import a SAFE assessment (.json) file previously exported from this tool. ' +
              'This action will overwrite your current configuration.',
            'Export your current config first if you need it later.',
          ]}
          action={importSAFE}
          button='Import config'
        />
      )}
      {importMessage && (
        <ModalFrame
          close={closeImportMessageModal}
          isOpen={!!importMessage}
          title={`Import ${importMessage}`}
        >
          <ul className='list-disc pl-4'>
            {missingCapabilities.map((c) => (
              <li key={c.id} className=''>
                {c.name}
              </li>
            ))}
          </ul>
        </ModalFrame>
      )}
    </>
  );
};

export default SafeFileActionsMenu;
